import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class MyVacChangeDataService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getVacationsChanges(): Observable<any> {
    const url = `${this.baseUrl}/myVacations/changes/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getVacationChangeDetail(pk:number): Observable<any> {
    const url = `${this.baseUrl}/myVacations/changes/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  putVacationChangeDetail(pk:number, data:any): Observable<any> {
    const url = `${this.baseUrl}/myVacations/changes/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')

    });
    return this.http.put(url, data, { headers });
    }

  deleteVacationChange(pk:number): Observable<any> {
    const url = `${this.baseUrl}/myVacations/changes/detail/`+pk +'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers });
  }

}
