<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
  <div class="grid">
    <div class="col-10" style="margin: auto">
      <h2 class="titulo">Gestion de vacaciones.</h2>
      <div class="grid">
        <div class="col-4 md:col-2 lg:col-2" *ngFor="let year of years">
          <button class="btn" (click)="getVacationsBossData(year)">
            {{ year }}
          </button>
        </div>
      </div>
      <h4>*Selecciona la solicitud con la que deseas interactuar.</h4>
      <p-table
        #dt1
        [value]="vacations"
        [tableStyle]="{ 'min-width': '40rem' }"
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 25]"
        [globalFilterFields]="[
          'start_date',
          'employee',
          'end_date',
          'substitute',
          'status'
        ]"
      >
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
              <button
                pButton
                label="Limpiar filtros"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                (click)="clear(dt1)"
              ></button>
            </div>
            <div class="col-6" style="align-items: end; display: flex">
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="filter_text"
                  (input)="dt1.filterGlobal(filter_text, 'contains')"
                  placeholder="Buscar"
                />
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th pSortableColumn="employee">
              Empleado <p-sortIcon field="Employee"></p-sortIcon>
            </th>
            <th pSortableColumn="start_date">
              Fecha de inicio <p-sortIcon field="start_date"></p-sortIcon>
            </th>
            <th pSortableColumn="end_date">
              Fecha de fin <p-sortIcon field="end_date"></p-sortIcon>
            </th>
            <th pSortableColumn="substitute">
              Sustituto <p-sortIcon field="substitute"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vacation>
          <tr (click)="showDetail(vacation)">
            <td>{{ vacation.id }}</td>
            <td>{{ vacation.employee }}</td>
            <td>{{ vacation.start_date }}</td>
            <td>{{ vacation.end_date }}</td>
            <td>{{ vacation.substitute }}</td>
            <td style="width: 20%">
              <tag [ngClass]="getColors(vacation.status)">{{
                vacation.status
              }}</tag>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="grid">
        <div class="col-8">
          <h3 [ngClass]="checkChangeTextStyle()">
            Gestionar solicitudes de cambios:
          </h3>
        </div>
        <div class="col-4" style="align-items: center; display: flex">
          <a href="/boss/vacationsChanges" style="width: 100%">
            <button
              href="/boss/vacationsChanges"
              type="submit"
              [ngClass]="checkChangeBtnStyle()"
              style="margin-top: 2%"
            >
              Consultar
            </button>
          </a>
        </div>
      </div>
      <div class="grid">
        <div class="col-8">
          <h3>Generar reportes:</h3>
        </div>
        <div class="col-4" style="align-items: center; display: flex">
          <button (click)="showReportDialog()" class="btn" type="submit">
            Imprimir
          </button>
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    [header]="'Resumen de la solicitud ' + detailId + ' de ' + detailEmployee"
    [(visible)]="visibleDetail"
    [modal]="true"
    [draggable]="false"
  >
    <div class="container">
      <div class="content-input" style="min-width: 30vw">
        <label htmlFor="detail-end">Periodo del empleado</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailPeriod"
          name="detail-period"
          placeholder="Periodo"
          [disabled]="true"
        />
      </div>
    </div>
    <div class="grid" *ngIf="detailStatus == 'En espera'">
      <div class="col-6" style="padding-bottom: 0">
        <div style="width: 100%">
          <label htmlFor="detail-substitute">Vacaciones restantes</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailDaysLeft"
            name="detail-substitute"
            placeholder="Sustituto"
            [disabled]="true"
          />
        </div>
      </div>
      <div class="col-6" style="padding-bottom: 0">
        <div style="width: 100%">
          <label htmlFor="detail-substitute">Sabados por cubrir</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailSatLeft"
            name="detail-substitute"
            placeholder="Sustituto"
            [disabled]="true"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-start">Fecha de inicio</label>
        <p-calendar
          [(ngModel)]="detailStart"
          name="detail-start"
          dateFormat="dd-mm-yy"
          [showIcon]="true"
          [disabled]="true"
          appendTo="body"
          class="select-dates"
          [disabled]="true"
        ></p-calendar>
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-end">Fecha de fin</label>
        <p-calendar
          [(ngModel)]="detailEnd"
          name="detail-end"
          dateFormat="dd-mm-yy"
          [showIcon]="true"
          [disabled]="true"
          appendTo="body"
          class="select-dates"
          [disabled]="true"
        ></p-calendar>
      </div>
    </div>
    <div class="grid">
      <div class="col-6" style="padding-bottom: 0">
        <div style="width: 100%">
          <label htmlFor="detail-substitute">Dias habiles</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailWorkDays"
            name="detail-substitute"
            placeholder="Sustituto"
            [disabled]="true"
          />
        </div>
      </div>
      <div class="col-6" style="padding-bottom: 0">
        <div style="width: 100%">
          <label htmlFor="detail-substitute">Sabados cubiertos</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailSaturdays"
            name="detail-substitute"
            placeholder="Sustituto"
            [disabled]="true"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-substitute">Sustituto</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailSubstitute"
          name="detail-substitute"
          placeholder="Sustituto"
          [disabled]="true"
        />
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-status">Status</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailStatus"
          name="detail-status"
          placeholder="Status"
          [disabled]="true"
          [ngClass]="getColors(detailStatus)"
        />
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-comment">Comentarios</label>
        <textarea
          type="text"
          pInputText
          [(ngModel)]="detailComments"
          name="detail-comment"
          [disabled]="true"
          placeholder="Comentarios"
          [disabled]="true"
        ></textarea>
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="detail-boss">Comentarios del jefe</label>
        <textarea
          type="text"
          pInputText
          [(ngModel)]="detailBossComments"
          name="detail-boss"
          placeholder="Comentarios del jefe"
          style="font-weight: bold"
        ></textarea>
      </div>
    </div>
    <div class="grid">
      <div class="col-6">
        <div style="width: 100%">
          <button
            type="submit"
            class="btn"
            style="margin-top: 2%; background-color: #009f4d"
            *ngIf="detailStatus == 'En espera'"
            (click)="acceptVacationData()"
          >
            Aceptar vacacion.
          </button>
        </div>
      </div>
      <div class="col-6">
        <div style="width: 100%">
          <button
            type="submit"
            class="btn"
            style="margin-top: 2%; background-color: #c2262e"
            *ngIf="detailStatus == 'En espera'"
            (click)="denyVacationData()"
          >
            Denegar vacación.
          </button>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-dialog
    header=""
    [(visible)]="reportDialog"
    [modal]="true"
    [draggable]="false"
    (onHide)="cleanReportForm()"
  >
    <ng-template pTemplate="header">
      <div>
        <span class="text-xl font-bold"
          >Impresion de reportes de vacaciones: <i class="pi pi-file"></i
        ></span>
      </div>
    </ng-template>
    <div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha de inicio del reporte</label>
          <p-calendar
            [(ngModel)]="reportStartDate"
            name="detail-start"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            appendTo="body"
            class="select-dates"
          ></p-calendar>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha de fin del reporte</label>
          <p-calendar
            [(ngModel)]="reportEndDate"
            name="detail-start"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            appendTo="body"
            class="select-dates"
          ></p-calendar>
        </div>
      </div>
      <div class="container" style="min-width: 30vw">
        <div class="content-input">
          <label htmlFor="detail-addDays">Status:</label><br />
          <p-multiSelect
            [options]="status_options"
            appendTo="body"
            [(ngModel)]="reportStatus"
            placeholder="Escoge los status que quieres imprimir"
          ></p-multiSelect>
        </div>
      </div>
      <div class="container" style="min-width: 30vw">
        <div class="content-input">
          <label htmlFor="detail-addDays">Nombre del empleado:</label><br />
          <input
            type="text"
            pInputText
            [(ngModel)]="reportName"
            name="detail-addDays"
            placeholder="Escoge el nombre de algun empleado o dejalo vacio"
            style="font-weight: normal; width: 100%"
          />
        </div>
      </div>

      <button
        type="submit"
        (click)="getReport()"
        class="btn"
        style="margin-top: 2%"
      >
      Generar.
      </button>
    </div>
  </p-dialog>
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
