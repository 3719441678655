<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">

    <div class="col-10" style="margin:auto;">
        <h2 class="titulo">Mis incapacidades.</h2>
        <div class="grid">

        <div class="col-7">
            <h3>Registro de incapacidad</h3>
            </div>
            <div class="col-4" style="align-items: center;display: flex;">
                <button  (click)="showDialog()" type="submit"  class="btn" style="margin-top: 2%;">Registrar</button>
            </div>
    </div>
        <p-table [value]="incapacities" [tableStyle]="{'min-width': '50rem'}" selectionMode="single" #dt1
        [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]" [globalFilterFields]="['start_date', 'end_date', 'motive','status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filterText" (input)="dt1.filterGlobal(filterText, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
                <tr>
                    <th>ID</th>
                    <th pSortableColumn="start_date">Fecha de inicio<p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin<p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="motive">Motivo<p-sortIcon field="motive"></p-sortIcon></th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-incapacity>
                <tr (click)="showDetail(incapacity)">
                    <td>{{ incapacity.id }}</td>
                    <td>{{ incapacity.start_date }}</td>
                    <td>{{ incapacity.end_date }}</td>
                    <td>{{ incapacity.motive }}</td>
                    <td><tag  [ngClass]="getColors(incapacity.status)">{{ incapacity.status }} </tag></td>
                </tr>
            </ng-template>
        </p-table>
        <h4>*Si deseas consultar una solicitud solo seleccionala.</h4>

</div>
</div>


<p-dialog header="Registro de incapacidad" [(visible)]="visibleModal" [modal]="true" [draggable]="false" (onHide)="cleanForm()">
  <div class="dialog-content">
    <div class="content-input">
      <label htmlFor="selected-start">Fecha de inicio</label>
      <p-calendar [(ngModel)]="selectedStart" name="selected-start" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"></p-calendar>
    </div>
    <div class="content-input">
      <label htmlFor="selected-duration">Dias de incapacidad</label>
      <input type="text" pInputText [(ngModel)]="selectedDuration" name="selected-duration" placeholder="Dias de incapacidad(Numero)">
    </div>
    <div class="content-input">
      <label htmlFor="selected-motive">Motivo</label>
      <input type="text" pInputText [(ngModel)]="selectedMotive" name="selected-motive" placeholder="Motivo de incapacidad">
    </div>
    <div class="content-input">
      <label htmlFor="proof">Documento del IMSS:</label>
      <p-fileUpload mode="basic" chooseLabel="Agregar" name="proof[]" (onSelect)="onUpload($event)" #fileClean></p-fileUpload>
    </div>
    <div class="content-input">
      <label htmlFor="selected-comment">Comentarios</label>
      <textarea type="text" pInputText [(ngModel)]="selectedComments" name="selected-comment" placeholder="Comentarios"></textarea>
    </div>
    <div class="dialog-buttons">
      <button type="submit" (click)="postIncapacityData()" class="btn">Finalizar registro</button>
    </div>
  </div>
</p-dialog>


<p-dialog [header]="'Solicitud de incapacidad ' + detailId" [(visible)]="visibleDetail" [modal]="true" [draggable]="false">
  <div class="dialog-content">
    <div class="content-input">
      <label htmlFor="detail-start">Fecha de inicio</label>
      <p-calendar [(ngModel)]="detailStart" name="detail-start" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"[disabled]="true"></p-calendar>
    </div>
    <div class="content-input">
      <label htmlFor="detail-end">Fecha de fin</label>
      <p-calendar [(ngModel)]="detailEnd" name="detail-end" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"[disabled]="true"></p-calendar>
    </div>
    <div class="content-input">
      <label htmlFor="detail-duration">Dias de incapacidad</label>
      <input type="text" pInputText [(ngModel)]="detailDuration" name="detail-duration" placeholder="Dias de incapacidad(Numero)"[disabled]="true">
    </div>
    <div class="content-input">
      <label htmlFor="detail-motive">Motivo</label>
      <input type="text" pInputText [(ngModel)]="detailMotive" name="detail-motive" placeholder="Motivo de incapacidad"[disabled]="true">
    </div>
    <div class="content-input">
      <label for="detail-proof">Documento del IMSS</label>
        <button class="btn"  (click)="downloadFile()"><i class="pi pi-download"></i></button>
    </div>
    <div class="content-input">
      <label htmlFor="detail-status">Status</label>
      <input type="text" pInputText [(ngModel)]="detailStatus" name="detail-status" placeholder="Status de incapacidad" [ngClass]="getColors(detailStatus)" [disabled]="true">
    </div>
    <div class="content-input">
      <label htmlFor="detail-comments">Comentarios</label>
      <textarea type="text" pInputText [(ngModel)]="detailComments" name="detail-comments" placeholder="Comentarios"[disabled]="true"></textarea>
    </div>
    <div class="content-input">
      <label htmlFor="detail-boss-comments">Comentarios de RH</label>
      <textarea type="text" pInputText [(ngModel)]="detailBossComments" name="detail-boss-comments" placeholder="Comentarios del jefe"[disabled]="true"></textarea>
    </div>

  </div>
</p-dialog>


</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
