import { Component, OnInit,NgModule, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login.service'
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  @ViewChild('captcha')
  captchaElem!: RecaptchaComponent;

  isLoading = false;
  username: string;
  password: string;
  errorMessage: Message[];
  token: string=''
  siteKey=environment.siteKey


  logoUrl = 'assets/logos/logo_capital.png';
  loginImg='assets/imgs/fondo_login.jpeg'
  constructor(private loginService: LoginService, private router: Router,private messageService: MessageService) {
    this.username = '';
    this.password = '';
    this.errorMessage=[]
  }
  response: any;
  login() {
    if (this.token=='')
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Selecciona el captcha' });
      return;
    }



    this.loginService.login(this.username, this.password,this.token).subscribe({
      next: response => {
        localStorage.setItem('key', response.key);
        this.router.navigate(['/home']);
      },
      error: (error: HttpErrorResponse) => {

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Usuario o contraseña incorrectos' });
        this.captchaElem.reset();
        this.token=''
      }
    });


  }
  resolved(captchaResponse: string)
  {
    this.token=captchaResponse
  }




  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = true;
    }, 1000);
  }

}
