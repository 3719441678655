import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { Card, CardModule } from 'primeng/card';
import { HomeComponent } from './home/home.component';
import { SidebarModule } from 'primeng/sidebar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MessagesModule } from 'primeng/messages';
import { MyVacationsComponent } from './my-vacations/my-vacations.component';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { MyLeavesComponent } from './my-leaves/my-leaves.component';
import { MyIncapacitiesComponent } from './my-incapacities/my-incapacities.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { LandingComponent } from './landing/landing.component';
import { VacChangesComponent } from './my-vacations/vac-changes/vac-changes.component';
import { PaginatorModule } from 'primeng/paginator';
import { AproveVacationsComponent } from './aprove-vacations/aprove-vacations.component';
import { AproveChangesComponent } from './aprove-vacations/aprove-changes/aprove-changes.component';
import { AproveLeaveComponent } from './aprove-leave/aprove-leave.component';
import { BossAbsencesComponent } from './boss-absences/boss-absences.component';
import { BossEmployeesComponent } from './boss-employees/boss-employees.component';
import { RhVacationsComponent } from './rh-vacations/rh-vacations.component';
import { RhVacationChangesComponent } from './rh-vacations/rh-vacation-changes/rh-vacation-changes.component';
import { RhLeavesComponent } from './rh-leaves/rh-leaves.component';
import { RhIncapacitiesComponent } from './rh-incapacities/rh-incapacities.component';
import { RhAbsencesComponent } from './rh-absences/rh-absences.component';
import { RhEmployeesComponent } from './rh-employees/rh-employees.component';
import { SystemManagementComponent } from './system-management/system-management.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [AppComponent,LoginComponent, HomeComponent, MyProfileComponent, MyVacationsComponent, MyLeavesComponent, MyIncapacitiesComponent, NotificationsComponent, LandingComponent, VacChangesComponent, AproveVacationsComponent, AproveChangesComponent, AproveLeaveComponent, BossAbsencesComponent, BossEmployeesComponent, RhVacationsComponent, RhVacationChangesComponent, RhLeavesComponent, RhIncapacitiesComponent, RhAbsencesComponent, RhEmployeesComponent, SystemManagementComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    CardModule,
    SidebarModule,
    MessagesModule,
    TableModule,
    TagModule,
    TabMenuModule,
    DialogModule,
    CalendarModule,
    DropdownModule,
    FileUploadModule,
    ConfirmDialogModule,
    ToastModule,
    PaginatorModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MultiSelectModule
  ],
  providers: [ConfirmationService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,
      } as RecaptchaSettings,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
