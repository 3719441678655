import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RhProfilesService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getProfilesRhData(): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getProfileDetailData(pk:string): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/detail/`+pk+'/'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  modifyProfileData(pk:string,data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  newProfileData(data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  resetPassword(pk:string): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/resetPassword/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,{}, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );

  }
  addVacationDays(pk:string,data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/employees/addVacationDays/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );

  }


}
