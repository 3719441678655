import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MyIncapacityDataService } from '../services/Incapacity/my-incapacity-data.service';
import { Table } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-my-incapacities',
  templateUrl: './my-incapacities.component.html',
  styleUrls: ['./my-incapacities.component.css'],
  providers: [MessageService]
})
export class MyIncapacitiesComponent implements OnInit{
  @ViewChild('fileClean') fileUpload: any;

  isLoading: boolean = true;
  selectedDuration: number;
  selectedStart: Date;
  selectedMotive: string;
  selectedComments:string;
  filterText:string ="";
  mimeType: string = '';


  visibleModal: boolean=false;
  incapacities:any[]=[];

  visibleDetail: boolean=false;
  detailDuration: number =0;
  detailStart: Date = new Date();
  detailEnd: Date = new Date();
  detailMotive: string ='';
  detailComments:string ='';
  detailBossComments:string =''
  detailStatus:string =''
  detailId:number =0
  base64IMSS: string='';
  detailBase64Imss: string='';
  detailImssType: string='';
  constructor(private messageService: MessageService,
    private incapacityService: MyIncapacityDataService,)
  {
    this.selectedStart=new Date();
    this.selectedMotive=""
    this.selectedComments=""
    this.selectedDuration=0

  }
  ngOnInit()  {
    this.getIncapacitiesData();
  }

  cleanForm()
  {
    this.selectedStart=new Date();
    this.selectedMotive=""
    this.selectedComments=""
    this.selectedDuration=0
    this.fileUpload.clear();
  }
  validateForm() {
    if (!this.selectedStart || !this.selectedDuration || !this.selectedMotive || !this.base64IMSS) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
      return false
    } else {
      return true
    }
  }
  getColors(status: string) {
    switch (status) {
        case 'Entregada':
            return 'delivered';
        case 'En revision':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        default:
          return 'inProcess';
    }

  }
showDialog() {
    this.visibleModal = true;
}
showDetail(incapacity: any)
{
  this.visibleDetail=true;
  this.getIncapacityDetail(incapacity.id)
}

getIncapacitiesData()
{
  this.incapacityService.getIncapacitiesData().subscribe({
    next: data => {
      this.incapacities=data
      this.isLoading=false
    },
    error: (error: Error) => {
    }
});
}

postIncapacityData()
  {
    const flag=this.validateForm()
    if (!flag){
      return;
    }
    const convStart=this.selectedStart.getFullYear()+"-"+(this.selectedStart.getMonth()+1)+"-"+this.selectedStart.getDate()
    const myVacationObject = {
      employee:"",
      start_date: convStart,
      duration_days:this.selectedDuration,
      motive:this.selectedMotive,
      imss_document:this.base64IMSS,
      comments: this.selectedComments
    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.incapacityService.newIncapacityData(myVacationObject).subscribe({
      next: data => {
      // La solicitud Post se completó exitosamente
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleModal = false;
      this.cleanForm()

    },
    error: (error: HttpErrorResponse) => {
      // Manejo de errores si es necesario
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });

  }
  clear(table: Table) {
    table.clear();
  }

async getIncapacityDetail(pk:number)
  {
    this.incapacityService.getIncapacityDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailMotive=data.motive
        this.detailDuration=data.duration_days
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailBase64Imss=data.imss_document
        this.detailImssType=data.imss_type

        // Aquí puedes hacer cualquier manipulación o asignación de datos en el componente
      },
      error: (error: Error) => {
        // Manejo de errores
      }
  });
  }
  onUpload(event: { files: any[]; }) {


    const file = event.files[0];
    if (file.size > 8000000) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
      this.fileUpload.clear();
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64IMSS = reader.result as string;
    };
    return true
  }
  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(this.base64ToBlob(this.detailBase64Imss));
    element.download = 'Documento de la incapacidad-IMSS ' + this.detailId;
    element.click();
  }
  base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime')
    const mimeType = mimeTypes.getType(this.detailImssType);
    if (mimeType!=null){
      this.mimeType = mimeType;
    }


    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }




}
