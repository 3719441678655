import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MyProfileDataService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getProfileData(): Observable<any> {
    const url = `${this.baseUrl}/myProfile/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  modifyProfileData(data:any): Observable<any> {
    const url = `${this.baseUrl}/myProfile/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
}
