import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';
import { RhIncapacitiesService } from '../services/Incapacity/rh-incapacities.service';

@Component({
  selector: 'app-rh-incapacities',
  templateUrl: './rh-incapacities.component.html',
  styleUrls: ['./rh-incapacities.component.css'],
  providers: [MessageService,ConfirmationService]
})
export class RhIncapacitiesComponent implements OnInit{
  @ViewChild('fileClean') fileUpload: any;

  isLoading: boolean = true;
  filterText:string ="";
  mimeType: string = '';


  visibleModal: boolean=false;
  incapacities:any[]=[];

  visibleDetail: boolean=false;
  detailDuration: number =0;
  detailStart: Date= new Date();
  detailEnd: Date = new Date();
  detailMotive: string ='';
  detailComments:string ='';
  detailBossComments:string=''
  detailStatus:string =''
  detailId:number =0
  base64IMSS: string='';
  detailBase64Imss: string='';
  detailImssType: string='';
  detailBoss: string='';
  detailEmployee: string='';

  years:number[]=[]
  year:number=new Date().getFullYear();

  reportDialog:boolean=false;
  reportStartDate:Date=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  reportEndDate:Date=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  status_options:any[]=["En revisión","Entregada"];
  reportStatus:string[]=[];
  reportName:string="";
  reportMotive:string="";

  constructor(private messageService: MessageService,private confirmationService: ConfirmationService,
    private rhIncapacityService: RhIncapacitiesService,)
  {


  }
  ngOnInit()  {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023+2;
    this.getIncapacitiesRhData(this.year);


    this.years = Array.from({ length: numberOfYearsToShow }, (_, index) => 2023 + index);

  }


  getColors(status: string) {
    switch (status) {
        case 'Entregada':
            return 'delivered';
        case 'En revision':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        default:
          return 'inProcess';
    }

  }
  clear(table: Table) {
    table.clear();
  }

showDetail(incapacity: any)
{
  this.visibleDetail=true;
  this.getIncapacityDetail(incapacity.id)
}

getIncapacitiesRhData(year: number)
{
  this.rhIncapacityService.getIncapacitiesRhData(year).subscribe({
    next: data => {
      this.incapacities=data
      this.isLoading=false
      this.year=year
    },
    error: (error: Error) => {
    }
});
}


async getIncapacityDetail(pk:number)
  {
    this.rhIncapacityService.getIncapacityRhDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailEmployee=data.employee
        this.detailBoss=data.boss
        this.detailMotive=data.motive
        this.detailDuration=data.duration_days
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailBase64Imss=data.imss_document
        this.detailImssType=data.imss_type

      },
      error: (error: Error) => {
      }
  });
  }
  confirmDeliver()
  {
    this.confirmationService.confirm({
      message: '¿Deseas entregar la incapacidad?',
      header: 'Entrega',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.receiveIncapacityData()
      },
      reject: () => {

      }
  });

  }
  receiveIncapacityData()
  {
   const myIncapacityObject = {
      id:this.detailId,
      status:"Entregada",
      boss_comments:this.detailBossComments


    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.rhIncapacityService.updateIncapacityStatus(this.detailId,myIncapacityObject).subscribe({
      next: data => {

      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }

  showReportDialog()
{
  this.reportDialog=true;
}

getReport()
  {
    const convStart=this.reportStartDate.getFullYear()+"-"+(this.reportStartDate.getMonth()+1)+"-"+this.reportStartDate.getDate()
    const convEnd=this.reportEndDate.getFullYear()+"-"+(this.reportEndDate.getMonth()+1)+"-"+this.reportEndDate.getDate()
    this.rhIncapacityService.getIncapacitiesReport(convStart,convEnd,this.reportStatus,this.reportName,this.reportMotive).subscribe({
      next: data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        const textStart = this.reportStartDate.getDate().toString().padStart(2, '0') + "-" +
                  (this.reportStartDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                  this.reportStartDate.getFullYear();
        const textEnd = this.reportEndDate.getDate().toString().padStart(2, '0') + "-" +
                        (this.reportEndDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                        this.reportEndDate.getFullYear();
        const file_name="Reporte de incapacidades del " + textStart + " al " + textEnd + ".pdf"
        link.download = file_name;
        link.click()

        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo generado.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el archivo.' });
      }
    });
  }

  cleanReportForm()
  {
    this.reportStartDate=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.reportEndDate=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
    this.reportStatus=[];
    this.reportName="";
    this.reportMotive="";

  }



  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(this.base64ToBlob(this.detailBase64Imss));
    element.download = 'Documento de la incapacidad-IMSS ' + this.detailId;
    element.click();
  }
  base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime')
    const mimeType = mimeTypes.getType(this.detailImssType);
    if (mimeType!=null){
      this.mimeType = mimeType;
    }


    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }
}
