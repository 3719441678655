import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  //private csrfToken: string;
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  login(username: string, password: string,token:string): Observable<any> {
    const url = `${this.baseUrl}/users/login/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
     // 'X-CSRFToken': this.csrfToken
    });
    const body =
    { username: username, password: password, recaptcha:token
    }
    return this.http.post<any>(url, body,{headers: headers})
  }
  changePassword(new_password1: string, new_password2: string): Observable<any> {
    const url = `${this.baseUrl}/users/password/change/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
     // 'X-CSRFToken': this.csrfToken
    });
    const body =
    { new_password1: new_password1, new_password2: new_password1
    }
    return this.http.post<any>(url, body,{headers: headers})
  }
  checkStatus(): Observable<any> {
    const url = `${this.baseUrl}/users/user/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });

    return this.http.get<any>(url,{headers: headers})
  }

  logout(): Observable<any> {
    const url = `${this.baseUrl}/users/logout/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });

    return this.http.post<any>(url,{headers: headers})
  }

}
