import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BossDataService } from '../services/Absence/boss-data.service';
import { Table } from 'primeng/table';
import { BossVacationsService } from '../services/Vacation/boss-vacations.service';
import { BossIncapacityDataService } from '../services/Incapacity/boss-incapacity-data.service';
import { BossLeavesService } from '../services/Leave/boss-leaves.service';

@Component({
  selector: 'app-boss-absences',
  templateUrl: './boss-absences.component.html',
  styleUrls: ['./boss-absences.component.css'],
  providers: [MessageService],
})
export class BossAbsencesComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;
  isAccepted: boolean = false;
  detailType: string = '';
  absences: any[] = [];
  visibleDetail: boolean = false;
  filter_text: string = '';
  otherTypes: string[] = [];
  relatives: string[] = [];
  detailStart: Date = new Date();
  detailEnd: Date = new Date();
  detailRelative: string = '';
  detailProof: File = new File([], '');
  base64DetailProof: string = '';
  base64DetailBeforeProof: string = '';
  detailProofType: string = '';
  detailOtherType: string = '';
  detailTime: string = '';
  detailRecoveryTime: string = '';
  detailRecoveryDay: Date[] = [];
  detailComments: string = '';
  detailBossComments: string = '';
  detailDecision: boolean = false;
  detailMotive: string = '';
  detailStatus: string = '';
  detailId: number = 0;
  recoveryDetailDecision: boolean = false;

  detailEmployee: string = '';
  detailSubstitute: string = '';

  detailDuration: number = 0;
  detailBase64Imss: string = '';
  detailImssType: string = '';
  mimeType: string = '';
  motives: string[];

  years: number[] = [];
  year: number = new Date().getFullYear();

  reportDialog: boolean = false;
  reportStartDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  reportEndDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  status_options: any[] = [
    'En espera',
    'Aceptada',
    'Rechazada',
    'En proceso',
    'Terminada',
    'Cancelada',
    'No cumplio',
    'Entregada',
    'En revisión',
  ];
  reportStatus: string[] = [];
  reportName: string = '';
  reportPaid:string[] = [];
  constructor(
    private messageService: MessageService,
    private absencesBoss: BossDataService,
    private incapacityBoss: BossIncapacityDataService,
    private leaveBoss: BossLeavesService,
    private vacationBoss: BossVacationsService
  ) {
    this.motives = [
      'Fallecimiento de familiar directo',
      'Intervención quirúrgica a familiar directo',
      'Privación de la libertad de familiar directo',
      'Siniestros ocurridos en el hogar',
      'Matrimonio del trabajador',
      'Examen profesional del trabajador o hijos',
      'Paternidad',
      'Otro',
    ];
    this.otherTypes = ['Día', 'Horas'];
    this.relatives = ['Padre/Madre', 'Hermano(a)', 'Hijo(a)', 'Conyugue'];
  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023 + 2;
    this.getAbsencesBossData(this.year);
    this.years = Array.from(
      { length: numberOfYearsToShow },
      (_, index) => 2023 + index
    );
  }
  getColors(status: string) {
    switch (status) {
      case 'Aceptada':
        return 'accepted';
      case 'Aceptado':
        return 'accepted';
      case 'En proceso':
        return 'inProcess';
      case 'Rechazada':
        return 'denied';
      case 'Rechazado':
        return 'denied';
      case 'Terminada':
        return 'finished';
      case 'Terminado':
        return 'finished';
      case 'En espera':
        return 'waiting';
      case 'Cancelada':
        return 'canceled';
      case 'No cumplio':
        return 'canceled';
      case 'En revisión':
        return 'waiting';
      case 'Entregada':
        return 'finished';
      default:
        return 'inProcess';
    }
  }
  showDetail(absence: any) {
    this.visibleDetail = true;
    this.detailType = absence.type;
    if (absence.type == 'Vacación') {
      this.getVacationDetail(absence.id);
    }
    if (absence.type == 'Permiso') {
      this.getLeaveDetail(absence.id);
    }
    if (absence.type == 'Incapacidad') {
      this.getIncapacityDetail(absence.id);
    }
  }
  clear(table: Table) {
    table.clear();
  }
  getAbsencesBossData(year: number) {
    this.absencesBoss.getAbsencesData(year).subscribe({
      next: (data) => {
        this.absences = data;
        this.isLoading = false;
        this.year = year;
      },
      error: (error: Error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al obtener las ausencias',
        });
      },
    });
  }

  async getVacationDetail(pk: number) {
    this.vacationBoss.getVacationDetail(pk).subscribe({
      next: (data) => {
        var dateParts = data.start_date.split('-');
        this.detailStart = new Date(
          dateParts[0],
          dateParts[1] - 1,
          dateParts[2]
        );
        dateParts = data.end_date.split('-');
        this.detailEnd = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        this.detailId = data.id;
        this.detailEmployee = data.employee;
        this.detailSubstitute = data.substitute;
        this.detailComments = data.comments;
        this.detailBossComments = data.boss_comments;
        this.detailStatus = data.status;
        if (this.detailStatus != 'En espera') {
          this.isBlocked = false;
        } else {
          this.isBlocked = true;
        }
      },
      error: (error: Error) => {},
    });
  }
  async getLeaveDetail(pk: number) {
    this.leaveBoss.getLeaveDetail(pk).subscribe({
      next: (data) => {
        var dateParts = data.start_date.split('-');
        this.detailStart = new Date(
          dateParts[0],
          dateParts[1] - 1,
          dateParts[2]
        );
        var dateParts = data.end_date.split('-');
        this.detailEnd = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        this.detailMotive = data.motive;
        this.detailRelative = data.relative;
        this.base64DetailBeforeProof = data.proof;
        this.detailStatus = data.status;
        if (this.detailStatus != 'En espera') {
          this.isAccepted = true;
        }
        this.detailComments = data.comments;
        this.detailBossComments = data.boss_comments;
        this.detailOtherType = data.other_type;
        this.detailTime = data.time;
        this.detailRecoveryTime = data.recovery_time;
        this.detailProofType = data.proof_type;
        if (data.recovery_day != '') {
          var recoveryDatesText = data.recovery_day;
          var recoveryDatesArray = recoveryDatesText.split(', ');
          var recoveryDates = recoveryDatesArray.map(
            (dateText: string) => new Date(dateText)
          );
          this.detailRecoveryDay = recoveryDates;
          this.recoveryDetailDecision = true;
        } else {
          this.detailRecoveryDay = [];
          this.recoveryDetailDecision = false;
        }

        this.detailId = data.id;
        this.detailEmployee = data.employee;
      },
      error: (error: Error) => {},
    });
  }

  async getIncapacityDetail(pk: number) {
    this.incapacityBoss.getIncapacityDetail(pk).subscribe({
      next: (data) => {
        var dateParts = data.start_date.split('-');
        this.detailStart = new Date(
          dateParts[0],
          dateParts[1] - 1,
          dateParts[2]
        );
        dateParts = data.end_date.split('-');
        this.detailEnd = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        this.detailId = data.id;
        this.detailEmployee = data.employee;
        this.detailMotive = data.motive;
        this.detailDuration = data.duration_days;
        this.detailComments = data.comments;
        this.detailBossComments = data.boss_comments;
        this.detailStatus = data.status;
        this.detailBase64Imss = data.imss_document;
        this.detailImssType = data.imss_type;

        // Aquí puedes hacer cualquier manipulación o asignación de datos en el componente
      },
      error: (error: Error) => {
        // Manejo de errores
      },
    });
  }

  cleanDetailForm() {
    this.recoveryDetailDecision = false;
    this.detailStart = new Date();
    this.detailEnd = new Date();
    this.detailRelative = '';
    this.detailProof = new File([], '');
    this.base64DetailProof = '';
    this.detailComments = '';
    this.detailBossComments = '';
    this.detailMotive = '';
    this.detailOtherType = '';
    this.detailTime = '';
    this.detailRecoveryTime = '';
    this.detailRecoveryDay = [];
    this.detailStatus = '';
    this.isAccepted = false;
    this.detailEmployee = '';
    this.detailSubstitute = '';

    this.detailDuration = 0;
    this.detailBase64Imss = '';
    this.detailImssType = '';
  }
  showReportDialog() {
    this.reportDialog = true;
  }
  getReport() {
    const convStart =
      this.reportStartDate.getFullYear() +
      '-' +
      (this.reportStartDate.getMonth() + 1) +
      '-' +
      this.reportStartDate.getDate();
    const convEnd =
      this.reportEndDate.getFullYear() +
      '-' +
      (this.reportEndDate.getMonth() + 1) +
      '-' +
      this.reportEndDate.getDate();
    this.absencesBoss
      .getAbsencesReport(convStart, convEnd, this.reportStatus, this.reportName,this.reportPaid)
      .subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          const textStart =
            this.reportStartDate.getDate().toString().padStart(2, '0') +
            '-' +
            (this.reportStartDate.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            this.reportStartDate.getFullYear();
          const textEnd =
            this.reportEndDate.getDate().toString().padStart(2, '0') +
            '-' +
            (this.reportEndDate.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            this.reportEndDate.getFullYear();
          const file_name =
            'Reporte de ausencias del ' + textStart + ' al ' + textEnd + '.pdf';
          link.download = file_name;
          link.click();

          this.messageService.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Archivo generado.',
          });
        },
        error: (error: Error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al obtener el archivo.',
          });
        },
      });
  }

  cleanReportForm() {
    this.reportStartDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.reportEndDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    this.reportStatus = [];
    this.reportPaid=[]
    this.reportName = '';
  }

  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(
      this.base64ToBlob(this.base64DetailBeforeProof, this.detailProofType)
    );
    element.download = 'Comprobante del permiso ' + this.detailId;
    element.click();
  }
  downloadImssFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(
      this.base64ToBlob(this.detailBase64Imss, this.detailImssType)
    );
    element.download = 'Documento de la incapacidad-IMSS ' + this.detailId;
    element.click();
  }
  base64ToBlob(base64Data: string, type: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime');
    const mimeType = mimeTypes.getType(type);
    if (mimeType != null) {
      this.mimeType = mimeType;
    }

    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }
}
