import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { MyProfileDataService } from '../services/Profile/my-profile-data.service';
import { MyVacationDataService } from '../services/Vacation/my-vacation-data.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-my-vacations',
  templateUrl: './my-vacations.component.html',
  styleUrls: ['./my-vacations.component.css'],
  providers: [MessageService]
})
export class MyVacationsComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;

  vac_days: number=0;
  rem_days:number =0;
  saturdays:number =0;
  next_vac_days: number =0;
  next_rem_days:number =0;
  next_saturdays:number =0;
  period:string='';
  next_period:string='';

  selectedStart: Date;
  selectedEnd: Date;
  selectedSubstitute:string;
  selectedComments:string;

  detailId: number =0;
  detailStart: Date=new Date();
  detailEnd: Date =new Date();
  detailSubstitute:string ="";
  detailComments:string ="";
  detailBossComments:string ="";
  detailStatus:string="";
  detailPeriod:string="";
  detailWorkDays:number=0;
  detailSaturdays:number=0;

  visibleModal: boolean=false;
  vacations: any[] = [];
  visibleDetail: boolean = false;

  filter_text:string ="";




  constructor(
    private messageService: MessageService,
    private vacationService: MyVacationDataService,
    private profileService: MyProfileDataService
    ){
    this.selectedStart=new Date();
    this.selectedEnd=new Date();
    this.selectedSubstitute=""
    this.selectedComments=""


  }

  ngOnInit() {
    this.getProfileData()
    this.getVacationsData()

  }





  getColors(status: string) {
    switch (status) {
        case 'Aceptada':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        case 'Terminada':
            return 'finished';
        case 'En espera':
            return 'waiting';
        case 'Cancelada':
            return 'canceled';
        default:
          return 'inProcess';
    }

}



showDialog() {
    this.visibleModal = true;
}
showDetail(vacation: any) {
  this.visibleDetail = true;
  this.getVacationDetail(vacation.id)

}
clear(table: Table) {
  table.clear();
}

getVacationsData()
  {
    this.vacationService.getVacationsData().subscribe({
      next: data => {
        this.vacations=data;
        this.isLoading=false
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las vacaciones' });
      }
  });
  }

postVacationData()
  {
    const flag=this.validateForm()
    if (!flag){
      return;
    }
    const convStart=this.selectedStart.getFullYear()+"-"+(this.selectedStart.getMonth()+1)+"-"+this.selectedStart.getDate()
    const convEnd=this.selectedEnd.getFullYear()+"-"+(this.selectedEnd.getMonth()+1)+"-"+this.selectedEnd.getDate()
    const myVacationObject = {
      employee:"",
      start_date: convStart,
      end_date: convEnd,
      substitute: this.selectedSubstitute,
      comments: this.selectedComments
    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.vacationService.newVacationData(myVacationObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleModal = false;
      this.cleanForm()


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  putVacationData()
  {
    const flag=this.validateDetailForm()
    if (!flag){
      return;
    }
    const convStart=this.detailStart.getFullYear()+"-"+(this.detailStart.getMonth()+1)+"-"+this.detailStart.getDate()
    const convEnd=this.detailEnd.getFullYear()+"-"+(this.detailEnd.getMonth()+1)+"-"+this.detailEnd.getDate()
    const myVacationChangeObject = {
      id:this.detailId,
      employee:"",
      start_date: convStart,
      end_date: convEnd,
      substitute: this.detailSubstitute,
      comments: this.detailComments,
      change_type:"",

    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.vacationService.changeVacationData(this.detailId,myVacationChangeObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  deleteVacationData()
  {


    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.vacationService.deleteVacationData(this.detailId).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }

  async getVacationDetail(pk:number)
  {
    this.vacationService.getVacationDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailSubstitute=data.substitute
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailPeriod=data.period
        this.detailWorkDays=data.work_days
        this.detailSaturdays=data.saturdays
        if (this.detailStatus=="En espera" || this.detailStatus=="Aceptada" || this.detailStatus=="En proceso"){
          this.isBlocked=false
        }
        else
        {
          this.isBlocked=true
        }


      },
      error: (error: Error) => {
      }
  });
  }








  getProfileData()
  {
    this.profileService.getProfileData().subscribe({
      next: data => {
          this.vac_days=data.vacations_days;
        this.rem_days=data.rem_days;
        this.saturdays=data.saturdays;
        this.next_vac_days=data.next_vacations_days;
        this.next_rem_days=data.next_rem_days;
        this.next_saturdays=data.next_saturdays;
        this.period=data.period;
        this.next_period=data.next_period;



      },
      error: (error: Error) => {
      }
  });
  }

validateForm() {
  if (!this.selectedStart || !this.selectedEnd  || !this.selectedSubstitute) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
  }
  if (this.selectedStart>this.selectedEnd && this.selectedStart!=this.selectedEnd) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, Ingresa fechas validas' });
    return false;
  }



    return true;

}
validateDetailForm() {
  if (!this.detailStart || !this.detailEnd  || !this.detailSubstitute) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
  }
  if (this.detailStart>this.detailEnd && this.detailStart!=this.detailEnd) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, Ingresa fechas validas' });
    return false;
  }



    return true;

}

cleanForm()
{
  this.selectedStart=new Date();
    this.selectedEnd=new Date();
    this.selectedSubstitute=""
    this.selectedComments=""
}

}
