<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">

    <div class="col-10" style="margin:auto;">
        <h2 class="titulo">Mis vacaciones.</h2>
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-6">
          <div class="grid">
              <div class="col-12 md:col-12 lg:col-12" style="margin: auto;">
              <h3>Periodo actual: {{period}}.</h3>
              </div>
              <div class="col-9 md:col-9 lg:col-10">
                  <h3 style="font-weight: normal;">Dias de vacaciones del periodo actual.</h3>
              </div>
              <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
              <input type="text" pInputText [(ngModel)]="vac_days" name="vac_days" placeholder="Dias de vacaciones al año" disabled>
              </div>
              <div class="col-9 md:col-9 lg:col-10">
              <h3 style="font-weight: normal;">Dias de vacaciones restantes del periodo actual.</h3>
              </div>
              <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
              <input type="text" pInputText [(ngModel)]="rem_days" name="rem_days" placeholder="Dias de vacaciones restantes" disabled style="width: 100%; bottom: 0;">
              </div>
              <div class="col-9 md:col-9 lg:col-10">
                <h3 style="font-weight: normal;">Sabados pendientes del periodo actual.</h3>
                </div>
                <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
                <input type="text" pInputText [(ngModel)]="saturdays" name="saturdays" placeholder="Sabados por cubrir restantes" disabled style="width: 100%; bottom: 0;">
                </div>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <div class="grid">
            <div class="col-9 md:col-9 lg:col-10">
              <h3> Siguiente periodo: {{next_period}}.</h3>
              </div>
            <div class="col-9 md:col-9 lg:col-10">
                <h3 style="font-weight: normal;">Dias de vacaciones del siguiente periodo laboral.</h3>
            </div>
            <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
            <input type="text" pInputText [(ngModel)]="next_vac_days" name="next_vac_days" placeholder="Dias de vacaciones del año siguiente" disabled>
            </div>
            <div class="col-9 md:col-9 lg:col-10">
            <h3 style="font-weight: normal;">Dias de vacaciones restantes del siguiente periodo laboral.</h3>
            </div>
            <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
            <input type="text" pInputText [(ngModel)]="next_rem_days" name="next_rem_days" placeholder="Dias de vacaciones restantes del año siguiente" disabled style="width: 100%; bottom: 0;">
            </div>
            <div class="col-9 md:col-9 lg:col-10">
              <h3 style="font-weight: normal;">Sabados pendientes del siguiente periodo laboral.</h3>
              </div>
              <div class="col-3 md:col-3 lg:col-2" style="align-items: center;display: flex;">
              <input type="text" pInputText [(ngModel)]="next_saturdays" name="next_saturdays" placeholder="Sabados por cubrir restantes" disabled style="width: 100%; bottom: 0;">
              </div>
        </div>
      </div>
      <div class="col-8">
        <h3>Solicitud de vacaciones</h3>
        </div>
        <div class="col-4" style="align-items: center;display: flex;">
            <button (click)="showDialog()"  class="btn" style="margin-top: 2%;">Solicitar</button>
        </div>
    </div>
        <p-table #dt1 [value]="vacations" [tableStyle]="{'min-width': '40rem'}"  selectionMode="single"   [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
        [globalFilterFields]="['start_date', 'end_date', 'substitute', 'status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th pSortableColumn="start_date">Fecha de inicio <p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin <p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="substitute">Sustituto <p-sortIcon field="substitute"></p-sortIcon></th>
                    <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vacation >
                <tr (click)="showDetail(vacation)">
                    <td>{{ vacation.id }}</td>
                    <td>{{ vacation.start_date }}</td>
                    <td>{{ vacation.end_date }}</td>
                    <td>{{ vacation.substitute }}</td>
                    <td><tag  [ngClass]="getColors(vacation.status)">{{vacation.status}}</tag></td>
                </tr>
            </ng-template>


        </p-table>
        <h4>*Si deseas consultar, cambiar o cancelar una solicitud solo seleccionala.</h4>
        <div class="grid">
            <div class="col-8">
                <h3>Solicitudes de cambios:</h3>
            </div>
            <div class="col-4" style="align-items: center;display: flex;">
              <a href="/myVacationsChanges" style="width: 100%;">
                <button  href="/myVacationsChanges" type="submit" class="btn" style="margin-top: 2%;">Consultar</button>
              </a>
            </div>

            </div>
</div>

</div>
<p-dialog header="Solicitud de vacaciones" [(visible)]="visibleModal" [modal]="true" [draggable]="false" (onHide)="cleanForm()">
      <div class="container" style="min-width: 30vw;">

        <div class="content-input">
          <label htmlFor="selected-start">Fecha de inicio</label>
            <p-calendar [(ngModel)]="selectedStart" name="selected-start"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"></p-calendar>
        </div>
      </div>
      <div class="container">

        <div class="content-input" style="overflow: auto;">
          <label htmlFor="selected-end">Fecha de fin</label>
            <p-calendar [(ngModel)]="selectedEnd" name="selected-end"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" [minDate]="selectedStart" class="select-dates"></p-calendar>
        </div>
      </div>
      <div class="container">

        <div class="content-input" >
          <label htmlFor="selected-substitute">Sustituto</label>
            <input type="text" pInputText [(ngModel)]="selectedSubstitute" name="selected-substitute" placeholder="Sustituto" >
        </div>
      </div>
      <div class="container">

        <div class="content-input">
          <label htmlFor="selected-comment">Comentarios</label>
            <textarea  type="text" pInputText [(ngModel)]="selectedComments" name="selected-comment" placeholder="Comentarios" ></textarea>
        </div>
      </div>
        <div  style="display: inline-block;width: 100%;">
            <button  (click)="postVacationData()" type="submit"  class="btn" style="margin-top: 5%;">Finalizar solicitud</button>
        </div>

</p-dialog>

<p-dialog [header]="'Resumen de la solicitud '+detailId" [(visible)]="visibleDetail" [modal]="true" [draggable]="false" >
  <div class="container" style="min-width: 30vw;">

    <div class="content-input">
      <label htmlFor="selected-period">Periodo</label>
        <input type="text" pInputText [(ngModel)]="detailPeriod" name="selected-period" placeholder="Periodo" [disabled]="true">
        </div>
    </div>
  <div class="container" >
    <div class="content-input">
      <label htmlFor="detail-start">Fecha de inicio</label>
        <p-calendar [(ngModel)]="detailStart" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="isBlocked"></p-calendar>
    </div>
  </div>
  <div class="container">
    <div class="content-input" >
      <label htmlFor="detail-end">Fecha de fin</label>
        <p-calendar [(ngModel)]="detailEnd" name="detail-end"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="isBlocked"></p-calendar>
    </div>
  </div>
    <div class="grid">
      <div class="col-6" style="padding-bottom: 0;">
        <div style="width: 100%;">
          <label htmlFor="detail-substitute">Dias habiles</label>
          <input type="text" pInputText [(ngModel)]="detailWorkDays" name="detail-substitute"   placeholder="Sustituto" [disabled]="true">
    </div>
  </div>
  <div class="col-6" style="padding-bottom: 0;">
    <div style="width: 100%;">
      <label htmlFor="detail-substitute">Sabados cubiertos</label>
      <input type="text" pInputText [(ngModel)]="detailSaturdays" name="detail-substitute"   placeholder="Sustituto" [disabled]="true">
  </div>
  </div>
  </div>
  <div class="container">

    <div class="content-input" >
      <label htmlFor="detail-substitute">Sustituto</label>
        <input type="text" pInputText [(ngModel)]="detailSubstitute" name="detail-substitute" placeholder="Sustituto" [disabled]="isBlocked">
    </div>
  </div>
  <div class="container">
    <div class="content-input" >
      <label htmlFor="detail-status">Status</label>
        <input type="text" pInputText [(ngModel)]="detailStatus" name="detail-status" placeholder="Status" [disabled]="true" [ngClass]="getColors(detailStatus)">
    </div>
  </div>
  <div class="container">
    <div class="content-input">
      <label htmlFor="detail-comment">Comentarios</label>
        <textarea  type="text" pInputText [(ngModel)]="detailComments" name="detail-comment" placeholder="Comentarios" [disabled]="isBlocked"></textarea>
    </div>
  </div>
  <div class="container" *ngIf="detailBossComments!=''">
    <div class="content-input">
      <label htmlFor="detail-boss">Comentarios del jefe</label>
        <textarea  type="text" pInputText [(ngModel)]="detailBossComments" name="detail-boss" [disabled]="true" placeholder="Comentarios del jefe" [disabled]="true"></textarea>
    </div>
  </div>

    <div style="width: 100%;">
        <button  type="submit"  class="btn" style="margin-top: 2%;" (click)="putVacationData()">Solicitar cambios</button>
    </div>
    <div style="display: inline-block;width: 100%; margin-top: 1%;">
      <button  type="submit"  class="btn" style="background-color: #C2262E;" (click)="deleteVacationData()">Solicitar cancelación</button>
  </div>


</p-dialog>
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
