import { Component,OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  notLogin = true;
  constructor(private config:PrimeNGConfig, private router: Router)
  {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideFooterOnSpecificPage();
      }
    });
  }
  hideFooterOnSpecificPage() {
    const currentRoute = this.router.url;
  const hideFooter = currentRoute === '/';

  this.notLogin = !hideFooter;
  }



  ngOnInit()
  {
    this.config.setTranslation({
      dayNamesMin:["Do","Lu","Ma","Mi","Ju","Vi","Sa"],

      monthNames:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]

  });
  }

}

