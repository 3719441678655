import { Component, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MessageService } from 'primeng/api';
import { MyNotificationsService } from '../services/Notification/my-notifications.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [MessageService]
})
export class HomeComponent implements AfterViewInit,OnInit{
  sidebarVisible: boolean = false;
  user_type: string='Subordinado'
  styles: any;
  logoUrl = 'assets/logos/Logo_Blanco.png';
  currentUrl: string
  newNotifications: boolean = false;

  constructor(private router: Router,private elementRef: ElementRef,private loginService: LoginService,
    private messageService: MessageService,
    private notificationService: MyNotificationsService,) {

    this.currentUrl = this.router.url;

  }
  ngOnInit()  {
    this.getNotificationsStatus();
  }

  ngAfterViewInit(): void {
    const topBarHeight = this.elementRef.nativeElement.querySelector('.top-bar').getBoundingClientRect().height;
    const contentElement = this.elementRef.nativeElement.querySelector('.top-block');
    contentElement.style.marginTop = `${topBarHeight}px`;
  }
    getNotificationsStatus()
    {
      this.notificationService.getNotificationsStatus().subscribe({
        next: data => {
          if(data.status=='Not read' && this.router.url!='/notifications')
          {
            this.newNotifications=true;
          }
          this.user_type=data.user_type;

        },
        error: (error: Error) => {
        }
    });
    }


  logout() {
    this.loginService.logout().subscribe({
      next: response => {
        console.log("hola")
        localStorage.removeItem('key');
        this.router.navigate(['/']);
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar' });

      }
    });


  }
  getStatus()
  {
    if (this.newNotifications)
    {
      return 'newNotifications'
    }
    else
    {
      return ''
    }
  }


  showSidebar() {
    this.sidebarVisible = true;
  }

  hideSidebar() {
    this.sidebarVisible = false;
  }
}
