import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { MyNotificationsService } from '../services/Notification/my-notifications.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  providers: [MessageService]
})
export class NotificationsComponent implements OnInit{
  items: MenuItem[]=[];

  activeItem: MenuItem=this.items[0];
  notifications : any[] = [];
  filteredVacations: any[] = [];
  filteredLeaves: any[] = [];
  filteredIncapacities: any[] = [];
  filteredPersonal: any[] = [];
  isLoading: boolean = true;
  constructor(
    private messageService: MessageService,
    private notificationService: MyNotificationsService,
    ){}


  ngOnInit() {
      this.items = [
        { label: 'Todas', icon: 'pi pi-fw pi-book' },
      { label: 'Vacaciones', icon: 'pi pi-fw pi-sun' },
      { label: 'Permisos', icon: 'pi pi-fw pi-calendar' },
      { label: 'Incapacidades', icon: 'pi pi-fw pi-heart' },
];
      this.activeItem = this.items[0];

      this.getNotificationsData();
    3

  }
  onActiveItemChange(event: MenuItem){
    this.activeItem = event;
}
getIconClass(type: string) {
  switch (type) {
    case 'vacation':
      return 'pi pi-sun';
    case 'leave':
      return 'pi pi-calendar';
    case 'incapacity':
        return 'pi pi-heart';
    default:
      return 'pi pi-bell';
  }
}
getNotificationsData()
  {
    this.notificationService.getNotificationsData().subscribe({
      next: data => {
        this.notifications=data;
        this.isLoading=false
        this.filteredVacations = this.notifications.filter( notification => notification.type === 'vacation');
        this.filteredLeaves = this.notifications.filter( notification => notification.type === 'leave');
        this.filteredIncapacities = this.notifications.filter( notification => notification.type === 'incapacity');
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las vacaciones' });
      }
  });
  }
getStatus(status: string) {
  switch (status) {
      case 'Not read':
          return 'notification-delivered';
      case 'Read':
          return 'notification-read';
      default:
        return 'notification-read';
  }

}
}
