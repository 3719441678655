import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RhBossOptionsService } from '../services/System-Management/rh-boss-options.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-system-management',
  templateUrl: './system-management.component.html',
  styleUrls: ['./system-management.component.css'],
  providers:[MessageService,ConfirmationService]
})
export class SystemManagementComponent {
  seeDataDialog:boolean=false
  consultType:string=""
  festiveDays:any[]=[]
  branches:any[]=[]
  areas:any[]=[]
  enterprises:any[]=[]
  pendingVacations: any[]=[]
  employeeNumber:string=""
  automaticPasswordDialog:boolean=false
  automaticPassword:string=""

  addDataDialog: boolean=false
  addType: string=""
  festivity:string=""
  festivityDate:Date =new Date()
  branchCity:string=""
  areaName:string=""
  enterpriseName:string=""

  constructor(private rhBossService: RhBossOptionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService)
  {}


  showDialog(showType:number){
    switch(showType)
    {
      case 0:
      {
        this.consultType="Dia Festivo"
        this.getFestiveDays()
        break;
      }
      case 1:
      {
        this.consultType="Area"
        this.getAreas()
        break;
      }
      case 2:
      {
        this.consultType="Empresa"
        this.getEnterprises()
        break;
      }
      case 3:
      {
        this.consultType="Sucursal"
        this.getBranches()
        break;
      }
      case 4:
        {
          this.consultType="Contraseñas"
          break;
        }
      case 5:
        {
          this.consultType="Vacaciones"
          this.getPendingVacations()
          break;
        }

    }
    this.seeDataDialog=true
  }

  showAddDialog(addType: number){
    switch(addType)
    {
      case 0:
      {
        this.addType="Dia Festivo"
        break;
      }
      case 1:
      {
        this.addType="Area"
        break;
      }
      case 2:
      {
        this.addType="Empresa"
        break;
      }
      case 3:
      {
        this.addType="Sucursal"
        break;
      }
    }
    this.addDataDialog=true
  }


  getFestiveDays()
  {
    this.rhBossService.getFestiveDays().subscribe({
      next: data => {
        this.festiveDays=data

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los dias festivos' });
      }
    });
  }

  addFestivity()
  {
      const convDate=this.festivityDate.getFullYear()+"-"+(this.festivityDate.getMonth()+1)+"-"+this.festivityDate.getDate()

      const festivityObject={
        festivity:this.festivity,
        date:convDate
      }
      this.rhBossService.postFestiveDay(festivityObject).subscribe({
        next: data => {
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Dia festivo agregado' });
          this.addDataDialog=false
          this.cleanAddForm()


      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

      }
    });

  }
  deleteFestivity(rowData: any)
  {
    this.rhBossService.deleteFestiveDay(rowData.id).subscribe({
      next: data => {

        const index=this.festiveDays.indexOf(rowData)
        if (index!=-1)
          this.festiveDays.splice(index,1)
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Dia festivo eliminada' });
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

      }
    })
  }

  getBranches()
  {
    this.rhBossService.getBranches().subscribe({
      next: data => {
        this.branches=data

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las sucursales' });
      }
    });
  }

  addBranch()
  {
    const branchObject={
      city:this.branchCity
    }
    this.rhBossService.postBranch(branchObject).subscribe({
      next: data => {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Sucursal agregada' });
        this.addDataDialog=false
        this.cleanAddForm()


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });
  }
  deleteBranch(rowData: any)
  {
    this.rhBossService.deleteBranch(rowData.id).subscribe({
      next: data => {

        const index=this.branches.indexOf(rowData)
        if (index!=-1)
          this.branches.splice(index,1)
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Sucursal eliminada' });
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

      }
    })
  }

  getAreas()
  {
    this.rhBossService.getAreas().subscribe({
      next: data => {
        this.areas=data

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las areas' });
      }
    });
  }

  addArea()
  {
    const areaObject={
      name:this.areaName
    }
    this.rhBossService.postArea(areaObject).subscribe({
      next: data => {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Area agregada' });
        this.addDataDialog=false
        this.cleanAddForm()


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });
  }
  deleteArea(rowData: any)
  {
    this.rhBossService.deleteArea(rowData.id).subscribe({
      next: data => {

        const index=this.areas.indexOf(rowData)
        if (index!=-1)
          this.areas.splice(index,1)
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Area eliminada' });
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

      }
    })
  }

  getEnterprises()
  {
    this.rhBossService.getEnterprises().subscribe({
      next: data => {
        this.enterprises=data

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las empresas' });
      }
    });
  }

  addEnterprise()
  {
    const enterpriseObject={
      name:this.enterpriseName
    }
    this.rhBossService.postEnterprise(enterpriseObject).subscribe({
      next: data => {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Empresa agregada' });
        this.addDataDialog=false
        this.cleanAddForm()


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });
  }
  deleteEnterprise(rowData: any)
  {
    this.rhBossService.deleteEnterprise(rowData).subscribe({
      next: data => {

        const index=this.enterprises.indexOf(rowData)
        if (index!=-1)
          this.enterprises.splice(index,1)
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Empresa eliminada' });
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

      }
    })
  }

  getPendingVacations()
  {
    this.rhBossService.getPendingVacations().subscribe({
      next: data => {
        this.pendingVacations=data
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las vacaciones pendientes' });
      }
    });
  }
  sendMail(rowData: any)
  {
    this.rhBossService.sendReminder(rowData.employee_number).subscribe({
      next: data => {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Recordatorio enviado.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al enviar el recordatorio' });
      }
    });
  }
  getAutomaticPassword()
  {
    this.rhBossService.getAutomaticPassword(this.employeeNumber).subscribe({
      next: data => {
        this.automaticPasswordDialog=true
        this.automaticPassword=data.password
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Contraseña obtenida.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener la contraseña del usuario' });
      }
    });
  }
  confirmPasswordReport()
  {
    this.confirmationService.confirm({
      message: '¿Deseas obtener todas las contraseñas generadas por el sistema?',
      header: 'Advertencia',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.receivePasswordReport()
      },
      reject: () => {

      }
  })
}
  receivePasswordReport()
  {
      this.rhBossService.getAutomaticPasswordReport().subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = 'Contraseñas automaticas en el sistema.pdf';
          link.click()

          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo generado.' });
        },
        error: (error: Error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el archivo.' });
        }
      });
  }

  cleanSeeForm()
  {
    this.seeDataDialog=false
    this.consultType=""
    this.festiveDays=[]
    this.branches=[]
    this.areas=[]
    this.enterprises=[]
    this.employeeNumber=""
    this.automaticPassword=""
  }
  cleanAddForm()
  {
    this.addDataDialog=false
    this.addType=""
    this.festivity=""
    this.festivityDate=new Date()
    this.branchCity=""
    this.areaName=""
    this.enterpriseName=""
  }

}
