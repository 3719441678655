import { Component, OnInit, ViewChild } from '@angular/core';
import { MyProfileDataService } from '../services/Profile/my-profile-data.service';
import { MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
  providers: [MessageService]
})
export class MyProfileComponent implements OnInit{
  @ViewChild('fileClean') fileUpload: any;
  isLoading: boolean = true;
  isRH: boolean = false;
  userType: string = '';

  placeholder_imss: string='';
  userImg='assets/imgs/userImage.png';
  base64ProfileImage: string='';
  base64Imss: string='';

  mimeType: string = '';
  username: string='';
  name: string='';
  phonenumber: string='';
  birthday: string='';
  escolarity: string='';
  curp: string='';
  rfc: string='';

  position: string='';
  branch: string='';
  area: string='';
  enterprise: string='';
  secomail: string='';
  boss: string='';
  vac_days: string='';;
  imss: string='';;
  imss_doc:string='';;
  imss_type:string='';

  start_date:string='';
  seniority: string='';
  end_date: string='';
  rem_days:string='';
  status: string='';
  period: string='';

  visiblePassChange: boolean = false;
  changePass1: string = '';
  changePass2: string = '';


  constructor(private profileService: MyProfileDataService, private messageService: MessageService,private passwordService: LoginService) {

  }
  ngOnInit() {
    this.getProfileData()

  }

  getProfileData()
  {
    this.profileService.getProfileData().subscribe({
      next: data => {
        this.username = data.employee_number;
        this.name = data.name;
        this.phonenumber= data.phonenumber;
        if (data.profile_image != ""){

          this.userImg='data:image/png;base64,' + data.profile_image;
        }
        this.birthday = data.birthday;
        this.escolarity=data.escolarity;
        this.curp = data.CURP;
        this.rfc=data.RFC;
        this.position=data.position;
        this.branch=data.branch;
        this.area=data.area;
        this.enterprise=data.enterprise;
        this.secomail=data.secomail;
        this.boss=data.boss;
        this.vac_days=data.vacations_days;
        this.imss=data.IMSS;
        this.placeholder_imss='Documento del IMSS'


        this.imss_type=data.IMSS_doc_type;
        this.imss_doc=data.IMSS_doc;
        this.start_date=data.start_date;
        if (data.seniority==1)
        {
          this.seniority=data.seniority+' año.';
        }
        else
        {
          this.seniority=data.seniority+' años.';
        }
        this.period=data.period;

        this.end_date=data.end_date;
        this.rem_days=data.rem_days;
        this.status=data.status;
        this.isLoading = false;
        this.userType = data.user_type;
        if (this.userType == 'Recursos Humanos' || this.userType == 'Jefe de Recursos Humanos')
            this.isRH = true;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el perfil' });
      }
  });
  }

  modifyData()
  {
    let myProfileObject = {}
    if (this.userType!='Recursos Humanos' && this.userType!='Jefe de Recursos Humanos')
    {myProfileObject = {
      phonenumber: this.phonenumber
    };
    }
    else
    {
      myProfileObject = {
        name: this.name,
        phonenumber: this.phonenumber,
        escolarity: this.escolarity,
        CURP: this.curp,
        RFC: this.rfc,
        position: this.position,
        branch: this.branch,
        area: this.area,
        enterprise: this.enterprise,
        secomail: this.secomail,
        IMSS: this.imss,
        profile_image: this.base64ProfileImage,
        IMSS_doc: this.base64Imss
      };
    }
    this.profileService.modifyProfileData(myProfileObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Actualización exitosa' });
    },
    error: (error: Error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar' });

    }
  });

  }


  onUploadImage(event: { files: any[]; }) {

    const file = event.files[0];
    if (file.size > 8000000) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
      this.fileUpload.clear();
      return false;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64ProfileImage = reader.result as string;
        this.userImg=this.base64ProfileImage.split(',')[1];
        this.userImg = 'data:image/png;base64,' + this.userImg;
    };
    return true
  }
  onUploadImss(event: { files: any[]; }) {

    const file = event.files[0];
    if (file.size > 8000000) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
      this.fileUpload.clear();
      return false;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64Imss = reader.result as string;
    };
    return true
  }
  changePassword()
  {
    this.passwordService.changePassword(this.changePass1, this.changePass1).subscribe({
      next: response => {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Contraseña cambiada' });
        this.visiblePassChange = false;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cambiar la contraseña'});

      }
    });
  }
  showPassChange()
  {
    this.visiblePassChange = true;
  }


  getColors(status: string) {
    switch (status) {
        case 'Activo':
            return 'accepted';
        case 'De vacaciones':
            return 'inProcess';
        case 'Dado de baja':
            return 'denied';
        case 'En periodo de prueba':
            return 'finished';
        case 'En permiso':
            return 'waiting';
        case 'Incapacitado':
            return 'canceled';
        default:
          return 'inProcess';
    }
}

downloadFile() {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(this.base64ToBlob(this.imss_doc));
  element.download = 'Documento del IMSS de ' + this.username;
  element.click();
}



base64ToBlob(base64Data: string): Blob {
  const byteString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }const base64String = '...'; // Tu cadena base64 aquí
  this.mimeType = 'application/pdf';
  var mimeTypes = require('mime')
  const mimeType = mimeTypes.getType(this.imss_type);
  if (mimeType!=null){
    this.mimeType = mimeType;
  }


  const blob = new Blob([uintArray], { type: this.mimeType });
  return blob;
}



}

