import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { RhVacationsService } from 'src/app/services/Vacation/rh-vacations.service';

@Component({
  selector: 'app-rh-vacation-changes',
  templateUrl: './rh-vacation-changes.component.html',
  styleUrls: ['./rh-vacation-changes.component.css'],
  providers: [MessageService]
})
export class RhVacationChangesComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;
  detailId: number =0;
  detailEmployee: string ="";
  detailBoss: string ="";
  detailStart: Date=new Date();
  detailEnd: Date =new Date();
  detailSubstitute:string ="";
  detailComments:string ="";
  detailBossComments:string ="";
  detailStatus:string="";
  detailChange:string="";
  detailBeforeDays:number=0;
  detailBeforeSaturdays:number=0;
  detailDays:number=0;
  detailSaturdays:number=0;

  beforeId: number =0;
  beforeStart: Date=new Date();
  beforeEnd: Date =new Date();
  beforeSubstitute:string ="";
  vac_changes: any[] = [];
  visibleDetail: boolean = false;

  filter_text:string ="";
  years:number[]=[]
  year:number=new Date().getFullYear();
  constructor(
    private messageService: MessageService,
    private rhChangeService: RhVacationsService
    ){
  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023+2;

    this.years = Array.from({ length: numberOfYearsToShow }, (_, index) => 2023 + index);
    this.getVacationsChangesRhData(this.year)

  }
  getColors(status: string) {
    switch (status) {
        case 'Aceptado':
            return 'accepted';
        case 'Rechazado':
            return 'denied';
        case 'En espera':
            return 'waiting';
        default:
          return 'waiting';
    }

}
getChangeColors(status: string) {
  switch (status) {
      case 'Modificación':
          return 'modification';
      case 'Cancelación':
          return 'cancelation';
      default:
        return 'modification';
  }
}
showDetail(vacation: any) {
  this.visibleDetail = true;
  this.getVacationChangeDetail(vacation.id)

}
clear(table: Table) {
  table.clear();
}
getVacationsChangesRhData(year:number)
  {
    this.rhChangeService.getVacationsChangesRhData(year).subscribe({
      next: data => {
        this.vac_changes=data
        this.isLoading=false
        this.year=year

        },
      error: (error: Error) => {
        }
  });

  }
  getVacationChangeDetail(pk:number)
  {
    this.rhChangeService.getVacationChangesDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailEmployee=data.employee
        this.detailBoss=data.boss
        this.detailSubstitute=data.substitute
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailChange=data.change_type
        this.detailBeforeSaturdays=data.before_saturdays
        this.detailBeforeDays=data.before_work_days
        this.detailSaturdays=data.saturdays
        this.detailDays=data.work_days

        this.beforeId=data.vacation.id
        this.beforeSubstitute=data.vacation.substitute
        dateParts = data.vacation.start_date.split('-');
        this.beforeStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.vacation.end_date.split('-');
        this.beforeEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);

        if (this.detailStatus=="En espera" ){
          this.isBlocked=false
        }
        else
        {
          this.isBlocked=true
        }
        // Aquí puedes hacer cualquier manipulación o asignación de datos en el componente
      },
      error: (error: Error) => {
        // Manejo de errores
      }
  });
  }





}
