<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">
<div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
  <h3 class="title">Informacion del empleado.</h3>
  <div class="frame" style="display: flex; align-items: center; justify-content: center;">

    <img [src]="userImg">
  </div>

   <div class="field" *ngIf="userType=='Recursos Humanos' || userType=='Jefe de Recursos Humanos'">
    <div class="flex flex-column gap-2" style="width: 50%;margin: auto;">
      <label htmlFor="username" style="text-align: center;">Foto de perfil: </label>
      <p-fileUpload mode="basic"  chooseIcon="pi pi-camera" accept="image/*" name="proof[]" (onSelect)="onUploadImage($event)"  [maxFileSize]='8000000' #fileClean uploadStyleClass="width:50%"></p-fileUpload>

    </div>
</div>
    <div class="field">
            <div class="flex flex-column gap-2">
              <label htmlFor="username">Numero de empleado</label>
              <input type="text" pInputText [(ngModel)]="username" name="username" placeholder="Numero de empleado" disabled>
            </div>
    </div>

        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="nombre">Nombre completo</label>
            <input type="text" pInputText [(ngModel)]="name" name="nombre" placeholder="Nombre" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="telefono">Telefono</label>
            <input type="text" pInputText [(ngModel)]="phonenumber" name="telefono" placeholder="Telefono" >
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="fecNacimiento">Fecha de nacimiento</label>
            <input type="text" pInputText [(ngModel)]="birthday" name="fecNacimiento" placeholder="Fecha de nacimiento" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">Escolaridad</label>
            <input type="text" pInputText [(ngModel)]="escolarity" name="escolaridad" placeholder="Escolaridad" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">CURP</label>
            <input type="text" pInputText [(ngModel)]="curp" name="curp" placeholder="CURP" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">RFC</label>
            <input type="text" pInputText [(ngModel)]="rfc" name="rfc" placeholder="RFC" [disabled]="!isRH">
          </div>
        </div>
</div>



<div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
  <h3 class="title">Informacion en la empresa.</h3>

    <div class="field">
            <div class="flex flex-column gap-2">
              <label htmlFor="username">Puesto</label>
              <input type="text" pInputText [(ngModel)]="position" name="position" placeholder="Puesto" [disabled]="!isRH">
            </div>
    </div>

        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="nombre">Sucursal</label>
            <input type="text" pInputText [(ngModel)]="branch" name="brach" placeholder="Sucursal" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="telefono">Area</label>
            <input type="text" pInputText [(ngModel)]="area" name="area" placeholder="Area" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="fecNacimiento">Empresa</label>
            <input type="text" pInputText [(ngModel)]="enterprise" name="enterprise" placeholder="Empresa" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">Correo de Secovam</label>
            <input type="text" pInputText [(ngModel)]="secomail" name="mail" placeholder="Correo de Secovam" [disabled]="!isRH">
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">Jefe inmediato</label>
            <input type="text" pInputText [(ngModel)]="boss" name="boss" placeholder="Jefe Inmediato" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">Dias de vacaciones al año</label>
            <input type="text" pInputText [(ngModel)]="vac_days" name="vac_days" placeholder="Dias de vacaciones al año" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">IMSS</label>
            <input type="text" pInputText [(ngModel)]="imss" name="imss" placeholder="imss" [disabled]="!isRH">
          </div>
        </div>
        <div class="field" *ngIf="userType=='Recursos Humanos' || userType=='Jefe de Recursos Humanos'" style="display: flex;">

          <div class="grid">
            <div class="col-7 md:col-7 lg:col-8" style="bottom: 0;" (click)="downloadFile()">
              <label for="detail-proof">Alta del IMSS</label>
              <input type="text"  pInputText [(ngModel)]="placeholder_imss" name="imss_doc"  placeholder="Alta del IMSS" disabled style=" cursor: pointer; text-decoration: underline;">
            </div>
            <div class="col-5 md:col-5 lg:col-4">
          <label for="detail-proof">Cambiar </label>
          <p-fileUpload mode="basic"  chooseIcon="pi pi-folder" name="proof[]" (onSelect)="onUploadImss($event)" #fileClean></p-fileUpload>
        </div>
        </div>

        </div>

        <div class="field" *ngIf="userType=='Subordinado' || userType=='Jefe'">
          <div class="flex flex-column gap-2"  (click)="downloadFile()" style=" cursor: pointer;">

            <label htmlFor="username">Alta del IMSS</label>
            <input type="text" pInputText [(ngModel)]="placeholder_imss" name="imss_doc"  placeholder="Alta del IMSS" disabled style=" cursor: pointer; text-decoration: underline;">


          </div>
        </div>

</div>


<div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
  <h3 class="title">Estado del empleado.</h3>
  <div class="field" *ngIf="userType!='Subordinado'">
    <div class="flex flex-column gap-2">
      <label htmlFor="username">Tipo de usuario</label>
      <input type="text" pInputText [(ngModel)]="userType" name="start_date" placeholder="Tipo de usuario" disabled>
    </div>
  </div>

  <div class="field">
            <div class="flex flex-column gap-2">
              <label htmlFor="username">Fecha de ingreso</label>
              <input type="text" pInputText [(ngModel)]="start_date" name="start_date" placeholder="Fecha de ingreso" disabled>
            </div>
    </div>

        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="nombre">Antigüedad</label>
            <input type="text" pInputText [(ngModel)]="seniority" name="seniority" placeholder="Antigüedad" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2" *ngIf="end_date!==null">
            <label htmlFor="telefono">Fecha de salida</label>
            <input type="text" pInputText [(ngModel)]="end_date" name="end_date" placeholder="Fecha de salida" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="fecNacimiento">Periodo vacacional actual</label>
            <input type="text" pInputText [(ngModel)]="period" name="period" placeholder="Periodo vacacional" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="fecNacimiento">Dias restantes de vacaciones</label>
            <input type="text" pInputText [(ngModel)]="rem_days" name="rem_days" placeholder="Dias restantes de vacaciones" disabled>
          </div>
        </div>
        <div class="field">
          <div class="flex flex-column gap-2">
            <label htmlFor="username">Status</label>
            <input type="text" pInputText [(ngModel)]="status" [ngClass]="getColors(status)" name="status" placeholder="Status" disabled>
          </div>
        </div>

</div>
<div class="col-10 md:col-11 lg:col-11" style="margin:auto;">
  <button  type="submit"  (click)="modifyData()" class="btn" style="margin-top: 1%;">Guardar cambios.</button>
  <button  type="submit"  (click)="showPassChange()" class="btn" style="margin-top: 1%;">Cambiar contraseña.</button>
</div>

</div>
<div *ngIf="userType=='Jefe'">
<app-boss-employees></app-boss-employees>
</div>
</ng-container>
<p-dialog [header]="'Cambio de contraseña'" [(visible)]="visiblePassChange" [modal]="true" [draggable]="false">
  <div class="container" style="min-width: 30vw;">
    <div class="content-input">
      <label htmlFor="detail-pass1">Nueva contraseña</label><br>
      <input type="password" pInputText [(ngModel)]="changePass1" name="detail-pass1" placeholder="Contraseña" style="font-weight: normal; width:100%">
    </div>
  </div>
  <div class="container">
    <div class="content-input" >
      <label htmlFor="detail-pass2">Repite la contraseña</label><br>
      <input type="password" pInputText [(ngModel)]="changePass2" name="detail-pass2" placeholder="Escribe la contraseña de nuevo" style="font-weight: normal; width:100%">
    </div>
  </div>
  <div style="display: inline-block;width: 100%;">
    <button type="submit" class="btn" style="margin-top: 2%;" (click)="changePassword()">Solicitar cambio.</button>
  </div>
  </p-dialog>

<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
