import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BossVacationsService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getVacationsBossData(year:number): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/?year=`+year;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getVacationDetail(pk:number): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }

  updateVacationStatus(pk:number,data:any): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getVacationsChangesBossData(year:number): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/changes/?year=`+year;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getVacationChangesDetail(pk:number): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/changes/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  updateVacationChangesStatus(pk:number,data:any): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/changes/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getVacationsReport(start_date:string,end_date:string,status:string[],employee:string): Observable<Blob>
  {
    const url = `${this.baseUrl}/boss/vacations/report/?start_date=`+start_date+'&end_date='+end_date+'&status='+status+'&employee='+employee;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    const options=
    {
      headers:headers,
      responseType:'blob' as 'json'
    }
    return this.http.get<Blob>(url, options).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  checkNewChanges(): Observable<any> {
    const url = `${this.baseUrl}/boss/vacations/check/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }



}
