import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyLeaveDataService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getLeavesData(): Observable<any> {
    const url = `${this.baseUrl}/myLeaves/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  newLeaveData(data:any): Observable<any> {
    const url = `${this.baseUrl}/myLeaves/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  getLeaveDetail(pk:number): Observable<any> {
    const url = `${this.baseUrl}/myLeaves/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  changeLeaveData(pk:number,data:any): Observable<any> {
    const url = `${this.baseUrl}/myLeaves/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  deleteLeaveData(pk:number): Observable<any> {
    const url = `${this.baseUrl}/myLeaves/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }



}
