import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyVacationsComponent } from './my-vacations/my-vacations.component';
import { MyLeavesComponent } from './my-leaves/my-leaves.component';
import { MyIncapacitiesComponent } from './my-incapacities/my-incapacities.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LandingComponent } from './landing/landing.component';
import { authGuard } from './auth/auth.guard';
import { loginGuard } from './auth/login.guard';
import { AproveVacationsComponent } from './aprove-vacations/aprove-vacations.component';
import { VacChangesComponent } from './my-vacations/vac-changes/vac-changes.component';
import { bossGuard } from './auth/boss.guard';
import { AproveChangesComponent } from './aprove-vacations/aprove-changes/aprove-changes.component';
import { AproveLeaveComponent } from './aprove-leave/aprove-leave.component';
import { BossAbsencesComponent } from './boss-absences/boss-absences.component';
import { BossEmployeesComponent } from './boss-employees/boss-employees.component';
import { RhVacationsComponent } from './rh-vacations/rh-vacations.component';
import { rhGuard } from './auth/rh.guard';
import { RhVacationChangesComponent } from './rh-vacations/rh-vacation-changes/rh-vacation-changes.component';
import { RhLeavesComponent } from './rh-leaves/rh-leaves.component';
import { RhIncapacitiesComponent } from './rh-incapacities/rh-incapacities.component';
import { RhAbsencesComponent } from './rh-absences/rh-absences.component';
import { RhEmployeesComponent } from './rh-employees/rh-employees.component';
import { bossrhGuard } from './auth/bossrh.guard';
import { SystemManagementComponent } from './system-management/system-management.component';


const routes: Routes = [
  {  path: '', component: LoginComponent,  canActivate: [loginGuard]},
  { path: 'home', component: LandingComponent, canActivate: [authGuard]} ,
  { path: 'myProfile',component:MyProfileComponent, canActivate: [authGuard]},
  { path: 'myVacations',component:MyVacationsComponent, canActivate: [authGuard]},
  { path: 'myVacationsChanges',component:VacChangesComponent, canActivate: [authGuard]},
  { path: 'myLeaves',component:MyLeavesComponent, canActivate: [authGuard]},
  { path: 'myIncapacities',component:MyIncapacitiesComponent, canActivate: [authGuard]},
  { path: 'notifications',component:NotificationsComponent, canActivate: [authGuard]},
  //BossOptions
  { path: 'boss/vacations',component:AproveVacationsComponent, canActivate: [bossGuard]},
  { path: 'boss/vacationsChanges',component:AproveChangesComponent, canActivate: [bossGuard]},
  { path: 'boss/leaves',component:AproveLeaveComponent, canActivate: [bossGuard]},
  { path: 'boss/employees',component:BossEmployeesComponent, canActivate: [bossGuard]},
  { path: 'boss/absences',component:BossAbsencesComponent, canActivate: [bossGuard]},
  //RhOptions
  { path: 'rh/vacations',component:RhVacationsComponent, canActivate: [rhGuard]},
  { path: 'rh/vacationsChanges',component:RhVacationChangesComponent, canActivate: [rhGuard]},
  { path: 'rh/leaves',component:RhLeavesComponent, canActivate: [rhGuard]},
  { path: 'rh/incapacities',component:RhIncapacitiesComponent, canActivate: [rhGuard]},
  { path: 'rh/employees',component:RhEmployeesComponent, canActivate: [rhGuard]},
  { path: 'rh/absences',component:RhAbsencesComponent, canActivate: [rhGuard]},
  //RhBossOptions
  { path: 'rh/systemManagement',component:SystemManagementComponent, canActivate: [bossrhGuard]},
  {
    path: '**',
    redirectTo : 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule{



}
