import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BossLeavesService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getLeavesBossData(year:number): Observable<any> {
    const url = `${this.baseUrl}/boss/leaves/?year=`+year;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getLeaveDetail(pk:number): Observable<any> {
    const url = `${this.baseUrl}/boss/leaves/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  updateLeaveStatus(pk:number,data:any): Observable<any> {
    const url = `${this.baseUrl}/boss/leaves/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.put(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  getLeavesReport(start_date:string,end_date:string,status:string[],employee:string,motives:string[],paid:string[]): Observable<Blob>
  {
    const url = `${this.baseUrl}/boss/leaves/report/?start_date=`+start_date+'&end_date='+end_date+'&status='+status+'&employee='+employee+'&motives='+motives+'&paid='+paid;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    const options=
    {
      headers:headers,
      responseType:'blob' as 'json'
    }
    return this.http.get<Blob>(url, options).pipe(
      catchError(error => {
        throw error;
      })
    );
  }


}
