import { Component, ViewChild } from '@angular/core';
import { RhProfilesService } from '../services/Profile/rh-profiles.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RhDataService } from '../services/Absence/rh-data.service';
import { Table } from 'primeng/table';
import { RhProfileOptionsService } from '../services/System-Management/rh-profile-options.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-rh-employees',
  templateUrl: './rh-employees.component.html',
  styleUrls: ['./rh-employees.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class RhEmployeesComponent {
  birthdayImage = 'assets/icons/birthday.png';
  user_statuses :string[];
  user_types:string[]=['Subordinado','Jefe','Recursos Humanos','Jefe de Recursos Humanos'];
  areas:string[]=[];
  branches:string[]=[];
  enterprises:string[]=[];
  bosses:any[]=[];

  newPasswordDialog: boolean = false;
  newPassword: string = '';
  visibleModal:boolean=false;

  @ViewChild('fileClean') fileUpload: any;
  @ViewChild('fileCleanImage') fileUploadImage: any;
  isLoading: boolean = true;
  subordinates: any[] = [];
  absences:any[]=[];
  visibleDetail: boolean = false;
  filter_text:string ="";

  placeholder_imss: string='';
  userImg='assets/imgs/userImage.png';
  mimeType: string = '';
  username: string='';
  name: string='';
  phonenumber: string='';
  birthday:Date=new Date();
  escolarity: string='';
  curp: string='';
  rfc: string='';

  position: string='';
  branch: string='';
  area: string='';
  enterprise: string='';
  secomail: string='';
  boss: any='';
  vac_days: string='';
  imss: string='';
  imss_doc:string='';
  imss_type:string='';
  userType:string='';
  start_date:string='';
  seniority: string='';
  end_date: string='';
  rem_days:string='';
  period='';
  status: string='';

  base64ProfileImage: string='';
  base64Imss: string='';

  new_placeholder_imss: string='';
  new_userImg='assets/imgs/userImage.png';
  new_mimeType: string = '';
  new_username: string='';
  new_name: string='';
  new_phonenumber: string='';
  new_birthday: Date=new Date();
  new_escolarity: string='';
  new_curp: string='';
  new_rfc: string='';
  new_number: string='';

  new_position: string='';
  new_branch: string='';
  new_area: string='';
  new_enterprise: string='';
  new_secomail: string='';
  new_boss: any='';
  new_imss: string='';
  new_imss_doc:string='';
  new_imss_type:string='';
  new_userType:string='';
  new_start_date:Date=new Date();
  new_seniority: number=0;
  new_vac_days: number=0;
  new_saturdays:number=0;
  new_total_vacations:number=0;
  new_total_saturdays:number=0;


  new_base64ProfileImage: string='';
  new_base64Imss: string='';

  addDays: number=0;
  addSaturdays: number=0;
  addDaysDialog: boolean=false;
  constructor(
    private messageService: MessageService,
    private profilesRhService: RhProfilesService,
    private absencesRh: RhDataService,
    private confirmationService: ConfirmationService,
    private systemService: RhProfileOptionsService
    ){
      this.user_statuses = ['Dado de baja','Activo','De vacaciones','En periodo de prueba','En permiso','Incapacitado']

  }

  ngOnInit() {
    this.getProfilesRhData()
    this.cleanFillProfile()
    this.getFillProfileData()
  }
  getColors(status: string) {
    switch (status) {
        case 'Activo':
            return 'inProcess';
        case 'De vacaciones':
            return 'accepted';
        case 'Dado de baja':
            return 'denied';
        case 'En periodo de prueba':
            return 'finished';
        case 'En permiso':
            return 'waiting';
        case 'Incapacitado':
            return 'canceled';
        default:
          return 'inProcess';
    }
}
  getColorsProfile(status: string) {
    switch (status) {
        case 'Activo':
            return 'active';
        case 'De vacaciones':
            return 'vacations';
        case 'Dado de baja':
            return 'discharged';
        case 'En periodo de prueba':
            return 'testPeriod';
        case 'En permiso':
            return 'leave';
        case 'Incapacitado':
            return 'incapacitated';
        default:
          return 'vacations';
    }
}
isBirthday(textDate: String): boolean {
  const [day, month, year] = textDate.split('-');
  const birthday = new Date(+year, +month - 1, +day);
  const today = new Date();
  return birthday.getMonth() === today.getMonth() && birthday.getDate() === today.getDate();

}
showDetail(subordinate: any) {
  this.visibleDetail = true;
  this.getProfileData(subordinate.employee_number)
  this.getAbsencesRhData(subordinate.employee_number)

}
clear(table: Table) {
  table.clear();
}
getProfilesRhData()
  {
    this.profilesRhService.getProfilesRhData().subscribe({
      next: data => {
        this.subordinates=data;
        this.isLoading=false
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los perfiles' });
      }
  });
  }
  getFillProfileData()
  {
    this.systemService.getProfileFill().subscribe({
      next: data => {
        for (let i = 0; i < data.area.length; i++) {
          this.areas.push(data.area[i].name);
        }
        for (let i = 0; i < data.branch.length; i++) {
          this.branches.push(data.branch[i].city);
        }
        for (let i = 0; i < data.enterprise.length; i++) {
          this.enterprises.push(data.enterprise[i].name);
        }
        this.bosses.push({name:'',employee_number:'-',drop_text:'-'})
        for (let i = 0; i < data.boss.length; i++) {
          this.bosses.push(data.boss[i]);
        }

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los datos de los perfiles' });
      }
  });
  }

  getProfileData(pk: string)
  {
    this.profilesRhService.getProfileDetailData(pk).subscribe({
      next: data => {
        this.username = data.employee_number;
        this.name = data.name;
        this.phonenumber= data.phonenumber;
        this.base64ProfileImage="";
        if (data.profile_image != ""){

          this.userImg='data:image/png;base64,' + data.profile_image;
          this.base64ProfileImage=this.userImg;
        }

        var dateParts = data.birthday.split('-');
        this.birthday=new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
        this.escolarity=data.escolarity;
        this.curp = data.CURP;
        this.rfc=data.RFC;
        this.position=data.position;
        this.branch=data.branch;
        this.area=data.area;
        this.enterprise=data.enterprise;
        this.secomail=data.secomail;
        this.boss=data.boss;
        const bossobj = this.bosses.find((obj) => obj.drop_text === this.boss);
        if (bossobj) {
            this.boss=bossobj
        } else {
          this.boss=this.bosses[0]
        }

        this.vac_days=data.vacations_days;
        this.imss=data.IMSS;
        this.placeholder_imss='Documento del IMSS'

        this.imss_type=data.IMSS_doc_type;
        this.imss_doc=data.IMSS_doc;
        this.start_date=data.start_date;
        this.start_date=data.start_date;
        this.userType=data.user_type;
        if (data.seniority==1)
        {
          this.seniority=data.seniority+' año';
        }
        else
        {
          this.seniority=data.seniority+' años';
        }
        this.period=data.period;
        this.end_date=data.end_date;
        this.rem_days=data.rem_days;
        this.status=data.status;
        this.isLoading = false;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el perfil' });
      }
  });
  }
  getAbsencesRhData(pk:string)
  {
    this.absencesRh.getAbsencesEmployeeData(pk).subscribe({
      next: data => {
        this.absences=data;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las ausencias.' });
      }
  });
  }

  resetConfirm()
  {

    this.confirmationService.confirm({
      message: '¿Deseas reiniciar la contraseña? Se perdera la contraseña actual',
      header: 'Reinicio de contraseña',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.resetPassword()
      },
      reject: () => {

      }
  });
  }

resetPassword()
{
  this.profilesRhService.resetPassword(this.username).subscribe({
    next: data => {
      this.newPasswordDialog = true;
      this.newPassword = data.password;

    },
    error: (error: Error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al reiniciar la contraseña.' });
    }
  });

}

  cleanDetailForm()
  {
    this.username = '';
        this.name = '';
        this.phonenumber='';

        this.userImg='assets/imgs/userImage.png';

        this.birthday = new Date();
        this.escolarity='';
        this.curp = '';
        this.rfc='';
        this.position='';
        this.branch='';
        this.area='';
        this.enterprise='';
        this.secomail='';
        this.boss='';
        this.vac_days='';
        this.imss='';
        this.placeholder_imss=''

        this.imss_type='';
        this.imss_doc='';
        this.start_date='';
        this.seniority='';
        this.end_date='';
        this.rem_days='';
        this.period='';
        this.status='';
  }

  newProfile(){
    if (!this.validateNewForm())
    {
      return
    }
    const newUser=this.new_enterprise+this.new_number
    const convStart=this.new_start_date.getFullYear()+"-"+(this.new_start_date.getMonth()+1)+"-"+this.new_start_date.getDate()
    const convBirth=this.new_birthday.getFullYear()+"-"+(this.new_birthday.getMonth()+1)+"-"+this.new_birthday.getDate()
    let myProfileObject = {}
      myProfileObject = {
        employee_number: newUser,
        name: this.new_name,
        birthday: convBirth,
        phonenumber: this.new_phonenumber,
        escolarity: this.new_escolarity,
        CURP: this.new_curp,
        RFC: this.new_rfc,
        position: this.new_position,
        branch: this.new_branch,
        area: this.new_area,
        enterprise: this.new_enterprise,
        secomail: this.new_secomail,
        IMSS: this.new_imss,
        profile_image: this.new_base64ProfileImage,
        IMSS_doc: this.new_base64Imss,
        start_date:convStart,
        boss: this.new_boss.employee_number,
        user_type: this.new_userType,
        vacation_days: this.new_vac_days,
        saturdays_taken: this.new_saturdays
      };

    this.profilesRhService.newProfileData(myProfileObject).subscribe({
      next: data => {
      this.newPasswordDialog = true;
      this.username=newUser
      this.newPassword = data.password;
      this.ngOnInit();
      this.visibleModal = false;
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });


  }

  modifyData()
  {
    if (!this.validateDetailForm())
    {
      return
    }
    const convbirthday=this.birthday.getFullYear()+"-"+(this.birthday.getMonth()+1)+"-"+this.birthday.getDate()

    let myProfileObject = {}
      myProfileObject = {
        name: this.name,
        phonenumber: this.phonenumber,
        birthday: convbirthday,
        escolarity: this.escolarity,
        CURP: this.curp,
        RFC: this.rfc,
        position: this.position,
        branch: this.branch,
        area: this.area,
        enterprise: this.enterprise,
        secomail: this.secomail,
        IMSS: this.imss,
        profile_image: this.base64ProfileImage,
        IMSS_doc: this.base64Imss,
        boss: this.boss.employee_number,
        status: this.status,
        user_type: this.userType
      };

    this.profilesRhService.modifyProfileData(this.username,myProfileObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Actualización exitosa' });
      this.ngOnInit();
      this.visibleDetail = false;
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });

  }




  dischargeConfirm()
  {

    this.confirmationService.confirm({
      message: '¿Deseas dar de baja al usuario? No podra acceder al sistema',
      header: 'Dar de baja',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.dischargeEmployee()
      },
      reject: () => {

      }
  });
  }
  dischargeEmployee()
  {
    let myProfileObject = {}
      myProfileObject = {
        name: this.name,
        phonenumber: this.phonenumber,
        escolarity: this.escolarity,
        CURP: this.curp,
        RFC: this.rfc,
        position: this.position,
        branch: this.branch,
        area: this.area,
        enterprise: this.enterprise,
        secomail: this.secomail,
        IMSS: this.imss,
        profile_image: this.base64ProfileImage,
        IMSS_doc: this.base64Imss,
        boss:this.boss.employee_number,
        status: 'Dado de baja',
        user_type: 'Dado de baja'

      };

    this.profilesRhService.modifyProfileData(this.username,myProfileObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Usuario dado de baja' });
      this.ngOnInit();




      this.visibleDetail = false;
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });

  }
addVacationDays()
{
  let myProfileObject = {}
  myProfileObject = {
    vacation_days: this.addDays,
    saturdays_taken: this.addSaturdays
  };
  this.profilesRhService.addVacationDays(this.username,myProfileObject).subscribe({
    next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Dias agregados' });
      this.ngOnInit();
      this.visibleDetail = false;
      this.addDaysDialog=false;
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  })


}
  showDialog() {
    this.cleanFillProfile()
    this.getFillProfileData()
    this.visibleModal = true;
}
showAddDays(){
  this.addDays=0;
  this.addSaturdays=0;
  this.addDaysDialog=true;
}
cleanNewForm()
{
  this.fileUpload.clear();
  this.fileUploadImage.clear();
  this.new_placeholder_imss ='';
  this.new_userImg='assets/imgs/userImage.png';
  this.new_mimeType= '';
  this.new_username='';
  this.new_name='';
  this.new_phonenumber='';
  this.new_birthday=new Date();
  this.new_escolarity='';
  this.new_curp='';
  this.new_rfc='';
  this.new_number='';
  this.new_position='';
  this.new_branch='';
  this.new_area='';
  this.new_enterprise='';
  this.new_secomail='';
  this.new_boss='';
  this.new_vac_days=0;
  this.new_saturdays=0;
  this.new_imss='';
  this.new_imss_doc='';
  this.new_imss_type='';
  this.new_userType='';
  this.new_start_date=new Date();
  this.new_seniority=0;
  this.new_base64ProfileImage='';
  this.new_base64Imss='';
  this.new_total_saturdays=0;
  this.new_total_vacations=0;

}

validateNewForm() {

  if (!this.new_enterprise || !this.new_start_date || !this.new_birthday || !this.new_userType) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false
  }
  if (this.new_vac_days>this.new_total_vacations)
  {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No tiene suficientes dias de vacaciones' });
    return false
  }
  if (this.new_saturdays>this.new_total_saturdays)
  {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No puede cubrir mas sabados de los necesarios.' });
    return false
  }

  return true

}

validateDetailForm() {

  if (!this.enterprise || !this.start_date || !this.birthday || !this.userType) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false
  }


  return true

}


cleanFillProfile(){
  this.branches=[];
  this.areas=[];
  this.enterprises=[];
  this.bosses=[];
}


  getColorsAbsence(status: string) {
    switch (status) {
        case 'Aceptada':
            return 'accepted';
        case 'Aceptado':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        case 'Rechazado':
            return 'denied';
        case 'Terminada':
            return 'finished';
        case 'Terminado':
            return 'finished'
        case 'En espera':
            return 'waiting';
        case 'Cancelada':
            return 'canceled';
        case 'No cumplio':
          return 'canceled'
        case 'En revisión':
          return 'waiting';
        case 'Entregada':
          return 'finished';
        default:
          return 'inProcess';
    }
}

onUploadImage(event: { files: any[]; }) {

  const file = event.files[0];
  if (file.size > 8000000) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
    this.fileUploadImage.clear();
    return false;
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
      this.base64ProfileImage = reader.result as string;
      this.userImg=this.base64ProfileImage.split(',')[1];
      this.userImg = 'data:image/png;base64,' + this.userImg;
  };
  return true
}
onUploadNewImage(event: { files: any[]; }) {

  const file = event.files[0];
  if (file.size > 8000000) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
    this.fileUploadImage.clear();
    return false;
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
      this.new_base64ProfileImage = reader.result as string;
      this.new_userImg=this.new_base64ProfileImage.split(',')[1];
      this.new_userImg = 'data:image/png;base64,' + this.new_userImg;
  };
  return true
}
onUploadImss(event: { files: any[]; }) {

  const file = event.files[0];
  if (file.size > 8000000) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
    this.fileUpload.clear();
    return false;
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
      this.base64Imss = reader.result as string;
  };
  return true
}

onUploadNewImss(event: { files: any[]; }) {

  const file = event.files[0];
  if (file.size > 8000000) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
    this.fileUpload.clear();
    return false;
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
      this.new_base64Imss = reader.result as string;
  };
  return true
}

  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(this.base64ToBlob(this.imss_doc));
    element.download = 'Documento del IMSS de ' + this.username;
    element.click();

  }



  base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime')
    const mimeType = mimeTypes.getType(this.imss_type);
    if (mimeType!=null){
      this.mimeType = mimeType;
    }


    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }

  calculateSeniority()
  {
    this.new_seniority=new Date().getFullYear()-this.new_start_date.getFullYear()
    const today=new Date()
    if (this.new_start_date.getMonth()>today.getMonth() && this.new_start_date.getFullYear()!=today.getFullYear())
    {
      this.new_seniority=this.new_seniority-1
    }
    if (this.new_start_date.getMonth()==today.getMonth() && this.new_start_date.getFullYear()!=today.getFullYear() && this.new_start_date.getDate()>today.getDate())
    {
      this.new_seniority=this.new_seniority-1
    }
    switch(this.new_seniority)
    {
      case 0:{
        this.new_total_vacations=0
        break;}
      case 1:{
        this.new_total_vacations=12;
        break;}
      case 2:{
        this.new_total_vacations=14;
        break;}
      case 3:{
        this.new_total_vacations=16;
        break;}
      case 4:{
        this.new_total_vacations=18;
        break;}
      case 5:{
        this.new_total_vacations=20;
        break;}
      default:{
        this.new_total_vacations=-1;
        break;
      }
    }
    if (this.new_total_vacations==-1 && this.new_seniority<11)
    {
      this.new_total_vacations=22;
    }
    if (this.new_total_vacations==-1 && this.new_seniority<16)
    {
      this.new_total_vacations=24;
    }
    if (this.new_total_vacations==-1 && this.new_seniority<21)
    {
      this.new_total_vacations=26;
    }
    if (this.new_total_vacations==-1 && this.new_seniority<26)
    {
      this.new_total_vacations=28;
    }
    if (this.new_total_vacations==-1)
      this.new_total_vacations=30
    this.new_total_saturdays=Math.floor(this.new_total_vacations/6)



  }

}
