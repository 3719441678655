<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">
    <div class="col-10" style="margin:auto;">
        <h2 class="titulo">Mis permisos de ausencia.</h2>
        <div class="grid">

        <div class="col-7">
            <h3>Solicitud de permisos</h3>
            </div>
            <div class="col-4" style="align-items: center;display: flex;">
                <button  (click)="showDialog()" type="submit"  class="btn" style="margin-top: 2%;">Solicitar</button>
            </div>
    </div>
    <p-table [value]="leaves" [tableStyle]="{'min-width': '45rem'}"  selectionMode="single" #dt1
    [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
    [globalFilterFields]="['start_date', 'end_date', 'motive','status']">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-6">
          <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        </div>
        <div class="col-6" style="align-items:end;display: flex;">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="filterText" (input)="dt1.filterGlobal(filterText, 'contains')" placeholder="Buscar" />
            </span>
          </div>
        </div>
      </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th >ID</th>
                    <th pSortableColumn="start_date">Fecha de inicio<p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin<p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="motive">Motivo<p-sortIcon field="motive"></p-sortIcon></th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-leave>
                <tr (click)="showDetail(leave)">
                    <td>{{ leave.id }}</td>
                    <td>{{ leave.start_date }}</td>
                    <td>{{ leave.end_date }}</td>
                    <td>{{ leave.motive }}</td>
                    <td style="width: 20%;"><tag  [ngClass]="getColors(leave.status)">{{ leave.status }} </tag></td>
                </tr>
            </ng-template>
        </p-table>
        <h4>*Si deseas consultar, cambiar o cancelar una solicitud solo seleccionala</h4>

</div>
</div>
</ng-container>
<p-dialog header="Solicitud de permiso" [(visible)]="visibleModal" [modal]="true" [draggable]="false" (onHide)="cleanForm()" >
  <div class="container" style="min-width: 30vw;">
    <div class="content-input"  >
      <label htmlFor="selected-motive">Motivo</label>
      <p-dropdown [options]="motives" appendTo="body" name="selected-motive" [(ngModel)]="selectedMotive" placeholder="Seleccionar motivo"></p-dropdown>
    </div>
  </div>

  <div *ngIf="selectedMotive=='Otro'">
    <span>*Estos permisos son sin gose de sueldo</span>
  </div>
  <div *ngIf="selectedMotive=='Paternidad'">
    <span>*Estos permisos duran 5 dias laborales</span>
  </div>

  <div *ngIf="selectedMotive!=''">
    <div class="container">
      <div class="content-input">
        <label htmlFor="selected-start">Fecha</label>
        <p-calendar [(ngModel)]="selectedStart" name="selected-start" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"></p-calendar>
      </div>
    </div>
  </div>

  <div *ngIf="selectedMotive=='Fallecimiento de familiar directo' || selectedMotive=='Intervención quirúrgica a familiar directo' || selectedMotive=='Privación de la libertad de familiar directo'">
    <div class="container">
      <div class="content-input">
        <label htmlFor="selected-relative">Familiar</label>
        <p-dropdown [options]="relatives" [(ngModel)]="selectedRelative" name="selected-relative" placeholder="Seleccionar familiar"></p-dropdown>
      </div>
    </div>
    <div class="container">
      <div class="content-input">
        <label htmlFor="selected-proof">Comprobante</label>
        <p-fileUpload mode="basic" chooseLabel="Agregar" name="proof[]" (onSelect)="onUpload($event)" #fileClean (showCancelButton)="true"></p-fileUpload>
        <span>*El tamaño maximo es de 8MB</span>
      </div>
    </div>
  </div>

  <div *ngIf="selectedMotive=='Siniestros ocurridos en el hogar' || selectedMotive=='Matrimonio del trabajador' || selectedMotive=='Examen profesional del trabajador o hijos' || selectedMotive=='Paternidad'">
    <div class="container">
      <div class="content-input">
        <label htmlFor="selected-proof">Comprobante</label>
        <p-fileUpload mode="basic" chooseLabel="Agregar" name="proof[]" (onSelect)="onUpload($event)" #fileClean></p-fileUpload>

        <span>*El tamaño maximo es de 8MB</span>
      </div>
    </div>
  </div>

  <div *ngIf="selectedMotive=='Otro'">
    <div class="container">
      <div class="content-input">
        <label htmlFor="selected-other-type">Tipo de permiso</label>
        <p-dropdown [options]="otherTypes" [(ngModel)]="selectedOtherType" (onChange)="onDropdownTypeChange($event)" name="selected-other-type" placeholder="Seleccionar tipo"></p-dropdown>
      </div>
    </div>

    <div *ngIf="selectedOtherType=='Horas'">
      <div class="container">
        <div class="content-input">
          <label htmlFor="selected-time">Horario de ausencia</label>
          <input type="text" pInputText [(ngModel)]="selectedTime" name="selected-time" placeholder="Horario de ausencia">
          <span>*El formato de hora utilizado es de 24 horas. Ejemplo: 12:00-16:00</span>
        </div>
      </div>

      <div *ngIf="recoveryDecision==true">
        <div class="container">
          <div class="content-input">
            <label htmlFor="selected-recovery-day">Fecha(s) de recuperación</label>
            <p-calendar [(ngModel)]="selectedRecoveryDay" name="selected-recovery-day" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" selectionMode="multiple"></p-calendar>

          </div>
        </div>

        <div class="container">
          <div class="content-input">
            <label htmlFor="selected-recovery-time">Horario(s) de recuperación</label>
            <input type="text" pInputText [(ngModel)]="selectedRecoveryTime" name="selected-recovery-time" placeholder="Horarios de recuperación">
            <span>*En caso de ser necesario escribe los periodos de hora separados por coma: 8:00-10:00,12:00-14:00 </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="content-input">
      <label htmlFor="selected-comment">Comentarios</label>
      <textarea type="text" pInputText [(ngModel)]="selectedComments" name="selected-comment" placeholder="Comentarios"></textarea>
    </div>
  </div>

  <div style="display: inline-block;width: 100%;">
    <button (click)="postLeaveData()" type="submit" class="btn" style="margin-top: 2%;">Finalizar solicitud</button>
  </div>


</p-dialog>

<p-confirmDialog #cd>
  <ng-template pTemplate="header">
      <h3>Aviso del permiso</h3>
  </ng-template>
  <ng-template pTemplate="content">
</ng-template>
  <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="Rechazar" (click)="cd.reject()"></button>
      <button type="button" pButton icon="pi pi-check" label="Aceptar" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>




<p-dialog [header]="'Informacion del permiso '+detailId" [(visible)]="visibleDetail" [modal]="true" [draggable]="false" (onHide)="cleanDetailForm()" >
  <div class="container">
    <div class="content-input">
      <label for="detail-motive">Motivo</label>
      <p-dropdown [options]="motives" appendTo="body" name="detail-motive" [(ngModel)]="detailMotive" placeholder="Seleccionar motivo" [disabled]="true"></p-dropdown>
    </div>
</div>
<div *ngIf="detailMotive=='Otro' && detailRecoveryDay==[]">
  <span>*Este permiso es sin gose de sueldo</span>
</div>
<div *ngIf="detailMotive!=''">
  <div class="container">
    <div class="content-input">
      <label for="detail-start">Fecha de inicio</label>
      <p-calendar [(ngModel)]="detailStart" name="detail-start" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="isBlocked"></p-calendar>
    </div>
  </div>
</div>
<div *ngIf="detailMotive!=''">
  <div class="container">
    <div class="content-input">
      <label for="detail-end">Fecha de fin</label>
      <p-calendar [(ngModel)]="detailEnd" name="detail-end" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="true"></p-calendar>
    </div>
  </div>
</div>
<div class="container" *ngIf="detailRelative!=''">
  <div class="content-input">
    <label for="detail-relative">Familiar</label>
    <p-dropdown [options]="relatives" [(ngModel)]="detailRelative" name="detail-relative" [disabled]="isBlocked"></p-dropdown>
  </div>
</div>
<div class="container" *ngIf="base64DetailBeforeProof!=''">
  <div class="content-input">
    <div class="grid">
      <div class="col-5 md:col-5 lg:col-4" style="bottom: 0;">
        <label for="detail-proof">Actual</label>
        <button class="btn"  (click)="downloadFile()"><i class="pi pi-download"></i></button>
      </div>
      <div class="col-7 md:col-7 lg:col-8">
    <label for="detail-proof">Cambiar comprobante</label>
    <p-fileUpload mode="basic" chooseLabel="Agregar" name="proof[]" (onSelect)="onUploadDetail($event)" #fileClean></p-fileUpload>
    <span>*El tamaño maximo es de 8MB</span>
  </div>
  </div></div>

</div>
<div class="container" *ngIf="base64DetailBeforeProof=='' && detailMotive!='Otro'">
  <div class="content-input">
    <label for="detail-proof">Comprobante</label>
    <p-fileUpload mode="basic" chooseLabel="Agregar" name="proof[]" (onSelect)="onUploadDetail($event)" #fileClean></p-fileUpload>
    <span>*El tamaño maximo es de 8MB</span>
  </div>
</div>
<div class="container" *ngIf="detailOtherType!=''">
  <div class="content-input">
    <label for="detail-other-type">Tipo de permiso</label>
    <p-dropdown [options]="otherTypes" [(ngModel)]="detailOtherType" (onChange)="onDropdownDetailChange($event)" name="detail-other-type" placeholder="Seleccionar tipo" [disabled]="isAccepted"></p-dropdown>
  </div>
</div>
<div class="container" *ngIf="detailOtherType=='Horas'">
  <div class="content-input">
    <label for="detail-time">Horario de ausencia</label>
    <input type="text" pInputText [(ngModel)]="detailTime" name="detail-time" placeholder="Horario de ausencia" [disabled]="isAccepted">
    <span>*El formato de hora utilizado es de 24 horas. Ejemplo: 12:00-16:00</span>
  </div>
</div>
<div class="container" *ngIf="detailRecoveryDay!=[] && recoveryDetailDecision==true">
  <div class="content-input">
    <label for="selected-recovery-day">Fecha(s) de recuperación</label>
    <p-calendar [(ngModel)]="detailRecoveryDay" name="selected-recovery-day" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" selectionMode="multiple" [disabled]="isAccepted"> </p-calendar>
  </div>
</div>
<div class="container" *ngIf="detailRecoveryDay!=[] && recoveryDetailDecision==true">
  <div class="content-input">
    <label for="detail-recovery-time">Horario(s) de recuperación</label>
    <input type="text" pInputText [(ngModel)]="detailRecoveryTime" name="detail-recovery-time" placeholder="Horarios de recuperación" [disabled]="isAccepted">
    <span>*En caso de ser necesario escribe los periodos de hora separados por coma: 8:00-10:00,12:00-14:00 </span>
  </div>
</div>
<div class="container">
  <div class="content-input">
    <label for="detail-status">Status</label>
    <input type="text" pInputText [(ngModel)]="detailStatus" name="detail-status" placeholder="Status de la solicitud" [ngClass]="getColors(detailStatus)" [disabled]="true">
  </div>
</div>
<div class="container">
  <div class="content-input">
    <label for="detail-comments">Comentarios</label>
    <textarea type="text" pInputText [(ngModel)]="detailComments" name="detail-comments" placeholder="Comentarios"></textarea>
  </div>
</div>
<div class="container" *ngIf="detailBossComments!=''">
  <div class="content-input">
    <label for="detail-boss-comments">Comentarios del jefe</label>
    <textarea type="text" pInputText [(ngModel)]="detailBossComments" name="detail-boss-comments" placeholder="Comentarios del jefe" [disabled]="true"></textarea>
  </div>
</div>
<div style="display: inline-block;width: 100%;">
  <button type="submit" class="btn"  style="margin-top: 2%;" (click)="putLeaveData()" *ngIf="detailStatus=='En espera'" >Solicitar cambios</button>
</div>
<div style="display: inline-block;width: 100%;">
  <button type="submit" class="btn"  style="margin-top: 2%;background-color: #C2262E;" (click)="deleteLeaveData()" *ngIf="detailStatus=='En espera' || detailStatus=='Aceptado'">Solicitar cancelacion</button>
</div>

</p-dialog>

<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
