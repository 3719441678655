<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">

    <div class="col-10" style="margin:auto;">
        <h2 class="titulo">Incapacidades en el sistema.</h2>
          <div class="grid">
            <div class="col-4 md:col-2 lg:col-2" *ngFor="let year of years" >
          <button class="btn" (click)="getIncapacitiesRhData(year)">{{ year }}</button>
        </div>
        </div>

        <p-table [value]="incapacities" [tableStyle]="{'min-width': '50rem'}" selectionMode="single" #dt1
        [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['employee','boss','start_date', 'end_date', 'motive','status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filterText" (input)="dt1.filterGlobal(filterText, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
                <tr>
                    <th>ID</th>
                    <th pSortableColumn="employee">Empleado<p-sortIcon field="employee"></p-sortIcon></th>
                    <th pSortableColumn="boss">Jefe<p-sortIcon field="boss"></p-sortIcon></th>
                    <th pSortableColumn="start_date">Fecha de inicio<p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin<p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="motive">Motivo<p-sortIcon field="motive"></p-sortIcon></th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-incapacity>
                <tr (click)="showDetail(incapacity)">
                    <td>{{ incapacity.id }}</td>
                    <td>{{ incapacity.employee }}</td>
                    <td>{{ incapacity.boss }}</td>
                    <td>{{ incapacity.start_date }}</td>
                    <td>{{ incapacity.end_date }}</td>
                    <td>{{ incapacity.motive }}</td>
                    <td style="white-space: nowrap;"><tag  [ngClass]="getColors(incapacity.status)">{{ incapacity.status }} </tag></td>
                </tr>
            </ng-template>
        </p-table>
        <h4>*Si deseas consultar una solicitud solo seleccionala.</h4>
        <div class="grid">
          <div class="col-8">
            <h3 >Generar reportes:</h3>
        </div>
        <div class="col-4" style="align-items: center;display: flex;">
            <button  (click)="showReportDialog()" class="btn" type="submit" >Imprimir</button>

        </div>

          </div>

</div>
</div>


<p-dialog [header]="'Solicitud de incapacidad ' + detailId+ ' de ' + detailEmployee" [(visible)]="visibleDetail" [modal]="true" [draggable]="false">
  <div class="dialog-content">
    <div class="content-input">
      <label htmlFor="detail-start">Fecha de inicio</label>
      <p-calendar [(ngModel)]="detailStart" name="detail-start" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"[disabled]="true"></p-calendar>
    </div>
    <div class="content-input">
      <label htmlFor="detail-end">Fecha de fin</label>
      <p-calendar [(ngModel)]="detailEnd" name="detail-end" dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"[disabled]="true"></p-calendar>
    </div>
    <div class="content-input">
      <label htmlFor="detail-duration">Dias de incapacidad</label>
      <input type="text" pInputText [(ngModel)]="detailDuration" name="detail-duration" placeholder="Dias de incapacidad(Numero)"[disabled]="true">
    </div>
    <div class="content-input">
      <label htmlFor="detail-motive">Motivo</label>
      <input type="text" pInputText [(ngModel)]="detailMotive" name="detail-motive" placeholder="Motivo de incapacidad"[disabled]="true">
    </div>
    <div class="content-input">
      <label for="detail-proof">Documento del IMSS</label>
        <button class="btn"  (click)="downloadFile()"><i class="pi pi-download"></i></button>
    </div>
    <div class="content-input">
      <label htmlFor="detail-status">Status</label>
      <input type="text" pInputText [(ngModel)]="detailStatus" name="detail-status" placeholder="Status de incapacidad" [ngClass]="getColors(detailStatus)" [disabled]="true">
    </div>
    <div class="content-input">
      <label htmlFor="detail-comments">Comentarios</label>
      <textarea type="text" pInputText [(ngModel)]="detailComments" name="detail-comments" placeholder="Comentarios"[disabled]="true"></textarea>
    </div>
    <div class="content-input">
      <label htmlFor="detail-boss-comments">Comentarios de RH</label>
      <textarea type="text" pInputText [(ngModel)]="detailBossComments" name="detail-boss-comments" placeholder="Comentarios de RH" [disabled]="detailStatus=='Entregada'"></textarea>
    </div>
    <div style="display: inline-block;width: 100%;" *ngIf="detailStatus=='En revisión'">
      <button (click)="confirmDeliver()" type="submit" class="btn" style="margin-top: 2%;">Recibir solicitud</button>
    </div>

  </div>
</p-dialog>

<p-confirmDialog #cd [position]="'top'">

  <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="Rechazar" (click)="cd.reject()"></button>
      <button type="button" pButton icon="pi pi-check" label="Aceptar" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>


<p-dialog header="" [(visible)]="reportDialog" [modal]="true" [draggable]="false" (onHide)="cleanReportForm()">
  <ng-template pTemplate="header">
  <div>
    <span class="text-xl font-bold">Impresion de reportes de incapacidades:  <i class="pi pi-file"></i></span>
  </div>
</ng-template>
<div>
  <div class="container">
    <div class="content-input">
      <label htmlFor="detail-start">Fecha de inicio del reporte</label>
        <p-calendar [(ngModel)]="reportStartDate" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true"  appendTo="body" class="select-dates"></p-calendar>
    </div>
  </div>
  <div class="container">
    <div class="content-input">
      <label htmlFor="detail-start">Fecha de fin del reporte</label>
        <p-calendar [(ngModel)]="reportEndDate" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true"  appendTo="body" class="select-dates"></p-calendar>
    </div>
  </div>
  <div class="container" style="min-width: 30vw;">
    <div class="content-input">
      <label htmlFor="detail-addDays">Status:</label><br>
      <p-multiSelect [options]="status_options" appendTo="body" [(ngModel)]="reportStatus" placeholder="Escoge los status que quieres imprimir"></p-multiSelect></div></div>
  <div class="container" style="min-width: 30vw;">
    <div class="content-input">
      <label htmlFor="detail-addDays">Nombre del empleado:</label><br>
      <input type="text" pInputText [(ngModel)]="reportName" name="detail-addDays" placeholder="Escoge el nombre de algun empleado o dejalo vacio" style="font-weight: normal; width:100%">
    </div></div>
    <div class="container" style="min-width: 30vw;">
      <div class="content-input">
        <label htmlFor="detail-addDays">Motivo:</label><br>
        <input type="text" pInputText [(ngModel)]="reportMotive" name="detail-addDays" placeholder="Escoge algun motivo o dejalo vacio" style="font-weight: normal; width:100%">
      </div></div>
    <button  type="submit"  (click)="getReport()" class="btn" style="margin-top: 2%;">Generar.</button>
</div>
</p-dialog>


</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
