<p-sidebar [(visible)]="sidebarVisible" *ngIf="user_type=='Subordinado'">

  <ng-template pTemplate="header">

    <div style="display: flex; align-items: center;">
      <a href="/home">
      <div class="card flex justify-content-center">
        <img [src]="logoUrl" alt="Logo de la empresa"  class="imagen-logo" style="align-items: center;width: 50%;">
      </div>
    </a>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <a href="/myProfile" class="selected-buttons" *ngIf="currentUrl==='/myProfile'">
      <div style="display: flex; align-items: center;background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>
    <a href="/myProfile" class="buttons" *ngIf="currentUrl!=='/myProfile'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>

    <a href="/myVacations" class="selected-buttons" *ngIf="currentUrl==='/myVacations'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myVacations" class="buttons" *ngIf="currentUrl!=='/myVacations'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="selected-buttons" *ngIf="currentUrl==='/myLeaves'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis permisos</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="buttons" *ngIf="currentUrl!=='/myLeaves'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis permisos.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="selected-buttons" *ngIf="currentUrl==='/myIncapacities'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="buttons" *ngIf="currentUrl!=='/myIncapacities'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/notifications" class="selected-buttons" *ngIf="currentUrl==='/notifications'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Notificaciones.</p>
        </div>
      </div>
    </a>
    <a href="/notifications" class="buttons" *ngIf="currentUrl!=='/notifications'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell" [ngClass]="getStatus()"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" [ngClass]="getStatus()">Notificaciones.</p>
        </div>
      </div>
    </a>


  </ng-template>


  <ng-template pTemplate="footer">
<a style="cursor: pointer;">
  <div style="display: flex; align-items: center;" (click)="logout()">
  <div style="width: 10%; margin-left: 5%">
    <i class="pi pi-sign-out"></i>
  </div>
  <div style="width: 80%; margin: 0px">
    <p  class="text-lg">Cerrar sesión.</p>
  </div>
</div>
</a>
  </ng-template>
</p-sidebar>

<p-sidebar [(visible)]="sidebarVisible" *ngIf="user_type=='Jefe'">

  <ng-template pTemplate="header">

    <div style="display: flex; align-items: center;">
      <a href="/home">
      <div class="card flex justify-content-center">
        <img [src]="logoUrl" alt="Logo de la empresa"  class="imagen-logo" style="align-items: center;width: 50%;">
      </div>
    </a>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <a href="/myProfile" class="selected-buttons" *ngIf="currentUrl==='/myProfile'">
      <div style="display: flex; align-items: center;background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>
    <a href="/myProfile" class="buttons" *ngIf="currentUrl!=='/myProfile'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>
    <a href="/boss/vacations" class="selected-buttons" *ngIf="currentUrl==='/boss/vacations'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-check-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Aprobar vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/boss/vacations" class="buttons" *ngIf="currentUrl!=='/boss/vacations'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-check-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Aprobar vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/boss/leaves" class="selected-buttons" *ngIf="currentUrl==='/boss/leaves'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar-plus"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Aprobar permisos.</p>
        </div>
      </div>
    </a>
    <a href="/boss/leaves" class="buttons" *ngIf="currentUrl!=='/boss/leaves'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar-plus"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Aprobar permisos.</p>
        </div>
      </div>
    </a>
    <a href="/boss/employees" class="selected-buttons" *ngIf="currentUrl==='/boss/employees'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-users"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Consultar colaboradores.</p>
        </div>
      </div>
    </a>
    <a href="/boss/employees" class="buttons" *ngIf="currentUrl!=='/boss/employees'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-users"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Consultar colaboradores.</p>
        </div>
      </div>
    </a>
    <a href="/boss/absences" class="selected-buttons" *ngIf="currentUrl==='/boss/absences'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-chart-pie"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Consultar ausencias.</p>
        </div>
      </div>
    </a>
    <a href="/boss/absences" class="buttons" *ngIf="currentUrl!=='/boss/absences'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-chart-pie"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Consultar ausencias.</p>
        </div>
      </div>
    </a>



    <a href="/myVacations" class="selected-buttons" *ngIf="currentUrl==='/myVacations'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myVacations" class="buttons" *ngIf="currentUrl!=='/myVacations'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="selected-buttons" *ngIf="currentUrl==='/myLeaves'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis permisos</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="buttons" *ngIf="currentUrl!=='/myLeaves'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis permisos.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="selected-buttons" *ngIf="currentUrl==='/myIncapacities'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="buttons" *ngIf="currentUrl!=='/myIncapacities'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>

    <a href="/notifications" class="selected-buttons" *ngIf="currentUrl==='/notifications'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Notificaciones.</p>
        </div>
      </div>
    </a>
    <a href="/notifications" class="buttons" *ngIf="currentUrl!=='/notifications'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell" [ngClass]="getStatus()"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" [ngClass]="getStatus()">Notificaciones.</p>
        </div>
      </div>
    </a>


  </ng-template>


  <ng-template pTemplate="footer">
<a style="cursor: pointer;">
  <div style="display: flex; align-items: center;" (click)="logout()">
  <div style="width: 10%; margin-left: 5%">
    <i class="pi pi-sign-out"></i>
  </div>
  <div style="width: 80%; margin: 0px">
    <p  class="text-lg">Cerrar sesión.</p>
  </div>
</div>
</a>
  </ng-template>
</p-sidebar>


<p-sidebar [(visible)]="sidebarVisible" *ngIf="user_type=='Recursos Humanos' || user_type=='Jefe de Recursos Humanos'">

  <ng-template pTemplate="header">

    <div style="display: flex; align-items: center;">
      <a href="/home">
      <div class="card flex justify-content-center">
        <img [src]="logoUrl" alt="Logo de la empresa"  class="imagen-logo" style="align-items: center;width: 50%;">
      </div>
    </a>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <a href="/myProfile" class="selected-buttons" *ngIf="currentUrl==='/myProfile'">
      <div style="display: flex; align-items: center;background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>
    <a href="/myProfile" class="buttons" *ngIf="currentUrl!=='/myProfile'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-user"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mi perfil.</p>
        </div>
      </div>
    </a>
    <a href="/rh/vacations" class="selected-buttons" *ngIf="currentUrl==='/rh/vacations'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-check-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Supervisar vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/rh/vacations" class="buttons" *ngIf="currentUrl!=='/rh/vacations'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-check-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Supervisar vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/rh/leaves" class="selected-buttons" *ngIf="currentUrl==='/rh/leaves'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar-plus"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Supervisar permisos.</p>
        </div>
      </div>
    </a>
    <a href="/rh/leaves" class="buttons" *ngIf="currentUrl!=='/rh/leaves'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar-plus"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Supervisar permisos.</p>
        </div>
      </div>
    </a>
    <a href="/rh/incapacities" class="selected-buttons" *ngIf="currentUrl==='/rh/incapacities'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-home"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Recibir incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/rh/incapacities" class="buttons" *ngIf="currentUrl!=='/rh/incapacities'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-home"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Recibir incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/rh/employees" class="selected-buttons" *ngIf="currentUrl==='/rh/employees'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-users"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Mi equipo.</p>
        </div>
      </div>
    </a>
    <a href="/rh/employees" class="buttons" *ngIf="currentUrl!=='/rh/employees'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-users"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mi equipo.</p>
        </div>
      </div>
    </a>
    <a href="/rh/absences" class="selected-buttons" *ngIf="currentUrl==='/rh/absences'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-chart-pie"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Consultar ausencias.</p>
        </div>
      </div>
    </a>
    <a href="/rh/absences" class="buttons" *ngIf="currentUrl!=='/rh/absences'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-chart-pie"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Consultar ausencias.</p>
        </div>
      </div>
    </a>

    <a href="/rh/systemManagement" class="selected-buttons" *ngIf="currentUrl==='/rh/systemManagement' && user_type=='Jefe de Recursos Humanos'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-exclamation-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Manejo del sistema.</p>
        </div>
      </div>
    </a>
    <a href="/rh/systemManagement" class="buttons" *ngIf="currentUrl!=='/rh/systemManagement' && user_type=='Jefe de Recursos Humanos'" >
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-exclamation-circle"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Manejo del sistema.</p>
        </div>
      </div>
    </a>






    <a href="/myVacations" class="selected-buttons" *ngIf="currentUrl==='/myVacations'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myVacations" class="buttons" *ngIf="currentUrl!=='/myVacations'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-sun"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis vacaciones.</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="selected-buttons" *ngIf="currentUrl==='/myLeaves'">
      <div style="display: flex; align-items: center;  background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%;">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px;">
          <p  class="text-lg">Mis permisos</p>
        </div>
      </div>
    </a>
    <a href="/myLeaves" class="buttons" *ngIf="currentUrl!=='/myLeaves'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-calendar"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis permisos.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="selected-buttons" *ngIf="currentUrl==='/myIncapacities'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>
    <a href="/myIncapacities" class="buttons" *ngIf="currentUrl!=='/myIncapacities'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-heart"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg">Mis incapacidades.</p>
        </div>
      </div>
    </a>

    <a href="/notifications" class="selected-buttons" *ngIf="currentUrl==='/notifications'">
      <div style="display: flex; align-items: center; background-color: #009F4D;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" >Notificaciones.</p>
        </div>
      </div>
    </a>
    <a href="/notifications" class="buttons" *ngIf="currentUrl!=='/notifications'">
      <div style="display: flex; align-items: center;">
        <div style="width: 10%; margin-left: 5%">
          <i class="pi pi-bell" [ngClass]="getStatus()"></i>
        </div>
        <div style="width: 80%; margin: 0px">
          <p  class="text-lg" [ngClass]="getStatus()">Notificaciones.</p>
        </div>
      </div>
    </a>


  </ng-template>


  <ng-template pTemplate="footer">
<a style="cursor: pointer;">
  <div style="display: flex; align-items: center;" (click)="logout()">
  <div style="width: 10%; margin-left: 5%">
    <i class="pi pi-sign-out"></i>
  </div>
  <div style="width: 80%; margin: 0px">
    <p  class="text-lg">Cerrar sesión.</p>
  </div>
</div>
</a>
  </ng-template>
</p-sidebar>






<div class="top-bar">
  <button pButton (click)="showSidebar()" icon="pi pi-list" [ngClass]="getStatus()"></button>
</div>
<div class="top-block">
</div>

