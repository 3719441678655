<app-home></app-home>



<ng-container *ngIf="!isLoading">
<div class="grid">

  <div class="col-10 md:col-10 lg:col-10" style="margin:auto;margin-top: 1%; min-width: 30rem;">
    <h2 class="titulo">Mis notificaciones.</h2>
    <p-tabMenu [scrollable]="true" [model]="items" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
    <div *ngIf="activeItem.label === 'Todas'" >
      <!-- contenido de la pestaña 1 -->
      <div  *ngFor="let notification of notifications" [ngClass]="getStatus(notification.status)">
        <div style="width: 10%; margin-left: 5%">
          <i [ngClass]="getIconClass(notification.type)"></i>
        </div>
        <div style="width: 60%; margin: 0px">
          <p  class="text-lg">{{notification.text}}</p>
        </div>
        <div style="width: 25%; margin: 0px">
          <p  class="text-lg">{{notification.date}}</p>
        </div>
      </div>

    </div>

    <div *ngIf="activeItem.label === 'Vacaciones'">
      <!-- contenido de la pestaña 2 -->

      <div   *ngFor="let notification of filteredVacations" [ngClass]="getStatus(notification.status)">
        <div style="width: 10%; margin-left: 5%">
          <i [ngClass]="getIconClass(notification.type)"></i>
        </div>
        <div style="width: 70%; margin: 0px">
          <p  class="text-lg">{{notification.text}}</p>
        </div>
        <div style="width: 10%; margin: 0px">
          <p  class="text-lg">{{notification.date}}</p>
        </div>
      </div>
    </div>


    <div *ngIf="activeItem.label === 'Permisos'">
      <!-- contenido de la pestaña 3 -->
      <div *ngFor="let notification of filteredLeaves" [ngClass]="getStatus(notification.status)">
        <div style="width: 10%; margin-left: 5%">
          <i [ngClass]="getIconClass(notification.type)"></i>
        </div>
        <div style="width: 70%; margin: 0px">
          <p  class="text-lg">{{notification.text}}</p>
        </div>
        <div style="width: 10%; margin: 0px">
          <p  class="text-lg">{{notification.date}}</p>
        </div>
      </div>


</div>
    <div *ngIf="activeItem.label === 'Incapacidades'">
      <!-- contenido de la pestaña 3 -->
      <div *ngFor="let notification of filteredIncapacities" [ngClass]="getStatus(notification.status)">
        <div style="width: 10%; margin-left: 5%">
          <i [ngClass]="getIconClass(notification.type)"></i>
        </div>
        <div style="width: 70%; margin: 0px">
          <p  class="text-lg">{{notification.text}}</p>
        </div>
        <div style="width: 10%; margin: 0px">
          <p  class="text-lg">{{notification.date}}</p>
        </div>
      </div>


    </div>

</div>
</div>
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
