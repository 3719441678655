import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RhProfileOptionsService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getProfileFill(): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/fillProfile/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
}
