import { Component } from '@angular/core';
import {  ConfirmationService, MessageService } from 'primeng/api';
import { BossLeavesService } from '../services/Leave/boss-leaves.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';
import { RhLeavesService } from '../services/Leave/rh-leaves.service';

@Component({
  selector: 'app-rh-leaves',
  templateUrl: './rh-leaves.component.html',
  styleUrls: ['./rh-leaves.component.css'],
  providers: [MessageService]
})
export class RhLeavesComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;
  isAccepted: boolean=false;

  filterText:string ="";
  motives: string[]=[];
  otherTypes: string[]=[];
  relatives: string[]=[];
  visibleModal: boolean=false;
  leaves: any[]=[];
  mimeType: string = '';

  visibleDetail: boolean = false;
  detailStart: Date =new Date();
  detailEnd: Date | undefined;
  detailRelative:string ='';
  detailProof:File=new File([],'');
  base64DetailProof: string='';
  base64DetailBeforeProof: string='';
  detailProofType:string =''
  detailOtherType:string ='';
  detailTime:string ='';
  detailRecoveryTime:string ='';
  detailRecoveryDay:Date[]=[];
  detailComments:string ='';
  detailBoss:string ='';
  detailBossComments:string ='';
  detailDecision: boolean | undefined;
  detailMotive: string ='';
  detailStatus:string="";
  recoveryDetailDecision: boolean=false;
  detailId:number=0;
  detailEmployee: string='';


  years:number[]=[]
  year:number=new Date().getFullYear();

  reportDialog:boolean=false;
  reportStartDate:Date=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  reportEndDate:Date=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  status_options:any[]=["En espera","Aceptado","Rechazado","En proceso","Terminado","No cumplio"];
  reportStatus:string[]=[];
  reportName:string="";
  reportMotive:string[]=[];
  reportPaid:string[]=[]
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private rhLeavesService: RhLeavesService
    ) {
      this.motives = ['Fallecimiento de familiar directo','Intervención quirúrgica a familiar directo','Privación de la libertad de familiar directo','Siniestros ocurridos en el hogar',
      'Matrimonio del trabajador','Examen profesional del trabajador o hijos','Paternidad',
      'Otro'];
      this.otherTypes=['Día','Horas'];
      this.relatives=['Padre/Madre','Hermano(a)','Hijo(a)','Conyugue'];

  }
  showDetail(leave: any) {
    this.visibleDetail = true;
    this.getLeaveBossDetail(leave.id)

  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023+2;
    this.years = Array.from({ length: numberOfYearsToShow }, (_, index) => 2023 + index);

    this.getLeavesRhData(this.year)

  }

  getLeavesRhData(year:number)
  {
    this.rhLeavesService.getLeavesRhData(year).subscribe({
      next: data => {
        this.leaves=data
        this.isLoading=false
        this.year=year
      },
      error: (error: Error) => {
      }
  });
  }
  async getLeaveBossDetail(pk:number)
  {
    this.rhLeavesService.getLeaveDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        var dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailMotive=data.motive;
        this.detailRelative=data.relative;
        this.base64DetailBeforeProof=data.proof;
        this.detailStatus=data.status;
        if (this.detailStatus!="En espera"){
          this.isAccepted=true;
        }
        this.detailComments=data.comments;
        this.detailBossComments=data.boss_comments;
        this.detailOtherType=data.other_type;
        this.detailTime=data.time;
        this.detailRecoveryTime=data.recovery_time;
        this.detailProofType=data.proof_type;
        this.detailBoss=data.boss;
        if (data.recovery_day!=""){
          var recoveryDatesText = data.recovery_day;
          var recoveryDatesArray = recoveryDatesText.split(", ");
          var recoveryDates = recoveryDatesArray.map((dateText: string) => new Date(dateText));
          this.detailRecoveryDay=recoveryDates;
          this.recoveryDetailDecision=true;
        }else
        {
        this.detailRecoveryDay=[];
        this.recoveryDetailDecision=false;
      }

        this.detailId=data.id
        this.detailEmployee=data.employee



              },
      error: (error: Error) => {
      }
  });
  }

  getColors(status: string) {
    switch (status) {
        case 'Aceptado':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazado':
            return 'denied';
        case 'Terminado':
            return 'finished';
        case 'En espera':
            return 'waiting';
        case 'No cumplio':
            return 'canceled';
        default:
          return 'inProcess';
    }

}
cleanDetailForm(){
  this.recoveryDetailDecision=false;
  this.detailStart=new Date();
  this.detailEnd=new Date();
  this.detailRelative="";
  this.detailProof=new File([],'');
  this.base64DetailProof="";
  this.detailComments="";
  this.detailBoss="";
  this.detailBossComments=""
  this.detailMotive="";
  this.detailOtherType="";
  this.detailTime="";
  this.detailRecoveryTime="";
  this.detailRecoveryDay=[];
  this.detailStatus="";
  this.isAccepted=false;
}
clear(table: Table) {
  table.clear();
}
showReportDialog()
{
  this.reportDialog=true;
}
getReport()
  {
    const convStart=this.reportStartDate.getFullYear()+"-"+(this.reportStartDate.getMonth()+1)+"-"+this.reportStartDate.getDate()
    const convEnd=this.reportEndDate.getFullYear()+"-"+(this.reportEndDate.getMonth()+1)+"-"+this.reportEndDate.getDate()
    this.rhLeavesService.getLeavesReport(convStart,convEnd,this.reportStatus,this.reportName,this.reportMotive,this.reportPaid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        const textStart = this.reportStartDate.getDate().toString().padStart(2, '0') + "-" +
                  (this.reportStartDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                  this.reportStartDate.getFullYear();
        const textEnd = this.reportEndDate.getDate().toString().padStart(2, '0') + "-" +
                        (this.reportEndDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                        this.reportEndDate.getFullYear();
        const file_name="Reporte de permisos del " + textStart + " al " + textEnd + ".pdf"
        link.download = file_name;
        link.click()

        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo generado.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el archivo.' });
      }
    });
  }
  cleanReportForm()
  {
    this.reportStartDate=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.reportEndDate=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
    this.reportStatus=[];
    this.reportName="";
    this.reportMotive=[];
    this.reportPaid=[];

  }





downloadFile() {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(this.base64ToBlob(this.base64DetailBeforeProof));
  element.download = 'Comprobante del permiso '+this.detailId;
  element.click();
}
base64ToBlob(base64Data: string): Blob {
  const byteString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }const base64String = '...'; // Tu cadena base64 aquí
  this.mimeType = 'application/pdf';
  var mimeTypes = require('mime')
  const mimeType = mimeTypes.getType(this.detailProofType);
  if (mimeType!=null){
    this.mimeType = mimeType;
  }


  const blob = new Blob([uintArray], { type: this.mimeType });
  return blob;
}

}
