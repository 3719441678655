<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">

  <div class="col-10" style="margin:auto;">
      <h2 class="titulo">Consulta de empleados.</h2>
      <div class="grid">
        <div class="col-8">
          <h3>Agregar un nuevo usuario al sistema</h3>
          </div>
          <div class="col-4" style="align-items: center;display: flex;">
              <button (click)="showDialog()"  class="btn" style="margin-top: 2%;">Agregar</button>
          </div>
      </div>
      <h4>*Selecciona el empleado del que quieras conocer mas información.</h4>
      <p-table #dt1 [value]="subordinates" [tableStyle]="{'min-width': '40rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
      [globalFilterFields]="['name','position', 'area', 'branch','enterprise', 'status']">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-6">
          <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        </div>
        <div class="col-6" style="align-items:end;display: flex;">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
            </span>
          </div>
        </div>
    </ng-template>
      <ng-template pTemplate="header">
              <tr>
                  <th>Numero de empleado</th>
                  <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                  <th pSortableColumn="position">Posición <p-sortIcon field="position"></p-sortIcon></th>
                  <th pSortableColumn="area">Area <p-sortIcon field="area"></p-sortIcon></th>
                  <th pSortableColumn="enterprise">Empresa <p-sortIcon field="enterprise"></p-sortIcon></th>
                  <th pSortableColumn="branch">Sucursal <p-sortIcon field="branch"></p-sortIcon></th>
                  <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-subordinate >
              <tr (click)="showDetail(subordinate)">
                  <td>{{ subordinate.employee_number }}</td>
                  <td>{{subordinate.name}} <img  *ngIf="isBirthday(subordinate.birthday)" [src]="birthdayImage" style="width: 16px; height: 16px; margin-left: 5px;"></td>
                  <td>{{ subordinate.position }}</td>
                  <td>{{ subordinate.area }}</td>
                  <td>{{ subordinate.enterprise }}</td>
                  <td>{{ subordinate.branch }}</td>
                  <td style="white-space: nowrap;"><tag  [ngClass]="getColors(subordinate.status)">{{subordinate.status}}</tag></td>
              </tr>
          </ng-template>


      </p-table>

</div>
</div>
</ng-container>


<p-dialog [header]="'Perfil del empleado'" [(visible)]="visibleDetail" [modal]="true" [draggable]="false" (onHide)="cleanDetailForm()" *ngIf="status!=''" style="width:100% !important">
  <div class="grid" style="min-width: 80%;">
    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Informacion del empleado.</h3>
      <div class="frame" style="display: flex; align-items: center; justify-content: center;">

        <img [src]="userImg">
      </div>
          <div class="flex flex-column gap-2" style="width: 50%;margin: auto;">
            <label htmlFor="username" style="text-align: center;">Foto de perfil: </label>
            <p-fileUpload mode="basic"  chooseIcon="pi pi-camera" accept="image/*" name="proof[]" (onSelect)="onUploadImage($event)"  [maxFileSize]='8000000' #fileCleanImage uploadStyleClass="width:50%"></p-fileUpload>

          </div>
        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Numero de empleado</label>
                  <input type="text" pInputText [(ngModel)]="username" name="username" placeholder="Numero de empleado" disabled>
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Nombre completo</label>
                <input type="text" pInputText [(ngModel)]="name" name="nombre" placeholder="Nombre" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Telefono</label>
                <input type="text" pInputText [(ngModel)]="phonenumber" name="telefono" placeholder="Telefono" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Fecha de nacimiento</label>
                <p-calendar [(ngModel)]="birthday" name="birthday"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"></p-calendar>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Escolaridad</label>
                <input type="text" pInputText [(ngModel)]="escolarity" name="escolaridad" placeholder="Escolaridad" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">CURP</label>
                <input type="text" pInputText [(ngModel)]="curp" name="curp" placeholder="CURP" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">RFC</label>
                <input type="text" pInputText [(ngModel)]="rfc" name="rfc" placeholder="RFC" >
              </div>
            </div>
    </div>



    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Informacion en la empresa.</h3>

        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Puesto</label>
                  <input type="text" pInputText [(ngModel)]="position" name="position" placeholder="Puesto" >
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Sucursal</label>
                <p-dropdown [options]="branches" appendTo="body" [editable]="true" name="detail-branch" [(ngModel)]="branch" [filter]="true" placeholder="Seleccionar sucursal"></p-dropdown>
         </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Area</label>
                <p-dropdown [options]="areas" appendTo="body" [editable]="true" name="detail-area" [(ngModel)]="area" [filter]="true" placeholder="Seleccionar area"></p-dropdown>
          </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Empresa</label>
                <p-dropdown [options]="enterprises" appendTo="body" [editable]="true" name="detail-enterprise" [(ngModel)]="enterprise" [filter]="true"placeholder="Seleccionar empresa"></p-dropdown>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Correo de Secovam</label>
                <input type="text" pInputText [(ngModel)]="secomail" name="mail" placeholder="Correo de Secovam" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Jefe inmediato</label>
                <p-dropdown [options]="bosses" appendTo="body" name="detail-boss" optionLabel="drop_text" [(ngModel)]="boss" [filter]="true"placeholder="Seleccionar el jefe"></p-dropdown>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Dias de vacaciones al año</label>
                <input type="text" pInputText [(ngModel)]="vac_days" name="vac_days" placeholder="Dias de vacaciones al año" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">IMSS</label>
                <input type="text" pInputText [(ngModel)]="imss" name="imss" placeholder="imss" >
              </div>
            </div>
            <div class="grid" *ngIf="imss_doc!=''">
              <div class="col-7 md:col-7 lg:col-8" style="bottom: 0;" (click)="downloadFile()">
                <label for="detail-proof">Alta del IMSS</label>
                <input type="text"  (click)="downloadFile()" pInputText [(ngModel)]="placeholder_imss" name="imss_doc"  placeholder="Alta del IMSS" disabled style=" cursor: pointer; text-decoration: underline;">
              </div>
              <div class="col-5 md:col-5 lg:col-4">
            <label for="detail-proof">Cambiar </label>
            <p-fileUpload mode="basic"  chooseIcon="pi pi-folder" name="proof[]" (onSelect)="onUploadImss($event)" #fileClean></p-fileUpload>
          </div>
          </div>
          <div class="grid" *ngIf="imss_doc==''">
            <div class="col-12 md:col-12 lg:col-12" style="bottom: 0;" >
              <label for="detail-proof">Alta del IMSS</label>

          <p-fileUpload mode="basic"  chooseIcon="pi pi-folder" name="proof[]" (onSelect)="onUploadImss($event)" #fileClean></p-fileUpload>
        </div>
        </div>

    </div>


    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Estado del empleado.</h3>
      <div class="field">
        <div class="flex flex-column gap-2">
          <label htmlFor="detail-type">Tipo de usuario</label>
          <p-dropdown [options]="user_types" appendTo="body"  name="detail-type" [(ngModel)]="userType" [filter]="true"placeholder="Seleccionar tipo de usuario"></p-dropdown>
              </div>
      </div>
        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="start_date">Fecha de ingreso</label>
                  <input type="text" pInputText [(ngModel)]="start_date" name="start_date" placeholder="Fecha de ingreso" disabled>
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Antigüedad</label>
                <input type="text" pInputText [(ngModel)]="seniority" name="seniority" placeholder="Antigüedad" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2" *ngIf="end_date!==null">
                <label htmlFor="telefono">Fecha de salida</label>
                <input type="text" pInputText [(ngModel)]="end_date" name="end_date" placeholder="Fecha de salida" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Dias restantes de vacaciones</label>
                <input type="text" pInputText [(ngModel)]="rem_days" name="rem_days" placeholder="Dias restantes de vacaciones" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Periodo vacacional actual</label>
                <input type="text" pInputText [(ngModel)]="period" name="period" placeholder="Periodo vacacional" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Status</label>
                <p-dropdown [options]="user_statuses" appendTo="body" [editable]="true"	 [ngClass]="getColorsProfile(status)" name="detail-status" [(ngModel)]="status" placeholder="Seleccionar Status"></p-dropdown>

              </div>
            </div>

    </div>
    <div class="col-10 md:col-11 lg:col-11" style="margin:auto;">
      <div class="grid">
        <div class="col-6">
      <button  type="submit"  (click)="modifyData()" class="btn" style="margin-top: 1%;">Guardar cambios.</button>
      </div>
      <div class="col-6">
      <button  type="submit"  (click)="resetConfirm()" class="btn" style="margin-top: 1%;" *ngIf="userType!='Jefe de Recursos Humanos'">Generar contraseña.</button>
    </div>
      <div class="col-6">
      <button  type="submit"  (click)="dischargeConfirm()" class="btn" style="margin-top: 1%;background-color: #C2262E;">Dar de baja</button>
    </div>
    <div class="col-6">
      <button  type="submit"  (click)="showAddDays()" class="btn" style="margin-top: 1%; background-color: #009F4D;">Sumar vacaciones</button>
    </div>
    </div>
    </div>
    <div class="col-10 md:col-10 lg:col-10" style="margin:auto;margin-top: 1%;">
    <p-table #dt1 [value]="absences" [tableStyle]="{'min-width': '40rem'}"  selectionMode="single"   [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
        [globalFilterFields]="['start_date','employee', 'end_date', 'type', 'status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th pSortableColumn="start_date">Fecha de inicio <p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin <p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="type">Tipo <p-sortIcon field="type"></p-sortIcon></th>
                    <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-absence >
                <tr (click)="absence">
                    <td>{{ absence.id }}</td>
                    <td>{{ absence.start_date }}</td>
                    <td>{{ absence.end_date }}</td>
                    <td>{{ absence.type }}</td>
                    <td><tag  [ngClass]="getColorsAbsence(absence.status)">{{absence.status}}</tag></td>
                </tr>
            </ng-template>


        </p-table>
        </div>


    </div>

</p-dialog>


<p-dialog [header]="'Nuevo perfil:'" [(visible)]="visibleModal" [modal]="true" [draggable]="false" (onHide)="cleanNewForm()"  style="width:100% !important">
  <div class="grid" style="min-width: 80%;">
    <div class="col-10 md:col-3 lg:col-3" style="margin-left:auto;margin-right: auto;">
      <h3 class="title">Informacion del empleado.</h3>
      <div class="frame" style="display: flex; align-items: center; justify-content: center;">

        <img [src]="new_userImg">
      </div>
          <div class="flex flex-column gap-2" style="width: 50%;margin: auto;">
            <label htmlFor="username" style="text-align: center;">Foto de perfil: </label>
            <p-fileUpload mode="basic"  chooseIcon="pi pi-camera" accept="image/*" name="proof[]" (onSelect)="onUploadNewImage($event)"  [maxFileSize]='8000000' #fileCleanImage uploadStyleClass="width:50%"></p-fileUpload>

          </div>
          <div style="width: 100%;margin-top: 3%;margin-bottom: 1%;">
            <label htmlFor="new-enterprise">Crea el numero de empleado:</label>
          </div>
          <div class="field" style="display: flex;">

            <div style="width:65%">
              <label htmlFor="new-enterprise">Empresa</label>
              <p-dropdown [options]="enterprises" appendTo="body" [editable]="true" name="new-enterprise" [(ngModel)]="new_enterprise" [filter]="true"placeholder="Seleccionar empresa"></p-dropdown>
            </div>
            <div style="width:35%">
              <label htmlFor="new_number">Numero</label>
              <input type="text" pInputText [(ngModel)]="new_number" name="new_number" placeholder="Empleado" >

              </div>

          </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Nombre completo</label>
                <input type="text" pInputText [(ngModel)]="new_name" name="nombre" placeholder="Nombre" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Telefono</label>
                <input type="text" pInputText [(ngModel)]="new_phonenumber" name="telefono" placeholder="Telefono" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Fecha de nacimiento</label>
                <p-calendar [(ngModel)]="new_birthday" name="new-start"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates"></p-calendar>
                </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Escolaridad</label>
                <input type="text" pInputText [(ngModel)]="new_escolarity" name="escolaridad" placeholder="Escolaridad" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">CURP</label>
                <input type="text" pInputText [(ngModel)]="new_curp" name="curp" placeholder="CURP" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">RFC</label>
                <input type="text" pInputText [(ngModel)]="new_rfc" name="rfc" placeholder="RFC" >
              </div>
            </div>
    </div>



    <div class="col-10 md:col-3 lg:col-3" style="margin-left:auto;margin-right: auto;">
      <h3 class="title">Informacion en la empresa.</h3>

        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Puesto</label>
                  <input type="text" pInputText [(ngModel)]="new_position" name="position" placeholder="Puesto" >
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Sucursal</label>
                <p-dropdown [options]="branches" appendTo="body" [editable]="true" name="detail-branch" [(ngModel)]="new_branch" [filter]="true" placeholder="Seleccionar sucursal"></p-dropdown>
         </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Area</label>
                <p-dropdown [options]="areas" appendTo="body" [editable]="true" name="detail-area" [(ngModel)]="new_area" [filter]="true" placeholder="Seleccionar area"></p-dropdown>
          </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Empresa</label>
                <p-dropdown [options]="enterprises" appendTo="body" [editable]="true" name="detail-enterprise" [(ngModel)]="new_enterprise" [filter]="true"placeholder="Seleccionar empresa"></p-dropdown>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Correo de Secovam</label>
                <input type="email" pInputText [(ngModel)]="new_secomail" name="mail" placeholder="Correo de Secovam" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Jefe inmediato</label>
                <p-dropdown [options]="bosses" appendTo="body" name="detail-boss" optionLabel="drop_text" [(ngModel)]="new_boss" [filter]="true"placeholder="Seleccionar el jefe"></p-dropdown>
              </div>
            </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">IMSS</label>
                <input type="text" pInputText [(ngModel)]="new_imss" name="imss" placeholder="imss" >
              </div>
            </div>

              <div class="field">
            <label for="detail-proof">Documento del IMSS </label>
            <p-fileUpload mode="basic"  chooseIcon="pi pi-folder" name="proof[]" (onSelect)="onUploadNewImss($event)" #fileClean></p-fileUpload>
          </div>


    </div>


    <div class="col-10 md:col-3 lg:col-3" style="margin-left:auto;margin-right: auto;">
      <h3 class="title">Estado del empleado.</h3>
          <div class="field">
            <div class="flex flex-column gap-2">
              <label htmlFor="detail-type">Tipo de usuario</label>
              <p-dropdown [options]="user_types" appendTo="body"  name="detail-type" [(ngModel)]="new_userType" [filter]="true"placeholder="Seleccionar tipo de usuario"></p-dropdown>
                  </div>
          </div>
            <div class="field">
                    <div class="flex flex-column gap-2">
                      <label htmlFor="new-start">Fecha de ingreso</label>
                      <p-calendar [(ngModel)]="new_start_date" name="new-start"  dateFormat="dd-mm-yy" [showIcon]="true" (onSelect)="calculateSeniority()" appendTo="body" class="select-dates"></p-calendar>
                    </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="new-seniority">Antigüedad</label>
                <input type="text" pInputText [(ngModel)]="new_seniority" name="new_seniority" placeholder="Antigüedad" disabled>
             </div>
              </div>
              <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="new-seniority">Dias de vacaciones</label>
                  <input type="text" pInputText [(ngModel)]="new_total_vacations" name="new_total_vac" placeholder="Antigüedad" disabled>
               </div>
                </div>
                <div class="field">
                  <div class="flex flex-column gap-2">
                    <label htmlFor="new-seniority">Dias sabados</label>
                    <input type="text" pInputText [(ngModel)]="new_total_saturdays" name="new_total_sat" placeholder="Antigüedad" disabled>
                 </div>
                  </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Dias de vacaciones ya tomados:</label>
                <input type="text" pInputText [(ngModel)]="new_vac_days" name="new_vac_days" placeholder="Dias ya tomados" >
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Dias sabados ya tomados:</label>
                <input type="text" pInputText [(ngModel)]="new_saturdays" name="new_saturdays" placeholder="Dias sabados tomados" >
              </div>
            </div>



    </div>
    <div class="col-10 md:col-11 lg:col-11" style="margin:auto;">
      <button  type="submit"  (click)="  newProfile()" class="btn" style="margin-top: 1%;">Generar perfil.</button>
    </div>


    </div>

</p-dialog>








<p-confirmDialog #cd [position]="'top'">

  <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="Rechazar" (click)="cd.reject()"></button>
      <button type="button" pButton icon="pi pi-check" label="Aceptar" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>


<p-dialog class="smaller-dialog"  header="Exito" [(visible)]="newPasswordDialog" [style]="{width: '40vw'}">
  <p style="text-align: center;">
       La contraseña generada para {{this.username}} es: <br>
       <strong>{{this.newPassword}}</strong></p>
</p-dialog>

<div class="smaller-dialog">
<p-dialog  [header]="'Vacaciones a sumar a ' + this.name" [(visible)]="addDaysDialog" >

  <div class="container" style="min-width: 30vw;">
    <div class="content-input">
      <label htmlFor="detail-addDays">Dias de vacaciones</label><br>
      <input type="text" pInputText [(ngModel)]="addDays" name="detail-addDays" placeholder="Dias de vacaciones" style="font-weight: normal; width:100%">
    </div>
  </div>
  <div class="container">
    <div class="content-input" >
      <label htmlFor="detail-addSaturdays">Dias sabados</label><br>
      <input type="text" pInputText [(ngModel)]="addSaturdays" name="detail-addSaturdays" placeholder="Escribe la contraseña de nuevo" style="font-weight: normal; width:100%">
    </div>
  </div>

    <button  type="submit"  (click)="  addVacationDays()" class="btn" style="margin-top: 1%;">Agregar dias.</button>
</p-dialog>
</div>
<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
