<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">
    <div class="col-10" style="margin:auto;">
        <h2 class="titulo">Mis solicitud de cambios de vacaciones.</h2>

        <p-table [value]="vac_changes" [tableStyle]="{'min-width': '45rem'}"  selectionMode="single" #dt1     [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
        [globalFilterFields]="['start_date', 'end_date', 'substitute', 'change_type','status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
      </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th pSortableColumn="start_date">Fecha de inicio<p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin<p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="substitute">Sustituto<p-sortIcon field="substitute"></p-sortIcon></th>
                    <th pSortableColumn="change_type">Tipo de cambio<p-sortIcon field="change_type"></p-sortIcon></th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-change >
                <tr (click)="showDetail(change)">
                    <td>{{ change.id }}</td>
                    <td>{{ change.start_date }}</td>
                    <td>{{ change.end_date }}</td>
                    <td>{{ change.substitute }}</td>
                    <td><tag  [ngClass]="getChangeColors(change.change_type)">{{change.change_type}}</tag></td>
                    <td><tag  [ngClass]="getColors(change.status)">{{change.status}}</tag></td>
                </tr>
            </ng-template>
        </p-table>
        <h4>*Si deseas consultar, cambiar o cancelar una solicitud solo seleccionala.</h4>
        <div class="grid">
          <div class=" md:col-9 lg:col-9">

          </div>
          <div class="col-12 md:col-3 lg:col-3" style="align-items: center;display: flex;">
            <a href="/myVacations" style="width: 100%;">
              <button  href="/myVacations" type="submit" class="btn" style="margin-top: 2%;">Volver</button>
            </a>
          </div>

          </div>
</div>

</div>


<p-dialog [header]="'Detalles de la solicitud '+detailId+': Vacación: ' + beforeId" [(visible)]="visibleDetail" [modal]="true" [draggable]="false">
  <div class="container" style="min-width: 30vw;">

    <div class="content-input">
      <label htmlFor="selected-period">Periodo</label>
        <input type="text" pInputText [(ngModel)]="detailPeriod" name="detail-period" placeholder="Periodo" [disabled]="true">
        </div>
    </div>
  <div class="container" style="min-width: 30vw;">
    <div class="grid">
    <div class="col-6">
    <div class="content-input">
      <label htmlFor="detail-start">Inicio anterior</label>
        <p-calendar [(ngModel)]="beforeStart" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="true"></p-calendar>
    </div>
    </div>
    <div class="col-6">
      <div class="content-input">
        <label htmlFor="detail-start">Fecha de inicio </label>
          <p-calendar [(ngModel)]="detailStart" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="isBlocked"></p-calendar>
      </div>
      </div>
  </div>
</div>
  <div class="container">
    <div class="grid">
      <div class="col-6">
      <div class="content-input">
        <label htmlFor="detail-end">Fin anterior</label>
        <p-calendar [(ngModel)]="beforeEnd" name="detail-end"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="true"></p-calendar>
      </div>
      </div>
      <div class="col-6">
        <div class="content-input">
          <label htmlFor="detail-end">Fecha de fin </label>
          <p-calendar [(ngModel)]="detailEnd" name="detail-end"  dateFormat="dd-mm-yy" [showIcon]="true" appendTo="body" class="select-dates" [disabled]="isBlocked"></p-calendar>
        </div>
        </div>
    </div>
  </div>
  <div class="container">
    <div class="grid">
      <div class="col-6" *ngIf="detailStatus=='En espera'" style="padding-bottom: 0;">
      <div class="content-input">
        <label htmlFor="detail-before-days">Dias anteriores</label>
        <input type="text" pInputText [(ngModel)]="detailBeforeDays" name="detail-before-days" placeholder="Dias anteriores" [disabled]="true">
      </div>
      </div>
      <div class="col-6" style="padding-bottom: 0;">
        <div class="content-input" >
          <label htmlFor="detail-before-saturdays">Dias habiles </label>
          <input type="text" pInputText [(ngModel)]="detailWorkDays" name="detail-before-saturdays" placeholder="Dias habiles" [disabled]="true">
        </div>
        </div>
        <div class="col-6" *ngIf="detailStatus=='En espera'" style="padding-bottom: 0;">
          <div class="content-input">
            <label htmlFor="detail-days">Sabados anteriores</label>
            <input type="text" pInputText [(ngModel)]="detailBeforeSaturdays" name="detail-days" placeholder="Sabados anteriores" [disabled]="true">
          </div>
          </div>
          <div class="col-6" style="padding-bottom: 0;">
            <div class="content-input">
              <label htmlFor="detail-saturdays">Sabados cubiertos</label>
              <input type="text" pInputText [(ngModel)]="detailSaturdays" name="detail-saturdays" placeholder="Sabados cubiertos" [disabled]="true">
            </div>
            </div>
    </div>
  </div>
  <div class="container">
    <div class="grid">
      <div class="col-6">
      <div class="content-input">
        <label htmlFor="detail-substitute">Sustituto anterior</label>
        <input type="text" pInputText [(ngModel)]="beforeSubstitute" name="detail-substitute" placeholder="Sustituto" [disabled]="true">
      </div>
      </div>
      <div class="col-6">
        <div class="content-input">
          <label htmlFor="detail-substitute">Sustituto </label>
          <input type="text" pInputText [(ngModel)]="detailSubstitute" name="detail-substitute" placeholder="Sustituto" [disabled]="isBlocked">
        </div>
        </div>
    </div>



  </div>
  <div class="container">
    <div class="grid">
      <div class="col-12">
    <div class="content-input" >
      <label htmlFor="detail-change">Tipo de cambio </label>
        <input type="text" pInputText [(ngModel)]="detailChange" name="detail-change" placeholder="Tipo de cambio" [disabled]="true" [ngClass]="getChangeColors(detailChange)" >
    </div>
  </div>
</div>
</div>
  <div class="container">
    <div class="grid">
      <div class="col-12">
    <div class="content-input" >
      <label htmlFor="detail-status">Status </label>
        <input type="text" pInputText [(ngModel)]="detailStatus" name="detail-status" placeholder="Status" [disabled]="true" [ngClass]="getColors(detailStatus)" >
    </div>
  </div>
</div>
</div>
  <div class="container">
    <div class="grid">
      <div class="col-12">
    <div class="content-input">
      <label htmlFor="detail-comment">Comentarios </label>
        <textarea  type="text" pInputText [(ngModel)]="detailComments" name="detail-comment" placeholder="Comentarios" [disabled]="isBlocked"></textarea>
    </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="detailBossComments!=''">
    <div class="grid">
      <div class="col-12">
    <div class="content-input">
      <label htmlFor="detail-boss">Comentarios del jefe</label>
        <textarea  type="text" pInputText [(ngModel)]="detailBossComments" name="detail-boss" placeholder="Comentarios del jefe" [disabled]="isBlocked"></textarea>
    </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12">
    <div style="width: 100%;">
        <button  type="submit"  *ngIf="detailChange=='Modificación'"class="btn" style="margin-top: 2%;" (click)="putVacationChangeData()">Hacer cambios.</button>
    </div></div></div>
    <div class="grid">
      <div class="col-12">
    <div style="display: inline-block;width: 100%;">
      <button  type="submit"  class="btn" style="background-color: #C2262E;" (click)="deleteVacationChangeData()">Cancelar solicitud.</button>
  </div></div></div>
</p-dialog>
</ng-container>



<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
