<app-home></app-home>
<p-toast></p-toast>
<div class="grid">

  <div class="col-10" style="margin:auto;">
      <h2 class="titulo">Administracion del sistema.</h2>
      <div class="grid">
          <div class="col-12">
              <h3>Consulta de información:</h3>
              </div>

          <div class="col-6" style="align-items: center;display: flex;">
              <button  (click)="showDialog(0)" type="submit"  class="btn" style="margin-top: 2%;">Dias Festivos</button>
          </div>
          <div class="col-6" style="align-items: center;display: flex;">
            <button  (click)="showDialog(1)" type="submit"  class="btn" style="margin-top: 2%;">Areas</button>
        </div>
        <div class="col-6" style="align-items: center;display: flex;">
          <button  (click)="showDialog(2)" type="submit"  class="btn" style="margin-top: 2%;">Empresas</button>
      </div>

      <div class="col-6" style="align-items: center;display: flex;">
        <button  (click)="showDialog(3)" type="submit"  class="btn" style="margin-top: 2%;">Sucursales</button>
    </div>
    <div class="col-12">
      <h3>Agregar información al sistema.</h3>
      </div>

      <div class="col-6" style="align-items: center;display: flex;">
        <button  (click)="showAddDialog(0)" type="submit"  class="btn" style="margin-top: 2%; background-color: #009F4D;">Dias Festivos</button>
    </div>

    <div class="col-6" style="align-items: center;display: flex;">
      <button  (click)="showAddDialog(1)" type="submit"  class="btn" style="margin-top: 2%; background-color: #009F4D;">Areas</button>
  </div>

  <div class="col-6" style="align-items: center;display: flex;">
    <button  (click)="showAddDialog(2)" type="submit"  class="btn" style="margin-top: 2%; background-color: #009F4D;">Empresas</button>
</div>

<div class="col-6" style="align-items: center;display: flex;">
  <button  (click)="showAddDialog(3)" type="submit"  class="btn" style="margin-top: 2%; background-color: #009F4D;">Sucursales</button>
</div>

<div class="col-12">
  <h3>Consulta de información sensible.</h3>
  </div>
  <div class="col-6" style="align-items: center;display: flex;">
    <h3>Obtener las contraseñas generadas en el sistema:</h3>
  </div>
  <div class="col-6" style="align-items: center;display: flex;">
    <button  (click)="showDialog(4)" type="submit"  class="btn" style="margin-top: 2%; background-color: #e32e89;">Consultar</button>
  </div>
  <div class="col-6" style="align-items: center;display: flex;">
    <h3>Obtener usuarios con vacaciones pendientes:</h3>
  </div>
  <div class="col-6" style="align-items: center;display: flex;">
    <button  (click)="showDialog(5)" type="submit"  class="btn" style="margin-top: 2%; background-color: #e32e89;">Consultar</button>
  </div>


  </div>
  </div>
</div>
<div class="smaller-dialog">
  <p-dialog header="" [(visible)]="addDataDialog" [modal]="true" [draggable]="false" (onHide)="cleanAddForm()">
    <ng-template pTemplate="header">
      <div *ngIf="addType=='Dia Festivo'">
      <span class="text-xl font-bold">Registro de dias festivos:  <i class="pi pi-sun"></i></span>
    </div>
    <div *ngIf="addType=='Empresa'">
      <span class="text-xl font-bold">Registro de empresas:  <i class="pi pi-building"></i></span>
    </div>
    <div *ngIf="addType=='Sucursal'">
      <span class="text-xl font-bold">Registro de sucursales: <i class="pi pi-image"></i></span>
    </div>
    <div *ngIf="addType=='Area'">
      <span class="text-xl font-bold">Registro de areas:  <i class="pi pi-id-card"></i></span>
    </div>
  </ng-template>
  <div *ngIf="addType=='Dia Festivo'">
    <div class="container" style="min-width: 30vw;">
      <div class="content-input">
        <label htmlFor="detail-addDays">Festividad:</label><br>
        <input type="text" pInputText [(ngModel)]="festivity" name="detail-addDays" placeholder="Motivo de la festividad" style="font-weight: normal; width:100%">
      </div></div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha</label>
            <p-calendar [(ngModel)]="festivityDate" name="detail-start"  dateFormat="dd-mm-yy" [showIcon]="true"  appendTo="body" class="select-dates"></p-calendar>
        </div>
      </div>
      <button  type="submit"  (click)="addFestivity()" class="btn" style="margin-top: 2%;">Agregar.</button>
  </div>

    <div *ngIf="addType=='Sucursal'">
      <div class="container" style="min-width: 30vw;">
        <div class="content-input">
          <label htmlFor="detail-addDays">Ciudad:</label><br>
          <input type="text" pInputText [(ngModel)]="branchCity" name="detail-addDays" placeholder="Ciudad de la sucursal" style="font-weight: normal; width:100%">
        </div></div>
      <button  type="submit"  (click)="addBranch()" class="btn" style="margin-top: 2%;">Agregar.</button>
    </div>

    <div *ngIf="addType=='Area'">
      <div class="container" style="min-width: 30vw;">
        <div class="content-input">
          <label htmlFor="detail-addDays">Nombre del area:</label><br>
          <input type="text" pInputText [(ngModel)]="areaName" name="detail-addDays" placeholder="Nombre del area" style="font-weight: normal; width:100%">
        </div></div>
      <button  type="submit"  (click)="addArea()" class="btn" style="margin-top: 2%;">Agregar.</button>
    </div>
    <div *ngIf="addType=='Empresa'">
      <div class="container" style="min-width: 30vw;">
        <div class="content-input">
          <label htmlFor="detail-addDays">Nombre de la empresa:</label><br>
          <input type="text" pInputText [(ngModel)]="enterpriseName" name="detail-addDays" placeholder="Nombre de la empresa" style="font-weight: normal; width:100%">

        </div></div>

      <button  type="submit"  (click)="addEnterprise()" class="btn" style="margin-top: 2%;">Agregar.</button>
    </div>

  </p-dialog>
  </div>


<!--SHOW INFO DIALOG-->


  <div class="smaller-dialog">
    <p-dialog header="" [(visible)]="seeDataDialog" style="max-width: 100%;"[modal]="true" [draggable]="false" (onHide)="cleanSeeForm()">
      <ng-template pTemplate="header">
        <div *ngIf="consultType=='Dia Festivo'">
        <span class="text-xl font-bold">Dias festivos:  <i class="pi pi-sun"></i></span>
      </div>
      <div *ngIf="consultType=='Empresa'">
        <span class="text-xl font-bold">Empresas:  <i class="pi pi-building"></i></span>
      </div>
      <div *ngIf="consultType=='Sucursal'">
        <span class="text-xl font-bold">Sucursales: <i class="pi pi-image"></i></span>
      </div>
      <div *ngIf="consultType=='Area'">
        <span class="text-xl font-bold">Areas:  <i class="pi pi-id-card"></i></span>
      </div>
      <div *ngIf="consultType=='Vacaciones'">
        <span class="text-xl font-bold">Empleados con dias de vacaciones pendientes:  <i class="pi pi-user"></i></span>
      </div>
      <div *ngIf="consultType=='Contraseñas'">
        <span class="text-xl font-bold">Consulta de contraseñas generadas por el sistema:  <i class="pi pi-hashtag"></i></span>
      </div>
    </ng-template>
    <div *ngIf="consultType=='Dia Festivo'">
      <p-table #dt1 [value]="festiveDays" [tableStyle]="{'min-width': '30rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25]">
      <ng-template pTemplate="caption">

    </ng-template>
      <ng-template pTemplate="header">
              <tr>
                  <th>Id</th>
                  <th>Festividad </th>
                  <th>Fecha </th>
                  <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-festiveDay >
              <tr>
                  <td>{{ festiveDay.id }}</td>
                  <td>{{festiveDay.festivity}} </td>
                  <td>{{ festiveDay.date }}</td>
                  <td style="width: 20%;" ><tag  style="cursor: pointer;"[ngClass]="'canceled'" (click)="deleteFestivity(festiveDay)">ELIMINAR</tag></td>
              </tr>
          </ng-template>
      </p-table>
    </div>

  <div *ngIf="consultType=='Sucursal'">

        <p-table #dt1 [value]="branches" [tableStyle]="{'min-width': '30rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25]">
          <ng-template pTemplate="caption">

        </ng-template>
          <ng-template pTemplate="header">
                  <tr>
                      <th>Id</th>
                      <th>Ciudad </th>
                      <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-branch >
                  <tr>
                      <td>{{ branch.id }}</td>
                      <td>{{branch.city}} </td>
                      <td style="width: 20%;" ><tag  style="cursor: pointer;" [ngClass]="'canceled'" (click)="deleteBranch(branch)">ELIMINAR</tag></td>
                  </tr>
              </ng-template>
          </p-table>
    </div>


  <div *ngIf="consultType=='Area'">

        <p-table #dt1 [value]="areas" [tableStyle]="{'min-width': '30rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25]">
          <ng-template pTemplate="caption">

        </ng-template>
          <ng-template pTemplate="header">
                  <tr>
                      <th>Id</th>
                      <th>Nombre </th>
                      <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-area >
                  <tr>
                      <td>{{ area.id }}</td>
                      <td>{{area.name}} </td>
                      <td style="width: 20%;" ><tag  style="cursor: pointer;" [ngClass]="'canceled'" (click)="deleteArea(area)">ELIMINAR</tag></td>
                  </tr>
              </ng-template>
          </p-table>
    </div>


    <div *ngIf="consultType=='Empresa'">

        <p-table #dt1 [value]="enterprises" [tableStyle]="{'min-width': '30rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25]">
          <ng-template pTemplate="caption">

        </ng-template>
          <ng-template pTemplate="header">
                  <tr>
                      <th>Id</th>
                      <th>Nombre </th>
                      <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-enterprise >
                  <tr>
                      <td>{{ enterprise.id }}</td>
                      <td>{{enterprise.name}} </td>
                      <td style="width: 20%; "><tag  [ngClass]="'canceled'" style="cursor: pointer;"  (click)="deleteEnterprise(enterprise)">ELIMINAR</tag></td>
                  </tr>
              </ng-template>
          </p-table>
      </div>
      <div *ngIf="consultType=='Vacaciones'">

        <p-table #dt1 [value]="pendingVacations" [tableStyle]="{'min-width': '30rem'}"  selectionMode="single"   [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25]">
          <ng-template pTemplate="caption">

        </ng-template>
          <ng-template pTemplate="header">
                  <tr>
                      <th>Numero de empleado</th>
                      <th>Nombre </th>
                      <th>Empresa</th>
                      <th>Dias restantes</th>
                      <th>Fecha limite</th>
                      <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-pending >
                  <tr>
                      <td>{{ pending.employee_number }}</td>
                      <td>{{pending.name}} </td>
                      <td>{{pending.enterprise}} </td>
                      <td>{{pending.rem_days}} </td>
                      <td style="white-space: nowrap;">{{pending.limit_date}} </td>
                      <td style="width: 20%;  white-space: nowrap;"><tag  [ngClass]="'waiting'" style="cursor: pointer;"  (click)="sendMail(pending)">Enviar recordatorio</tag></td>
                  </tr>
              </ng-template>
          </p-table>
      </div>
      <div *ngIf="consultType=='Contraseñas'">
        <div class="container" style="min-width: 30vw;">
        <div class="content-input">
          <label htmlFor="detail-employee">Numero de empleado:</label><br>
          <input type="text" pInputText [(ngModel)]="employeeNumber" name="detail-employee" placeholder="Numero del empleado" style="font-weight: normal; width:100%">

        </div></div>
        <button  type="submit"  (click)="getAutomaticPassword()" class="btn" style="margin-top: 2%;">Buscar.</button>
        <button  type="submit"  (click)="confirmPasswordReport()" class="btn" style="margin-top: 2%;">Imprimir contraseñas.</button>
      </div>



    </p-dialog>
    </div>
<p-dialog class="smaller-dialog"  header="Exito" [(visible)]="automaticPasswordDialog" [style]="{width: '40vw'}">
  <p style="text-align: center;">
       La contraseña generada para {{this.employeeNumber}} es: <br>
       <strong>{{this.automaticPassword}}</strong></p>
</p-dialog>
<p-confirmDialog #cd [position]="'top'">

  <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="Rechazar" (click)="cd.reject()"></button>
      <button type="button" pButton icon="pi pi-check" label="Aceptar" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
