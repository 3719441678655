import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BossProfilesDataService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getProfilesBossData(): Observable<any> {
    const url = `${this.baseUrl}/boss/employees/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getProfileDetailData(pk:string): Observable<any> {
    const url = `${this.baseUrl}/boss/employees/detail/`+pk+'/'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
}
