<app-home></app-home>
<div class="image-container">
  <img src="assets/landing/new_banner2.jpg" alt="Imagen de secovam">
</div>
<div style="padding:1%">
<div class="grid" style="margin-right: auto;margin-left: auto;">
  <div class="col-12 md:col-8 lg:col-8" style="background-color: #003CA6;">
    <div class="center">
      <img src="assets/logos/favLogo.png" style="width:50%;max-width: 115px;margin-top: 2%;">
    </div>
      <div class="grid">
      <div class="col-12 md:col-5 lg:col-5" style="background-color: #003CA6;margin-left: auto;margin-right: auto;">
        <div class="container">
        <h3 class="center greater">MISIÓN</h3>
        <span class="center great" style="text-align: center;">Contribuir al sector empresarial
          con estudios confiables en
          servicios ambientales, seguridad,
          higiene y salud; aplicando la
          competencia de nuestro talento
          humano y utilizando
          tecnología de punta.</span>
      </div>
    </div>
      <div class="col-12 md:col-5 lg:col-5  " style="background-color: #003CA6;margin-left: auto;margin-right: auto;">
        <div class="container">
        <h3 class="center greater">VISIÓN</h3>
        <span class="center great" style="text-align: center;">Posicionarnos como una
          empresa de clase mundial,
          consolidándonos como líderes
          en la evaluación de la
          normatividad ambiental y laboral;
          logrando así el reconocimiento
          nacional e internacional.</span>
      </div>
    </div>
    </div>
  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">

    <div class="container">
    <iframe src="https://secovamnews.com/"style="width: 100%; height: 400px"></iframe>
  </div>
</div>
</div>
<h3 class="text-title">Nuestros valores</h3>
<div class="grid" style="margin-bottom: 2%;">
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/honesty.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Honestidad</h3>
        <span class="center value-text">Hablamos con franqueza y
          entregamos veracidad en
          nuestros resultados.</span>

  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/respect.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Respeto</h3>
        <span class="center value-text">Valoramos a las personas
          por su trabajo,
          manteniendo un trato
          amable.</span>

  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/responsability.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Responsabilidad</h3>
        <span class="center value-text">Somos profesionales con el
          uso de la información y
          nuestras herramientas de
          trabajo.</span>

  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/punctuality.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Puntualidad</h3>
        <span class="center value-text">Hablamos con franqueza y
          entregamos veracidad en
          nuestros resultados.</span>

  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/creativity.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Creatividad</h3>
        <span class="center value-text">Valoramos a las personas
          por su trabajo,
          manteniendo un trato
          amable.</span>

  </div>
  <div class="col-10 md:col-4 lg:col-4 " style="margin-left: auto;margin-right: auto;">
    <div class="center">
      <img src="assets/landing/lidership.png" style="width:50%;max-width: 200px;">
    </div>
    <h3 class="center value-title">Liderazgo</h3>
        <span class="center value-text">Somos profesionales con el
          uso de la información y
          nuestras herramientas de
          trabajo.</span>

  </div>


</div>
<div class="grid" style="margin-top:5%">
  <div class="col-10 md:col-6 lg:col-4 quality-policy" style="margin-left: auto;margin-right: auto;padding:2%">

    <h3 class="center greater" style="color:white">POLÍTICA DE CALIDAD    </h3>
        <span class="center great" style="color:white;padding-left:5%;padding-right: 5%;">
          La Dirección General de Grupo SECOVAM, orienta a
          todo el personal para ofrecer servicios de calidad como
          labo- ratorio de ensayo y consultoría en las áreas de
          protección ambiental, seguridad e higiene y alimentos en
          los centros de trabajo, direccionando los objetivos con la
          gestión de calidad siempre con el compromiso de la
          buena prácti- ca profesional y optimizando los recursos
          existentes para garantizar que nuestros servicios
          satisfacen los requisitos de la ISO/IEC 17025-2017, de
          los clientes y de las partes interesadas.</span>
          <div class="center" style="margin-top: 2%;margin-bottom: 1%">
            <img src="assets/landing/qualityManagement.png" style="width:20%;max-width: 120px;">
          </div>


  </div>
  <div class="col-10 md:col-6 lg:col-5" style="margin-left: auto;margin-right: auto;padding:2%">
    <table class="custom-table">
      <thead>
        <tr>
          <th>ANTIGUEDAD EN AÑOS</th>
          <th>DIAS DE VACACIONES</th>
          <th>DIAS DE PRIMA VACACIONAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>12</td>
          <td>3.0</td>
        </tr>
        <tr>
          <td>2</td>
          <td>14</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>3</td>
          <td>16</td>
          <td>4.0</td>
        </tr>
        <tr>
          <td>4</td>
          <td>18</td>
          <td>4.5</td>
        </tr>
        <tr>
          <td>5</td>
          <td>20</td>
          <td>5.0</td>
        </tr>
        <tr>
          <td>6 a 10</td>
          <td>22</td>
          <td>5.5</td>
        </tr>
        <tr>
          <td>11 a 15</td>
          <td>24</td>
          <td>6.0</td>
        </tr>
        <tr>
          <td>16 a 20</td>
          <td>26</td>
          <td>6.5</td>
        </tr>
        <tr>
          <td>21 a 25</td>
          <td>28</td>
          <td>7.0</td>
        </tr>
        <tr>
          <td>26 a 30</td>
          <td>30</td>
          <td>7.5</td>
        </tr>
      </tbody>
    </table>



  </div>


</div>
  <h3 class="text-title center" style="margin-bottom: 0px;margin-top:5%">POLITICA DE PREVENCIÓN</h3>
  <h3 class="text-title center"style="margin-bottom: 0px; margin-top:0px">“FACTORES DE RIESGO PSICOSOCIAL“</h3>
  <h3 class="text-title center" style="margin-top: 0px;">NOM-035-STPS-2018</h3>
  <span class="policies-text">
    La empresa, sus sucursales y colaboradores asumen el compromiso de:
  </span>
  <ul class="p-list">
    <li>Al surgir medidas preventivas y/o de acción, Recursos
      Humanos difundirá la información garantizando el conocimiento de todos los integrantes del grupo.</li>
      <br>
      <li>Todo personal deberá adoptar las medidas encaminadas a la prevención de los factores de riesgo psicosocial
        implementadas por Dirección General y Recursos Humanos.</li>
        <br>
      <li>Es responsabilidad de cada uno de los integrantes del grupo pro- mover un entorno laboral favorable, mediante la
        adecuada comunicación, buen trato y respeto hacia los colaboradores, clientes y proveedores</li>
        <br>
      <li>Informar y aplicar el procedimiento establecido por Grupo SECOVAM para el tratamiento y seguimiento en la
        detección de factores de riesgo psicosocial.</li>
        <br>
      <li>Dar seguimiento al procedimiento de atención con justicia, que no permita represalias y evite reclamaciones
        deshonestas o carentes de fundamento y que garantice la confidencialidad de los casos.</li>
        <br>
      <li>No tolerar ni promover malos tratos, hostigamiento, acoso o actos de violencia laboral; ni acciones en contra de un
        entorno laboral favorable.</li>
  </ul>

  <p class="policies-text">
    Es obligación de los coordinadores, jefes y gerentes de cada área, así como los Directores del Grupo: promover y cumplir
    en su totalidad con esta Política de Prevención; así como canalizar al área de Recursos Humanos, en caso de
    incumplimiento, para la ejecución de las medidas de acción de acuerdo al reglamento interno de trabajo en beneficio de un
    Entorno Laboral Favorable.
  </p>
  <div class="grid">
    <div class="col-10 md:col-4 lg:col-4" style="margin-left: auto;margin-right: auto;">
      <a href="https://gsecovam-my.sharepoint.com/personal/daniel_islas_gsecovam_onmicrosoft_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fdaniel%5Fislas%5Fgsecovam%5Fonmicrosoft%5Fcom%2FDocuments%2FLIBRETA%20DE%20INDUCCI%C3%93N%2FLibreta%20de%20inducci%C3%B3n%202023%2Epdf&parent=%2Fpersonal%2Fdaniel%5Fislas%5Fgsecovam%5Fonmicrosoft%5Fcom%2FDocuments%2FLIBRETA%20DE%20INDUCCI%C3%93N&ga=1"  target="_blank">
      <button type="submit"  class="btn" style="margin-top: 2%;">Consulta los lineamientos</button>
    </a>
      </div>
      <div class="col-10 md:col-4 lg:col-4" style="margin-left: auto;margin-right: auto;">
        <div class="center">
          <img src="assets/landing/rh.png" style="width:20%;max-width: 120px;">
        </div>
        </div>
        <div class="col-10 md:col-4 lg:col-4" style="margin-left: auto;margin-right: auto;">

          </div>
  </div>



</div>
