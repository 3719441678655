import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RhBossOptionsService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getPendingVacations(): Observable<any>
  {
    const url = `${this.baseUrl}/rh/employees/checkPendingVacations/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  sendReminder(pk:string): Observable<any>
  {
    const url = `${this.baseUrl}/rh/employees/checkPendingVacations/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,{}, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  getAutomaticPassword(pk:string): Observable<any>
  {
    const url = `${this.baseUrl}/rh/systemManagement/automaticPassword/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getAutomaticPasswordReport(): Observable<Blob>
  {
    const url = `${this.baseUrl}/rh/systemManagement/automaticPasswords/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    const options=
    {
      headers:headers,
      responseType:'blob' as 'json'
    }
    return this.http.get<Blob>(url, options).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAreas(): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/area/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  postArea(data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/area/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  deleteArea(pk:number): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/area/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getBranches(): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/branch/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  postBranch(data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/branch/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  deleteBranch(pk:number): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/branch/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getEnterprises(): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/enterprise/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  postEnterprise(data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/enterprise/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  deleteEnterprise(pk:number): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/enterprise/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  getFestiveDays(): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/festiveDay/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  postFestiveDay(data:any): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/festiveDay/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.post(url,data, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  deleteFestiveDay(pk:number): Observable<any> {
    const url = `${this.baseUrl}/rh/systemManagement/festiveDay/detail/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.delete(url, { headers }).pipe(
      catchError(error => {
        throw error;
      })
    );
  }


}
