<p-toast></p-toast>
<ng-container *ngIf="isLoading">
<div *ngIf="isLoading" class="container" style="background-image: url('assets/imgs/fondo_login.jpg'); background-size: cover; background-position: center; height: 100vh; display: flex; justify-content: center; align-items: center;">
  <div class="grid">

  <div class="col-11 md:col-6 lg:col-4" style="margin: auto;">
  <div class="form-container">
    <div class="card flex justify-content-center">
      <img [src]="logoUrl" alt="Logo de la empresa" class="imagen-logo">
    </div>
     <div class="card" style="width: 100%;">
      <h1>Bienvenido!</h1>
      <h2>Inicia sesión para entrar al sitio.</h2>
      <div class="field">
        <div class="flex flex-column gap-2">
          <label htmlFor="username">Numero de empleado</label>
          <input type="text" pInputText [(ngModel)]="username" name="username" placeholder="Ingresa tu numero de empleado" class="information">
        </div>
      </div>
      <div class="field">
        <div class="flex flex-column gap-2">
          <label htmlFor="password">Contraseña</label>
          <input type="password" pInputText [(ngModel)]="password" name="password" placeholder="Ingresa tu contraseña" class="information">
        </div>
      </div>
      <div class="captcha" style="margin:auto;align-items: center;">
      <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey" #captcha></re-captcha>
      </div>
      <button type="submit" (click)="login()" class="btn">Iniciar sesión</button>
    </div>


  </div>
  </div>

</div>
</div>

</ng-container>
<ng-container *ngIf!="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
