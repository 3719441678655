import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BossDataService {

  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getAbsencesData(year:number): Observable<any> {
    const url = `${this.baseUrl}/boss/absences/?year=`+year;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getAbsencesEmployeeData(pk:string): Observable<any> {
    const url = `${this.baseUrl}/boss/absences/employee/`+pk+'/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    return this.http.get(url, { headers });
  }
  getAbsencesReport(start_date:string,end_date:string,status:string[],employee:string,paid:string[]): Observable<Blob>
  {
    const url = `${this.baseUrl}/boss/absences/report/?start_date=`+start_date+'&end_date='+end_date+'&status='+status+'&employee='+employee+'&paid='+paid;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Authorization: 'Token ' + localStorage.getItem('key')
    });
    const options=
    {
      headers:headers,
      responseType:'blob' as 'json'
    }
    return this.http.get<Blob>(url, options).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
}
