import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BossLeavesService } from '../services/Leave/boss-leaves.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-aprove-leave',
  templateUrl: './aprove-leave.component.html',
  styleUrls: ['./aprove-leave.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AproveLeaveComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;
  isComment: boolean=false;

  filterText:string ="";
  motives: string[]=[];
  otherTypes: string[]=[];
  relatives: string[]=[];
  visibleModal: boolean=false;
  leaves: any[]=[];
  mimeType: string = '';

  visibleDetail: boolean = false;
  detailStart: Date =new Date();
  detailEnd: Date | undefined;
  detailRelative:string ='';
  detailProof:File=new File([],'');
  base64DetailProof: string='';
  base64DetailBeforeProof: string='';
  detailProofType:string =''
  detailOtherType:string ='';
  detailTime:string ='';
  detailRecoveryTime:string ='';
  detailRecoveryDay:Date[]=[];
  detailComments:string ='';
  detailBossComments:string ='';
  detailDecision: boolean | undefined;
  detailMotive: string ='';
  detailStatus:string="";
  recoveryDetailDecision: boolean=false;
  detailId:number=0;
  detailEmployee: string='';

  years:number[]=[]
  year:number=new Date().getFullYear();


  reportDialog:boolean=false;
  reportStartDate:Date=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  reportEndDate:Date=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  status_options:any[]=["En espera","Aceptado","Rechazado","En proceso","Terminado","No cumplio"];
  reportStatus:string[]=[];
  reportName:string="";
  reportMotive:string[]=[];
  reportPaid:string[]=[]

  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private bossService: BossLeavesService
    ) {
      this.motives = ['Fallecimiento de familiar directo','Intervención quirúrgica a familiar directo','Privación de la libertad de familiar directo','Siniestros ocurridos en el hogar',
      'Matrimonio del trabajador','Examen profesional del trabajador o hijos','Paternidad',
      'Otro'];
      this.otherTypes=['Día','Horas'];
      this.relatives=['Padre/Madre','Hermano(a)','Hijo(a)','Conyugue'];
  }
  showDetail(leave: any) {
    this.visibleDetail = true;
    this.getLeaveBossDetail(leave.id)

  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023+2;

    this.getLeavesBossData(this.year)
    this.years = Array.from({ length: numberOfYearsToShow }, (_, index) => 2023 + index);

  }

  getLeavesBossData(year: number)
  {
    this.bossService.getLeavesBossData(year).subscribe({
      next: data => {
        this.leaves=data
        this.isLoading=false
        this.year=year
      },
      error: (error: Error) => {
      }
  });
  }
  async getLeaveBossDetail(pk:number)
  {
    this.bossService.getLeaveDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        var dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailMotive=data.motive;
        this.detailRelative=data.relative;
        this.base64DetailBeforeProof=data.proof;
        this.detailStatus=data.status;
        if (this.detailStatus=="No cumplio" || this.detailStatus=="Rechazado"){
          this.isComment=true;
        }
        this.detailComments=data.comments;
        this.detailBossComments=data.boss_comments;
        this.detailOtherType=data.other_type;
        this.detailTime=data.time;
        this.detailRecoveryTime=data.recovery_time;
        this.detailProofType=data.proof_type;
        if (data.recovery_day!=""){
          var recoveryDatesText = data.recovery_day;
          var recoveryDatesArray = recoveryDatesText.split(", ");
          var recoveryDates = recoveryDatesArray.map((dateText: string) => new Date(dateText));
          this.detailRecoveryDay=recoveryDates;
          this.recoveryDetailDecision=true;
        }else
        {
        this.detailRecoveryDay=[];
        this.recoveryDetailDecision=false;
      }

        this.detailId=data.id
        this.detailEmployee=data.employee


              },
      error: (error: Error) => {
      }
  });
  }
  acceptLeaveData()
  {
   const myLeaveObject = {
      id:this.detailId,
      status:"Aceptado",
      boss_comments:this.detailBossComments


    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.bossService.updateLeaveStatus(this.detailId,myLeaveObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  denyLeaveData()
  {
   const myLeaveObject = {
      id:this.detailId,
      status:"Rechazado",
      boss_comments:this.detailBossComments

    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.bossService.updateLeaveStatus(this.detailId,myLeaveObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  confirmCancel()
  {
    this.confirmationService.confirm({
      message: '¿Deseas reportar que no cumplio con el permiso?',
      header: 'No cumplio',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.cancelLeaveData()
      },
      reject: () => {

      }
  });

  }
  cancelLeaveData()
  {
   const myLeaveObject = {
      id:this.detailId,
      status:"No cumplio",
      boss_comments:this.detailBossComments

    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.bossService.updateLeaveStatus(this.detailId,myLeaveObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }


  getColors(status: string) {
    switch (status) {
        case 'Aceptado':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazado':
            return 'denied';
        case 'Terminado':
            return 'finished';
        case 'En espera':
            return 'waiting';
        case 'No cumplio':
            return 'canceled';
        default:
          return 'inProcess';
    }

}
cleanDetailForm(){
  this.recoveryDetailDecision=false;
  this.detailStart=new Date();
  this.detailEnd=new Date();
  this.detailRelative="";
  this.detailProof=new File([],'');
  this.base64DetailProof="";
  this.detailComments="";
  this.detailBossComments=""
  this.detailMotive="";
  this.detailOtherType="";
  this.detailTime="";
  this.detailRecoveryTime="";
  this.detailRecoveryDay=[];
  this.detailStatus="";
  this.isComment=false;
}
clear(table: Table) {
  table.clear();
}


showReportDialog()
{
  this.reportDialog=true;
}
getReport()
  {
    const convStart=this.reportStartDate.getFullYear()+"-"+(this.reportStartDate.getMonth()+1)+"-"+this.reportStartDate.getDate()
    const convEnd=this.reportEndDate.getFullYear()+"-"+(this.reportEndDate.getMonth()+1)+"-"+this.reportEndDate.getDate()
    this.bossService.getLeavesReport(convStart,convEnd,this.reportStatus,this.reportName,this.reportMotive,this.reportPaid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        const textStart = this.reportStartDate.getDate().toString().padStart(2, '0') + "-" +
                  (this.reportStartDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                  this.reportStartDate.getFullYear();
        const textEnd = this.reportEndDate.getDate().toString().padStart(2, '0') + "-" +
                        (this.reportEndDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                        this.reportEndDate.getFullYear();
        const file_name="Reporte de permisos del " + textStart + " al " + textEnd + ".pdf"
        link.download = file_name;
        link.click()

        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo generado.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el archivo.' });
      }
    });
  }
  cleanReportForm()
  {
    this.reportStartDate=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.reportEndDate=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
    this.reportStatus=[];
    this.reportName="";
    this.reportMotive=[];
    this.reportPaid=[]
  }






downloadFile() {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(this.base64ToBlob(this.base64DetailBeforeProof));
  element.download = 'Comprobante del permiso '+this.detailId;
  element.click();
}
base64ToBlob(base64Data: string): Blob {
  const byteString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }const base64String = '...'; // Tu cadena base64 aquí
  this.mimeType = 'application/pdf';
  var mimeTypes = require('mime')
  const mimeType = mimeTypes.getType(this.detailProofType);
  if (mimeType!=null){
    this.mimeType = mimeType;
  }


  const blob = new Blob([uintArray], { type: this.mimeType });
  return blob;
}







}
