import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { MyVacChangeDataService } from 'src/app/services/Vacation/my-vac-change-data.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-vac-changes',
  templateUrl: './vac-changes.component.html',
  styleUrls: ['./vac-changes.component.css'],
  providers: [MessageService]
})
export class VacChangesComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;

  saturdays:number | undefined;
  next_vac_days: number | undefined;
  next_rem_days:number | undefined;
  next_saturdays:number | undefined;


  detailId: number =0;
  detailStart: Date=new Date();
  detailEnd: Date =new Date();
  detailSubstitute:string ="";
  detailComments:string ="";
  detailBossComments:string ="";
  detailStatus:string="";
  detailChange:string="";
  detailPeriod:string="";
  detailWorkDays:number=0;
  detailBeforeDays: number=0;
  detailSaturdays:number=0;
  detailBeforeSaturdays: number=0;

  beforeId: number =0;
  beforeStart: Date=new Date();
  beforeEnd: Date =new Date();
  beforeSubstitute:string ="";


  visibleModal: boolean=false;
  vac_changes: any[] = [];
  visibleDetail: boolean = false;

  filter_text:string ="";


  constructor(
    private messageService: MessageService,
    private vacationChangeService: MyVacChangeDataService,
    ){



  }

  ngOnInit() {

    this.getVacationsChangesData()

  }


  clear(table: Table) {
    table.clear();
  }




  getColors(status: string) {
    switch (status) {
        case 'Aceptado':
            return 'accepted';
        case 'Rechazado':
            return 'denied';
        case 'En espera':
            return 'waiting';
        default:
          return 'waiting';
    }

}
getChangeColors(status: string) {
  switch (status) {
      case 'Modificación':
          return 'modification';
      case 'Cancelación':
          return 'cancelation';
      default:
        return 'modification';
  }

}



showDialog() {
    this.visibleModal = true;
}
showDetail(vacation: any) {
  this.visibleDetail = true;
  this.getVacationChangeDetail(vacation.id)

}


getVacationsChangesData()
  {
    this.vacationChangeService.getVacationsChanges().subscribe({
      next: data => {
        this.vac_changes=data
        this.isLoading=false

        },
      error: (error: Error) => {
        }
  });

  }


  getVacationChangeDetail(pk:number)
  {
    this.vacationChangeService.getVacationChangeDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailSubstitute=data.substitute
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailChange=data.change_type
        this.detailPeriod=data.period
        this.detailWorkDays=data.work_days
        this.detailSaturdays=data.saturdays
        this.detailBeforeDays=data.before_work_days
        this.detailBeforeSaturdays=data.before_saturdays

        this.beforeId=data.vacation.id
        this.beforeSubstitute=data.vacation.substitute
        dateParts = data.vacation.start_date.split('-');
        this.beforeStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.vacation.end_date.split('-');
        this.beforeEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);

        if (this.detailStatus=="En espera" ){
          this.isBlocked=false
        }
        else
        {
          this.isBlocked=true
        }
        // Aquí puedes hacer cualquier manipulación o asignación de datos en el componente
      },
      error: (error: Error) => {
        // Manejo de errores
      }
  });
  }
putVacationChangeData()
  {
    const flag=this.validateForm()
    if (!flag){
      return;
    }
    const convStart=this.detailStart.getFullYear()+"-"+(this.detailStart.getMonth()+1)+"-"+this.detailStart.getDate()
    const convEnd=this.detailEnd.getFullYear()+"-"+(this.detailEnd.getMonth()+1)+"-"+this.detailEnd.getDate()
    const myVacationChangeObject = {
      id:this.detailId,
      employee:"",
      start_date: convStart,
      end_date: convEnd,
      substitute: this.detailSubstitute,
      comments: this.detailComments,
      change_type:"",

    };
    this.vacationChangeService.putVacationChangeDetail(this.detailId,myVacationChangeObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Cambio realizado' });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }

  deleteVacationChangeData()
  {


    this.vacationChangeService.deleteVacationChange(this.detailId).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }








validateForm() {
  if (!this.detailStart || !this.detailEnd || !this.detailComments || !this.detailSubstitute) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
  }
  if (this.detailStart>this.detailEnd && this.detailStart!=this.detailEnd) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, Ingresa fechas validas' });
    return false;
  }



    return true;

}


}
