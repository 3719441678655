import { Component, ElementRef, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MyLeaveDataService } from '../services/Leave/my-leave-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-my-leaves',
  templateUrl: './my-leaves.component.html',
  styleUrls: ['./my-leaves.component.css'],
  providers: [MessageService]
})
export class MyLeavesComponent {
  @ViewChild('fileClean') fileUpload: any;

  isLoading: boolean = true;
  isBlocked: boolean = true;
  isAccepted: boolean=false;

  filterText:string ="";
  motives: string[];
  otherTypes: string[];
  relatives: string[];
  visibleModal: boolean=false;
  leaves: any[]=[];
  mimeType: string = '';

  selectedStart: Date;
  selectedRelative:string;
  selectedProof:File=new File([],'');
  base64Proof: string='';
  selectedOtherType:string;
  selectedTime:string;
  selectedRecoveryTime:string;
  selectedRecoveryDay:Date[]=[];
  selectedComments:string;
  recoveryDecision: boolean;
  selectedMotive: string;

  visibleDetail: boolean = false;
  detailStart: Date =new Date();
  detailEnd: Date | undefined;
  detailRelative:string ='';
  detailProof:File=new File([],'');
  base64DetailProof: string='';
  base64DetailBeforeProof: string='';
  detailProofType:string =''
  detailOtherType:string ='';
  detailTime:string ='';
  detailRecoveryTime:string ='';
  detailRecoveryDay:Date[]=[];
  detailComments:string ='';
  detailBossComments:string ='';
  detailDecision: boolean | undefined;
  detailMotive: string ='';
  detailStatus:string="";
  recoveryDetailDecision: boolean=false;
  detailId:number=0;


  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private leaveService: MyLeaveDataService
    ) {
    this.motives=[]
    this.otherTypes=[]
    this.relatives=[]
    this.recoveryDecision=false;
    this.selectedStart=new Date();
    this.selectedRelative="";
    this.selectedProof=new File([],'');
    this.selectedComments="";
    this.selectedMotive="";
    this.selectedOtherType="";
    this.selectedTime="";
    this.selectedRecoveryTime="";

  }

  ngOnInit() {
    this.motives = ['Fallecimiento de familiar directo','Intervención quirúrgica a familiar directo','Privación de la libertad de familiar directo','Siniestros ocurridos en el hogar',
    'Matrimonio del trabajador','Examen profesional del trabajador o hijos','Paternidad',
    'Otro'];
    this.otherTypes=['Día','Horas'];
    this.relatives=['Padre/Madre','Hermano(a)','Hijo(a)','Conyugue'];
    this.selectedMotive="";
    this.getLeavesData()
}

showDialog() {
  this.visibleModal = true;
}
showDetail(leave: any) {
  this.visibleDetail = true;
  this.getLeaveDetail(leave.id)

}

onDropdownTypeChange(event: any) {
  if (this.selectedOtherType=="Horas")
  {
    this.confirm1();
  };
  if (this.detailOtherType=="Día")
  {
    this.recoveryDecision=false;
  };

}
onDropdownDetailChange(event: any) {
  if (this.detailOtherType=="Horas")
  {
    this.confirm2();
  };
  if (this.detailOtherType=="Día")
  {
    this.recoveryDetailDecision=false;
  };
}

confirm1() {
  this.confirmationService.confirm({ message:
    '<span>Los permisos en este concepto son sin goce de sueldo;<br>pero si lo negocías con tu jefe directo, puedes reponer el tiempo de ausencia. </span><h3>¿Deseas solicitarlo?</h3>',
      accept: () => {
          this.recoveryDecision=true;
      },
      reject: () => {
          this.recoveryDecision=false
      }
  });
}
confirm2() {
  this.confirmationService.confirm({ message:
    '<span>Los permisos en este concepto son sin goce de sueldo;<br>pero si lo negocías con tu jefe directo, puedes reponer el tiempo de ausencia. </span><h3>¿Deseas solicitarlo?</h3>',
      accept: () => {
          this.recoveryDetailDecision=true;
      },
      reject: () => {
          this.recoveryDetailDecision=false
      }
  });
}


getLeavesData()
  {
    this.leaveService.getLeavesData().subscribe({
      next: data => {
        this.leaves=data
        this.isLoading=false
      },
      error: (error: Error) => {
      }
  });
  }

  postLeaveData()
  {
    const flag=this.validateForm()
    var myLeaveData:any;
    if (!flag){
      return;
    }
    const convStart=this.selectedStart.getFullYear()+"-"+(this.selectedStart.getMonth()+1)+"-"+this.selectedStart.getDate()
    const convRecovery = this.selectedRecoveryDay
    .map((dateR) => {
    const convDateT = dateR.getFullYear() + "-" + (dateR.getMonth() + 1) + "-" + dateR.getDate();
    return convDateT;
    })
    .join(", ");
    if (this.selectedMotive=="Fallecimiento de familiar directo" || this.selectedMotive=="Intervención quirúrgica a familiar directo" || this.selectedMotive=="Privación de la libertad de familiar directo")
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.selectedMotive,
      relative:this.selectedRelative,
      proof:this.base64Proof,

      comments: this.selectedComments,
      boss_comments:"",
      time: "",
      recovery_time: "",
      recovery_day: "",
      other_type: "",
      status:"",
    };
  }
  if (this.selectedMotive=="Siniestros ocurridos en el hogar" || this.selectedMotive=="Matrimonio del trabajador" || this.selectedMotive=="Examen profesional del trabajador o hijos"|| this.selectedMotive=="Paternidad")
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.selectedMotive,
      proof:this.base64Proof,
      comments: this.selectedComments,
      relative: "",
      boss_comments:"",
      time: "",
      recovery_time: "",
      recovery_day: "",
      other_type: "",
      status:"",
    };
  }
  if (this.selectedMotive=="Otro" && this.selectedOtherType=="Día")
    {
    myLeaveData = {
      empleado:"",
      start_date: convStart,
      end_date: "",
      motive: this.selectedMotive,
      proof:'',
      other_type:this.selectedOtherType,
      comments: this.selectedComments,
      relative:"",
      boss_comments:"",
      time: "",
      recovery_time: "",
      recovery_day: "",
      status:"",
    };
  }
  if (this.selectedMotive=="Otro" && this.selectedOtherType=="Horas" && this.recoveryDecision==false)
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.selectedMotive,
      proof:'',
      other_type:this.selectedOtherType,
      time:this.selectedTime,
      comments: this.selectedComments,
      relative:"",
      boss_comments:"",
      recovery_time: "",
      recovery_day: "",
      status:"",
    };
  }
  if (this.selectedMotive=="Otro" && this.selectedOtherType=="Horas" && this.recoveryDecision==true)
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.selectedMotive,
      proof:'',
      other_type:this.selectedOtherType,
      time:this.selectedTime,
      recovery_time:this.selectedRecoveryTime,
      recovery_day:convRecovery,
      comments: this.selectedComments,
      relative:"",
      boss_comments:"",
      status:"",
    };
  }
  this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.leaveService.newLeaveData(myLeaveData).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleModal = false;
      this.cleanForm()

    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });


  }
  putLeaveData()
  {
    const flag=this.validateDetailForm()
    var myLeaveData:any;
    if (!flag){
      return;
    }
    const convStart=this.detailStart.getFullYear()+"-"+(this.detailStart.getMonth()+1)+"-"+this.detailStart.getDate()
    const convRecovery = this.detailRecoveryDay
    .map((dateR) => {
    const convDateT = dateR.getFullYear() + "-" + (dateR.getMonth() + 1) + "-" + dateR.getDate();
    return convDateT;
    })
    .join(", ");
    if (this.detailMotive=="Fallecimiento de familiar directo" || this.detailMotive=="Intervención quirúrgica a familiar directo" || this.detailMotive=="Privacidad de libertad")
    {

    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.detailMotive,
      relative:this.detailRelative,
      proof:this.base64DetailProof,
      comments: this.detailComments,
      boss_comments:this.detailBossComments,
      time: "",
      recovery_time: "",
      recovery_day: "",
      other_type: "",
      status:this.detailStatus,
    };
  }
  if (this.detailMotive=="Siniestros ocurridos en el hogar" || this.detailMotive=="Matrimonio del trabajador" || this.detailMotive=="Examen profesional del trabajador o hijos"|| this.detailMotive=="Paternidad")
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.detailMotive,
      proof:this.base64DetailProof,
      comments: this.detailComments,
      relative: "",
      boss_comments:this.detailBossComments,
      time: "",
      recovery_time: "",
      recovery_day: "",
      other_type: "",
      status:this.detailStatus,
    };
  }
  if (this.detailMotive=="Otro" && this.detailOtherType=="Día")
    {
    myLeaveData = {
      empleado:"",
      start_date: convStart,
      end_date: "",
      motive: this.detailMotive,
      proof:'',
      other_type:this.detailOtherType,
      comments: this.detailComments,
      relative:"",
      boss_comments:this.detailBossComments,
      time: "",
      recovery_time: "",
      recovery_day: "",
      status:this.detailStatus,
    };
  }
  if (this.detailMotive=="Otro" && this.detailOtherType=="Horas" && this.recoveryDetailDecision==false)
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.detailMotive,
      proof:'',
      other_type:this.detailOtherType,
      time:this.detailTime,
      comments: this.detailComments,
      relative:"",
      boss_comments:this.detailBossComments,
      recovery_time: "",
      recovery_day: "",
      status:this.detailStatus,
    };
  }
  if (this.detailMotive=="Otro" && this.detailOtherType=="Horas" && this.recoveryDetailDecision==true)
    {
    myLeaveData = {
      employee:"",
      start_date: convStart,
      end_date: "",
      motive: this.detailMotive,
      proof:'',
      other_type:this.detailOtherType,
      time:this.detailTime,
      recovery_time:this.detailRecoveryTime,
      recovery_day:convRecovery,
      comments: this.detailComments,
      relative:"",
      boss_comments:this.detailBossComments,
      status:this.detailStatus,
    };
  }
  this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.leaveService.changeLeaveData(this.detailId,myLeaveData).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail = false;
      this.cleanDetailForm()

    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail });

    }
  });


  }

  deleteLeaveData()
  {
    this.leaveService.deleteLeaveData(this.detailId).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false
    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }



  async getLeaveDetail(pk:number)
  {
    this.leaveService.getLeaveDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        var dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailMotive=data.motive;
        this.detailRelative=data.relative;
        this.base64DetailBeforeProof=data.proof;
        this.detailStatus=data.status;
        if (this.detailStatus=="Aceptado"){
          this.isAccepted=true;
        }
        this.detailComments=data.comments;
        this.detailBossComments=data.boss_comments;
        this.detailOtherType=data.other_type;
        this.detailTime=data.time;
        this.detailRecoveryTime=data.recovery_time;
        this.detailProofType=data.proof_type;
        if (data.recovery_day!=""){
          var recoveryDatesText = data.recovery_day;
          var recoveryDatesArray = recoveryDatesText.split(", ");
          var recoveryDates = recoveryDatesArray.map((dateText: string) => new Date(dateText));
          this.detailRecoveryDay=recoveryDates;
          this.recoveryDetailDecision=true;
        }else
        {
        this.detailRecoveryDay=[];
        this.recoveryDetailDecision=false;
      }
      if (data.status!="En espera")
      {
        this.isBlocked=true
      }
      else
        {
          this.isBlocked=false
        }

        this.detailId=data.id


      },
      error: (error: Error) => {

      }
  });
  }



  getColors(status: string) {
    switch (status) {
        case 'Aceptado':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazado':
            return 'denied';
        case 'Terminado':
            return 'finished';
        case 'En espera':
            return 'waiting';
        case 'No cumplio':
            return 'canceled';
        default:
          return 'inProcess';
    }

}



validateForm() {
  if(this.selectedMotive=="")
  {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
  }


  if (this.selectedMotive== "Privación de la libertad de familiar directo" || this.selectedMotive=="Intervención quirúrgica a familiar directo" || this.selectedMotive=="Fallecimiento de familiar directo") {

    if(!this.selectedStart  || !this.selectedRelative || !this.base64Proof)
    {
     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
     return false;
    }
   }
  if (this.selectedMotive== "Paternidad" || this.selectedMotive=="Siniestros ocurridos en el hogar" || this.selectedMotive=="Matrimonio del trabajador" || this.selectedMotive=="Examen profesional del trabajador o hijos") {
   if(!this.selectedStart  || !this.base64Proof)
   {
   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
   }
  }
  if (this.selectedMotive=="Otro")
{
  if(this.selectedOtherType=="")
  {
    {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
    }
  }
  if (this.selectedOtherType=="Día")
  {

    if(!this.selectedStart || !this.selectedComments){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
    }
  }

  if (this.selectedOtherType=="Horas" && this.recoveryDecision==false)
  {
    if(!this.selectedStart || !this.selectedComments || !this.selectedTime){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
      return false;
      }
  }
  if (this.selectedOtherType=="Horas" && this.recoveryDecision==true)
  {
    if(!this.selectedStart || !this.selectedComments || !this.selectedTime || !this.selectedRecoveryDay || !this.selectedRecoveryTime){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
      return false;
      }
  }
}

return true;
}
validateDetailForm() {
  if(this.detailMotive=="")
  {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
  }


  if (this.detailMotive== "Privación de la libertad de familiar directo" || this.detailMotive=="Intervención quirúrgica a familiar directo" || this.detailMotive=="Fallecimiento de familiar directo") {

    if(!this.detailStart)
    {
     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
     return false;
    }
   }
  if (this.detailMotive== "Paternidad" || this.detailMotive=="Siniestros ocurridos en el hogar" || this.detailMotive=="Matrimonio del trabajador" || this.detailMotive=="Examen profesional del trabajador o hijos") {
   if(!this.detailStart )
   {
   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
   }
  }
  if (this.detailMotive=="Otro")
{
  if(this.detailOtherType=="")
  {
    {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
    }
  }
  if (this.detailOtherType=="Día")
  {

    if(!this.detailStart || !this.detailComments){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
    return false;
    }
  }

  if (this.detailOtherType=="Horas" && this.recoveryDetailDecision==false)
  {
    if(!this.detailStart || !this.detailComments || !this.detailTime){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
      return false;
      }
  }
  if (this.detailOtherType=="Horas" && this.recoveryDetailDecision==true)
  {
    if(!this.detailStart || !this.detailComments || !this.detailTime || !this.detailRecoveryDay || !this.detailRecoveryTime){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, completa todos los campos' });
      return false;
      }
  }
}

return true;
}

cleanForm(){
  this.recoveryDecision=false;
  this.selectedStart=new Date();
  this.selectedRelative="";
  this.selectedProof=new File([],'');;
  this.base64Proof="";
  this.selectedComments="";
  this.selectedMotive="";
  this.selectedOtherType="";
  this.selectedTime="";
  this.selectedRecoveryTime="";
  this.selectedRecoveryDay=[];
  this.fileUpload.clear();
}
cleanDetailForm(){
  this.recoveryDetailDecision=false;
  this.detailStart=new Date();
  this.detailEnd=new Date();
  this.detailRelative="";
  this.detailProof=new File([],'');
  this.base64DetailProof="";
  this.detailComments="";
  this.detailBossComments=""
  this.detailMotive="";
  this.detailOtherType="";
  this.detailTime="";
  this.detailRecoveryTime="";
  this.detailRecoveryDay=[];
  this.detailStatus="";
  this.fileUpload.clear();
  this.isAccepted=false;
}
clear(table: Table) {
  table.clear();
}
onUpload(event: { files: any[]; }) {


    const file = event.files[0];
    if (file.size > 8000000) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
      this.fileUpload.clear();
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64Proof = reader.result as string;
    };
    return true
  }

onUploadDetail(event: { files: any[]; }) {

    const file = event.files[0];
    if (file.size > 8000000) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tamaño máximo permitido para el archivo es de 8MB' });
      this.fileUpload.clear();
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64DetailProof = reader.result as string;
    };
    return true
  }
  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(this.base64ToBlob(this.base64DetailBeforeProof));
    element.download = 'Comprobante del permiso '+this.detailId;
    element.click();
  }



  base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime')
    const mimeType = mimeTypes.getType(this.detailProofType);
    if (mimeType!=null){
      this.mimeType = mimeType;
    }


    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }


}



